// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[lfxDraggable]',
})
export class DraggableDirective {
  @HostBinding('class.draggable') draggable = true;
  @Output() dragStart = new EventEmitter<PointerEvent>();
  @Output() dragMove = new EventEmitter<PointerEvent>();

  // required to bind pointerdown to pointermove such
  // that pointermove & pointerup emit events only when pointerdown event has occured
  private isDragStart = false;

  constructor() {}

  // pointerdown on the element => dragStart
  @HostListener('pointerdown', ['$event']) onPointerDown(
    event: PointerEvent
  ): void {
    this.isDragStart = true;
    this.dragStart.emit(event);
  }

  // pointermove on the document => dragMove
  @HostListener('document:pointermove', ['$event']) onPointerMove(
    event: PointerEvent
  ): void {
    if (!this.isDragStart) {
      return;
    }

    this.dragMove.emit(event);
  }

  // pointerup on the document
  @HostListener('document:pointerup', []) onPointerUp(): void {
    if (!this.isDragStart) {
      return;
    }

    this.isDragStart = false;
  }
}
