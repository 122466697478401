// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const claEnabledProjectsQuery = gql`
  query claEnabledProjects($id: ID!) {
    claEnabledProjects(id: $id) {
      id
      name
      logoUrl
      description
    }
  }
`;
