// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const searchOrganizationsQuery = gql`
  query searchOrganizations(
    $name: String
    $type: String
    $ismember: Boolean
    $pageSize: Int
    $parentId: String
    $slug: String
  ) {
    searchOrganizations(
      name: $name
      type: $type
      ismember: $ismember
      pageSize: $pageSize
      parentId: $parentId
      slug: $slug
    ) {
      id
      name
      slug
      description
      logoUrl
      phone
      website
      salesforceB2BAccountId
      isMember
      isParent
      parentId
      parentDetail {
        id
        name
        salesforceB2BAccountId
        slug
      }
    }
  }
`;
