// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const removeFileMutation = gql`
  mutation removeContractDoc($key: String) {
    removeContractDoc(key: $key) {
      code
      message
      success
    }
  }
`;
