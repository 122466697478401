// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const sendNewMemberInviteMutation = gql`
  mutation sendInvite($input: SendInviteInput!) {
    sendInvite(input: $input) {
      code
      message
      success
      invitation {
        status
        email
        type
      }
    }
  }
`;
