// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const resendInviteMutation = gql`
  mutation resendInvite($inviteId: ID!, $inviteEmail: String!) {
    resendInvite(inviteId: $inviteId, inviteEmail: $inviteEmail) {
      code
      message
      success
      payload {
        status
        email
        type
      }
    }
  }
`;
