// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const nonrecurringZoomMeetingsQuery = gql`
  query nonrecurringZoomMeetings($input: ZoomMeetingsInput) {
    nonrecurringZoomMeetings(input: $input) {
      data {
        agenda
        duration
        id
        project
        startTime
        topic
        participants
      }
      metadata {
        total
      }
    }
  }
`;
