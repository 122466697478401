// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const orgTncLearnersQuery = gql`
  query orgTncLearners(
    $organizationId: String
    $pageSize: Int
    $offset: Int
    $fromDate: String
    $toDate: String
  ) {
    orgTncLearners(
      organizationId: $organizationId
      pageSize: $pageSize
      offset: $offset
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        enrollmentStats {
          certificatesAchieved
          enrollments
        }
        learner {
          name
          photoUrl
          id
        }
      }
      metadata {
        pageSize
        offset
        totalSize
      }
    }
  }
`;
