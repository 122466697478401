// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const uploadSbomFileMutation = gql`
  mutation uploadSbomFile($input: UploadSbomFileInput!) {
    uploadSbomFile(input: $input) {
      code
      message
      success
      payload {
        contentType
        fileUrl
        presignedUrl
      }
    }
  }
`;
