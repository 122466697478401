// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationContributionQuery = gql`
  query organizationContribution(
    $withContribution: Boolean = false
    $withContributionMonthly: Boolean = false
    $withContributionPerProject: Boolean = false
    $withContributionPerProjectMonthly: Boolean = false
    $withContributionPerProjectStats: Boolean = false
    $withContributionPerEmployee: Boolean = false
    $withContributionStats: Boolean = false
    $organizationId: ID
    $contributionMonthlyFilters: ContributionMonthlyFilters
    $contributionFilters: ContributionFilters
    $contributionStatsFilters: ContributionFilters
    $contributionPerProjectFilters: ContributionFilters
    $contributionPerProjectMonthlyFilters: ContributionFilters
    $contributionPerProjectStatsFilters: ContributionFilters
    $contributionPerEmployeeFilters: ContributionPerEmployeeFilters
    $projectId: ID
  ) {
    contribution(
      organizationId: $organizationId
      contributionFilters: $contributionFilters
    ) @include(if: $withContribution) {
      projects {
        slug
        projectname
        project_id
        logo
        countofemployees
        countofcommits
        share
      }
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        chat
        codeContributions
        commits {
          contribution
          contributors
          totalContributors
          totalContribution
        }
        issues {
          all {
            contribution
            totalContribution
          }
          resolved {
            contribution
            totalContribution
          }
          submitted {
            contribution
            totalContribution
          }
        }
        mailingList
        pr {
          all {
            contribution
            totalContribution
          }
          approved {
            contribution
            totalContribution
          }
          merged {
            contribution
            totalContribution
          }
          reviewed {
            contribution
            totalContribution
          }
          submitted {
            contribution
            totalContribution
          }
        }
        projectGroup {
          count
          totalCount
        }
        lastContributionDate
        project {
          description
          id
          industrySector
          logoUrl
          name
          slug
          parent
          isMember
        }
        organization {
          id
          logoUrl
          name
        }
        totalContributions
      }
    }
    # ts CompanyContribution
    contributionStats(
      organizationId: $organizationId
      contributionStatsFilters: $contributionStatsFilters
    ) @include(if: $withContributionStats) {
      code_changed
      count_of_commit
      count_of_contribution
      count_of_contributor
      count_of_issue
      count_of_pr
      count_of_project
      count_of_user
      loc_added
      loc_del
      chat
      mailingList
      codeContributions
      totalProjects
      commits {
        contribution
        contributors
        totalContributors
        totalContribution
      }
      issues {
        all {
          contribution
          totalContribution
        }
        resolved {
          contribution
          totalContribution
        }
        submitted {
          contribution
          totalContribution
        }
      }
      lastContributionDate
      pr {
        all {
          contribution
          totalContribution
        }
        approved {
          contribution
          totalContribution
        }
        merged {
          contribution
          totalContribution
        }
        reviewed {
          contribution
          totalContribution
        }
        submitted {
          contribution
          totalContribution
        }
      }
      totalContributions
    }
    # ContributionPerEmployeeConnection
    contributionPerEmployee(
      organizationId: $organizationId
      contributionPerEmployeeFilters: $contributionPerEmployeeFilters
    ) @include(if: $withContributionPerEmployee) {
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        chat
        mailingList
        codeContributions
        commits {
          contribution
        }
        issues
        name
        pr
        logoUrl
        projects {
          totalContribution
          contribution
          description
          id
          industrySector
          logoUrl
          name
          slug
          parent
        }
        totalContributions
      }
    }
    contributionMonthly(
      organizationId: $organizationId
      contributionMonthlyFilters: $contributionMonthlyFilters
    ) @include(if: $withContributionMonthly) {
      months {
        countofcommit
        countofissue
        countofpr
        ym
      }
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        date
        insights {
          chat
          commits {
            contribution
          }
          issues {
            all
            commented
            resolved
            submitted
          }
          mailingList
          pr {
            all
            approved
            merged
            reviewed
            submitted
          }
        }
      }
    }
    contributionPerProject(
      projectId: $projectId
      contributionPerProjectFilters: $contributionPerProjectFilters
    ) @include(if: $withContributionPerProject) {
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        chat
        codeContributions
        commits {
          contribution
          contributors
          totalContributors
          totalContribution
        }
        issues {
          all {
            contribution
            totalContribution
          }
        }
        mailingList
        projectGroup {
          count
          totalCount
        }
        lastContributionDate
        pr {
          all {
            contribution
            totalContribution
          }
          approved {
            contribution
            totalContribution
          }
          merged {
            contribution
            totalContribution
          }
          reviewed {
            contribution
            totalContribution
          }
          submitted {
            contribution
            totalContribution
          }
        }
        project {
          description
          id
          industrySector
          logoUrl
          name
          slug
          parent
          isMember
        }
        organization {
          id
          logoUrl
          name
        }
        totalContributions
      }
    }
    contributionPerProjectMonthly(
      projectId: $projectId
      contributionPerProjectMonthlyFilters: $contributionPerProjectMonthlyFilters
    ) @include(if: $withContributionPerProjectMonthly) {
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        date
        insights {
          chat
          commits {
            contribution
          }
          issues {
            all
            commented
            resolved
            submitted
          }
          mailingList
          pr {
            all
            approved
            merged
            reviewed
            submitted
          }
        }
      }
    }
    contributionPerProjectStats(
      projectId: $projectId
      contributionPerProjectStatsFilters: $contributionPerProjectStatsFilters
    ) @include(if: $withContributionPerProjectStats) {
      chat
      codeContributions
      commits {
        contribution
        contributors
      }
      issues {
        all {
          contribution
        }
        resolved {
          contribution
        }
        submitted {
          contribution
        }
      }
      lastContributionDate
      mailingList
      pr {
        all {
          contribution
        }
        approved {
          contribution
        }
        merged {
          contribution
        }
        reviewed {
          contribution
        }
        submitted {
          contribution
        }
      }
      totalContributions
      totalProjects
    }
  }
`;
