// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectForStatisticsQuery = gql`
  fragment VisualizationTypes on visualizationTypes {
    ... on Visualizations {
      id
      type
      title
      rows
      cols
    }
    ... on ChartsVisualization {
      labels
      dataSets {
        label
        data
      }
    }
    ... on NumberVisualization {
      value
      prefix
      suffix
      color
    }
    ... on DataGridVisualization {
      columns {
        prop
        name
        type
      }
      data
    }
  }

  query project($id: ID!, $filter: String) {
    project(id: $id) {
      id
      statistics {
        trends(filter: $filter) {
          id
          title
          filter {
            name
            options {
              key
              value
              default
            }
          }
          visualizations {
            git {
              ...VisualizationTypes
            }
            commits {
              ...VisualizationTypes
            }
            commitsByTimeZone {
              ...VisualizationTypes
            }
            authorsList {
              ...VisualizationTypes
            }
            authors {
              ...VisualizationTypes
            }
            organizationsSummary {
              ...VisualizationTypes
            }
            organizationsInfo {
              ...VisualizationTypes
            }
            repositoriesList {
              ...VisualizationTypes
            }
            organizationsList {
              ...VisualizationTypes
            }
          }
        }
      }
    }
  }
`;

export const projectStatisticsQuery = gql`
  query projectStatistics($id: ID!) {
    projectStatistics(id: $id) {
      community {
        issues {
          count
          percentage
        }
        commits {
          count
          percentage
        }
        contributors {
          count
          percentage
        }
        merges {
          count
          percentage
        }
      }

      repositorySecurity {
        name
        high
        low
        medium
      }

      organizationsRepositoryDetails {
        issues {
          count
          percentage
        }
        commits {
          count
          percentage
        }
        contributors {
          count
          percentage
        }
        merges {
          count
          percentage
        }
      }
      activeContributors {
        name
        avatarUrl
        commits
      }
    }
  }
`;
