// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const unlinkSocialIdentityMutation = gql`
  mutation unlinkUserIdentity($input: LinkUserIdentityInput!) {
    unlinkUserIndentity(input: $input) {
      code
      message
      success
      identities {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
