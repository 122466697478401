// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getInviteQuery = gql`
  query getInvite($inviteId: String!) {
    getInvite(inviteId: $inviteId) {
      data {
        inviteId
        email
        status
        type
        roleName
      }
    }
  }
`;
