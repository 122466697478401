// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { projectDetailsOffcanvasConfig } from '@lfx/config';
import {
  Info,
  InfoOffcanvasComponent,
} from '@lfx/shared/components/info-offcanvas/info-offcanvas.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class FlyoutService {
  constructor(private offcanvasService: NgbOffcanvas) {}

  openInfo(info: Info) {
    const ref = this.offcanvasService.open(
      InfoOffcanvasComponent,
      projectDetailsOffcanvasConfig
    );

    ref.componentInstance.info = info;
  }
}
