// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class LfxAcsUiService {
  constructor() {}

  isScriptLoaded(url) {
    const scripts = document.getElementsByTagName('script');

    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === url) {
        return true;
      }
    }

    return false;
  }

  injectScript(src): Promise<any> {
    if (this.isScriptLoaded(src)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');

      script.src = src;
      script.addEventListener('load', resolve);
      script.addEventListener('error', e => reject(e.error));
      document.head.appendChild(script);
    });
  }

  async injectScripts() {
    await this.injectScript(environment.lfxAcsUICDN + '/wc/lfx-acs-ui.js');
  }
}
