// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const searchFoundationsQuery = gql`
  query searchProjects($searchParams: SearchProjectsQueryParams) {
    searchProjects(searchParams: $searchParams) {
      id
      name
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      community {
        contributors
        commits
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      entityType
      website
      slug
      projectLogo
      industrySector
      technologySector
    }
  }
`;
