// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteSbomFileMutation = gql`
  mutation deleteSbomFile($input: DeleteSbomFileInput!) {
    deleteSbomFile(input: $input) {
      code
      message
      success
    }
  }
`;
