// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const trainedEmployeesByTypeOFTrainingQuery = gql`
  query TrainedEmployeesByTypeOfTraining(
    $organizationId: String!
    $dateRange: String!
  ) {
    trainedEmployeesByTypeOfTraining(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      instructorLead
      eLearning
    }
  }
`;
