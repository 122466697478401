// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteUserRoleScopeMutation = gql`
  mutation deleteUserRoleScope($input: DeleteUserRoleScopeInput!) {
    deleteUserRoleScope(input: $input) {
      code
      message
      success
    }
  }
`;
