// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  FoundationProject,
  Meeting,
  ProjectStatistics,
  Resource,
  UserGroup,
  ActivityLog,
  MembershipProjectsRow,
} from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProjectServiceGql,
  CreateNewProjectPayload,
  ContributorProject,
  ProjectRepository,
  CreateRepositoryRolePayload,
  DeleteRepositoryRoleInput,
  DeletRepositoryRolePayload,
  ContributorProjectRepository,
  projectOrganizationContributionsStatGraphQueryVariables,
} from '../gql';
import { projectContributorsGraphQueryVariables } from '../gql/queries/project/project-contributors';
import { organizationContributionsNonMembershipProjectsVariables } from '../gql/queries/project/project-organization-non-membership';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  membershipToProjectRoute = null;

  constructor(private projectServiceGql: ProjectServiceGql) {}

  public getProjectForStatistics(
    id: string,
    filter?: string
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProjectForStatistics(id, filter);
  }

  public getAdminProjectDetail(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProject(id, withCommitteesData);
  }

  public getProjectById(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProject(id, withCommitteesData);
  }

  public getProjectStatistics(id: string): Observable<ProjectStatistics> {
    return this.projectServiceGql.getProjectStatistics(id);
  }

  public getProjectMeetings(id: string): Observable<Meeting[]> {
    return this.projectServiceGql.getProjectMeetings(id);
  }

  public getProjectResources(
    id: string,
    noCache = false
  ): Observable<Resource[]> {
    return this.projectServiceGql.getProjectResources(id, noCache);
  }

  public getProjectMembershipDetails(
    projectId: string,
    organizationId?: string,
    accountB2bId?: string,
    filter?: string,
    noCache = false
  ) {
    return this.projectServiceGql.getProjectMembershipDetails(
      projectId,
      organizationId,
      accountB2bId,
      filter,
      noCache
    );
  }

  public getProjectMembershipWithTiers(
    projectId: string,
    organizationId?: string
  ) {
    return this.projectServiceGql.getProjectMembershipWithTiers(
      projectId,
      organizationId
    );
  }

  public updateProjectEssential(input: any): Observable<FoundationProject> {
    return this.projectServiceGql.updateProjectEssential(input.data);
  }

  public getProjectCommittees(
    id: string,
    isCommittee?: boolean
  ): Observable<UserGroup[]> {
    return this.projectServiceGql.getProjectCommittees(id, isCommittee);
  }

  public getProjectOrganizationContributionsStat(
    args: projectOrganizationContributionsStatGraphQueryVariables
  ) {
    return this.projectServiceGql.getProjectOrganizationContributionsStat(args);
  }

  public getOrganizationMembershipActive(args: any) {
    return this.projectServiceGql.getOrganizationMembershipActive(args);
  }

  public getOrganizationContributionsNonMembershipProjects(
    args: organizationContributionsNonMembershipProjectsVariables
  ) {
    return this.projectServiceGql.getOrganizationContributionsNonMembershipProjects(
      args
    );
  }

  public getProjectContributors(args: projectContributorsGraphQueryVariables) {
    return this.projectServiceGql.getProjectContributors(args);
  }

  public getProjectSubscribers(
    projectSlugs?: string[],
    categories?: string[],
    pageSize?: number,
    offset?: number
  ): Observable<UserGroup[]> {
    return this.projectServiceGql.getProjectSubscribers(
      projectSlugs,
      categories,
      pageSize,
      offset
    );
  }

  public getProjectCommitteesV2(
    projectId: string,
    pageSize?: number,
    offset?: number,
    noCache?: boolean
  ): Observable<UserGroup[]> {
    return this.projectServiceGql.getProjectCommitteesV2(
      projectId,
      pageSize,
      offset,
      noCache
    );
  }

  public createProjectCommittees(
    projectId: string,
    committees: UserGroup[]
  ): Observable<UserGroup[]> {
    const committeesInput = { projectId, committees: [] };

    committees.forEach(committee => {
      const committeeInput = {
        category: committee.category,
        collaborationName: committee.collaborationName,
        description: committee.description,
        url: committee.url,
      };

      committeesInput.committees.push(committeeInput);
    });

    return this.projectServiceGql.createProjectCommittees(committeesInput);
  }

  public updateProjectCommittee(
    projectId: string,
    committee: UserGroup
  ): Observable<UserGroup> {
    const updateCommitteeObj = {
      category: committee.category,
      collaborationName: committee.collaborationName,
      description: committee.description,
      url: committee.url,
    };
    const updateCommitteeInput = {
      projectId,
      committeeId: committee.id,
      committee: updateCommitteeObj,
    };

    return this.projectServiceGql.updateProjectCommittee(updateCommitteeInput);
  }

  public removeProjectCommittee(
    projectId: string,
    committeeId: string
  ): Observable<boolean> {
    const deleteCommitteeInput = {
      projectId,
      committeeId,
    };

    return this.projectServiceGql.deleteProjectCommittee(deleteCommitteeInput);
  }

  public createNewProject(
    projectName: string,
    ownerId,
    parentId
  ): Observable<CreateNewProjectPayload> {
    return this.projectServiceGql.creatNewProject({
      projectName,
      ownerId,
      parentId,
    });
  }

  public getCommitteesCategories(): Observable<string[]> {
    return this.projectServiceGql.getCommitteesCategories();
  }

  getActivityLogs(input, useCache?: boolean): Observable<ActivityLog[]> {
    return this.projectServiceGql.getActivityLogs(input, useCache);
  }

  public getContactUsLink(project) {
    if (project && project.programManager && project.programManager.email) {
      return `mailto:${project.programManager.email}`;
    }

    return 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/group/113';
  }

  public getContributorProjects(
    userId: string,
    organizationId: string,
    projectId: string
  ): Observable<ContributorProject[]> {
    return this.projectServiceGql.getContributorProjects(
      userId,
      organizationId,
      projectId
    );
  }

  public getProjectRepositories(
    projectId: string
  ): Observable<ProjectRepository[]> {
    return this.projectServiceGql.getProjectRepositories(projectId);
  }

  public createRepositoryRole(
    projectId,
    repositoryId,
    body
  ): Observable<CreateRepositoryRolePayload> {
    return this.projectServiceGql.createRepositoryRole({
      projectId,
      repositoryId,
      body,
    });
  }

  public deleteRepositoryRole(
    input: DeleteRepositoryRoleInput
  ): Observable<DeletRepositoryRolePayload> {
    return this.projectServiceGql.deleteRepositoryRole(input);
  }

  public getContributorProjectRepositoryMap(
    userId: string,
    organizationId: string,
    projectId: string
  ): Observable<{
    contributorProjects: Omit<ContributorProject, 'userDetails'>[];
    projectRepoRecord: Record<string, ContributorProjectRepository[]>;
  }> {
    return this.getContributorProjects(userId, organizationId, projectId).pipe(
      map(projects => {
        const contributorProjects: Omit<ContributorProject, 'userDetails'>[] =
          [];
        const projectRepoRecord: Record<
          string,
          ContributorProjectRepository[]
        > = {};

        for (const project of projects) {
          contributorProjects.push({
            id: project.id,
            maintainer: project.maintainer,
            name: project.name,
          });

          let repos: ContributorProjectRepository[] = [];

          for (const user of project.userDetails) {
            repos = repos.concat(user.repositories);
          }

          // No need to save empty record - no repo to add or remove
          if (repos.length) {
            const uniqueReposMap = new Map();

            for (const repo of repos) {
              const foundRepo = repos.find(
                r => r.id === repo.id && r.maintainer
              );

              if (foundRepo) {
                uniqueReposMap.set(foundRepo.id, {
                  ...foundRepo,
                  existingMaintainer: true,
                });
              } else {
                uniqueReposMap.set(repo.id, repo);
              }
            }

            projectRepoRecord[project.id] = Array.from(uniqueReposMap.values());
          }
        }

        return {
          contributorProjects,
          projectRepoRecord,
        };
      })
    );
  }

  public getMembershipProjects(): Observable<MembershipProjectsRow[]> {
    return this.projectServiceGql.getMembershipProjects();
  }
}
