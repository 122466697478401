// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectQuery = gql`
  query project($id: ID!, $withCommitteesData: Boolean = false) {
    project(id: $id) {
      badgeUrl
      autoJoinEnabled
      foundation {
        id
        name
      }
      enabledServices
      membershipAutorenew
      name
      parent
      status
      category
      createdDate
      endDate
      id
      modifiedDate
      projectType
      systemModStamp
      type
      description
      logoUrl
      colorBand
      tag
      slug
      repositoryUrl
      website
      insightsLink
      notificationsGroup
      model
      entityType
      members {
        id
        name
        logoUrl
        website
        tier {
          id
          name
        }
      }
      tiers {
        sizeMin
        sizeMax
        name
        description
        id
        pricing
        isProrated
        sizeMin
        sizeMax
        doNotDisplayInAutoJoin
        benefits {
          title
          points
        }
      }
      community {
        contributors
        commits
      }
      committees @include(if: $withCommitteesData) {
        id
        name
        collaborationName
        category
        description
        url
        totalMembers
        isCommittee
        groupsTitle
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      groupName
      opportunityOwner {
        firstName
        lastName
      }
      programManager {
        firstName
        lastName
      }
      owner {
        lastName
        firstName
      }
      parentHierarchy {
        id
        name
        slug
        logoUrl
        status
        parentHierarchy {
          id
          name
          slug
          logoUrl
          status
          parentHierarchy {
            id
            name
            slug
            logoUrl
            status
          }
        }
      }
    }
  }
`;
