// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateApprovalListMutation = gql`
  mutation updateApprovalList($input: ApprovalListInput!) {
    updateApprovalList(input: $input) {
      code
      approvalList
      success
      message
    }
  }
`;
