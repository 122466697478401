// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import gql from 'graphql-tag';

export const organizationContributorInsightsQuery = gql`
  query organizationContributorInsights(
    $organizationId: ID!
    $queryString: OrganizationContributorInsightsQueryParams
  ) {
    organizationContributorInsights(
      organizationId: $organizationId
      queryString: $queryString
    ) {
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        chat
        codeContributions
        commits {
          contribution
        }
        issues
        mailingList
        name
        logoUrl
        pr
        projects {
          contribution
        }
        totalContributions
      }
    }
  }
`;
