// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const claInsightsQuery = gql`
  query easyClaInsights {
    easyClaInsights {
      claManagersCount
      companiesCount
      companiesProjectContributionCount
      contributorsCount
      corporateContributorsCount
      createdAt
      gerritRepositoriesCount
      githubRepositoriesCount
      individualContributorsCount
      projectsCount
      repositoriesCount
    }
  }
`;
