// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface SendMemberInviteInput {
  email: string;
  firstName: string;
  lastName: string;
  scope: string;
  scopeId: string;
  roleName: string;
  type: string;
}

export interface SendNewEmployeesInviteInput {
  invites: Invite[];
  organizationId: string;
}

export interface Invite {
  emailAddress: string;
  role: InviteRole;
}

export enum InviteRole {
  Employee = 'Viewer',
  Administrator = 'Administrator',
}
