// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const checkUserPermissionsQuery = gql`
  query checkUserPermissions($permissionRequest: [String]!) {
    checkUserPermissions(permissionRequest: $permissionRequest) {
      permission
      status
    }
  }
`;
