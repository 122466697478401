// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployeesMediatorService {
  private refreshTableSource = new Subject<void>();
  private setActiveTabSource = new Subject<number>();
  private openEditEmployeeSource = new Subject<any>();
  private closeEditEmployeeSource = new Subject<void>();
  private reloadAddKeyContactUsersSource = new Subject<void>();
  private downloadCsvSource = new Subject<void>();

  refreshTable$ = this.refreshTableSource.asObservable();
  setActiveTab$ = this.setActiveTabSource.asObservable();
  openEditEmployee$ = this.openEditEmployeeSource.asObservable();
  closeEditEmployee$ = this.closeEditEmployeeSource.asObservable();
  reloadAddKeyContactUsers$ =
    this.reloadAddKeyContactUsersSource.asObservable();

  downloadCsv$ = this.downloadCsvSource.asObservable();

  refreshTable() {
    this.refreshTableSource.next();
  }

  setActiveTab(tabId: number) {
    this.setActiveTabSource.next(tabId);
  }

  openEditEmployee(inputs: any) {
    this.openEditEmployeeSource.next(inputs);
  }

  closeEditEmployee() {
    this.closeEditEmployeeSource.next();
  }

  reloadAddKeyContactUsers() {
    this.reloadAddKeyContactUsersSource.next();
  }

  downloadCsv() {
    this.downloadCsvSource.next();
  }
}
