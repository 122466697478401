// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import gql from 'graphql-tag';

export const organizationResearchQuery = gql`
  query organizationResearch(
    $organizationId: String!
    $queryParams: OrganizationResearchQueryInput
    $isMember: Boolean
  ) {
    organizationResearch(
      organizationId: $organizationId
      queryParams: $queryParams
      isMember: $isMember
    ) {
      metadata {
        offset
        pageSize
        totalSize
      }
      data {
        description
        documentUrl
        logoUrl
        title
        authors {
          authorName
          organizationLogoUrl
          organizationName
        }
        date
      }
    }
  }
`;
