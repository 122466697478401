// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable, Inject } from '@angular/core';
import { TypedStorage } from '@models';
import { Observable } from 'rxjs';
import { localStorageToken } from '@lfx/config';

export const bucketName = 'lfxBucket';

@Injectable({ providedIn: 'root' })
export class StorageService implements TypedStorage {
  private bucket: { [key: string]: any } = null;

  constructor(@Inject(localStorageToken) private storageEngine: Storage) {
    const stringifiedBucket = this.storageEngine.getItem(bucketName);

    this.bucket = stringifiedBucket ? JSON.parse(stringifiedBucket) : {};
  }

  getItem<T>(key: string): Observable<T> {
    return new Observable(observer => {
      if (!this.bucket.hasOwnProperty(key)) {
        // eslint-disable-next-line no-console
        console.warn(`Cannot get unsaved property from storage => ${key}`);
      }

      const item: T = this.bucket[key];

      observer.next(item);

      return observer.complete();
    });
  }

  setItem<T>(key: string, value: T): void {
    this.bucket[key] = value;
    this.storageEngine.setItem(bucketName, JSON.stringify(this.bucket));
  }

  getItemSync(key: string) {
    return this.bucket[key];
  }

  removeItem(key: string) {
    this.bucket[key] = null;

    this.storageEngine.setItem(bucketName, JSON.stringify(this.bucket));
  }

  clear(key: string) {
    delete this.bucket[key];
    this.storageEngine.setItem(bucketName, JSON.stringify(this.bucket));
  }
}
