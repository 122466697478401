// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const membershipRecommendationsQuery = gql`
  query membershipRecommendations($organizationId: ID!) {
    membershipRecommendations(salesforceId: $organizationId) {
      projectId
      slug
      description
      logo
      name
      projectType
      type
      entityType
      category
      industrySector
      technologySector
      autoJoin
    }
  }
`;
