// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Observable } from 'rxjs';

export interface AutoCompleteSearchInputActionEvent {
  text: string;
  type: AutoCompleteActionEventType;
}

export enum AutoCompleteActionEventType {
  focus = 'focus',
  typing = 'typing',
  remove = 'remove',
}

export interface AutoCompleteSearchConfig {
  result$: Observable<any>;
  notFoundText: string;
  placeHolderText: string;
  textThreshold: number;
}
