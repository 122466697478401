// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
/* eslint-disable prettier/prettier */
import { easyclaHelp } from '@config';
import {
  Component,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'lfx-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpMenuComponent {
  @Output() createSupportTicket = new EventEmitter<string>();
  @Output() getPageHelp = new EventEmitter<
    typeof easyclaHelp['easyClaHelpPaths']
  >();

  helpPaths = easyclaHelp.easyClaHelpPaths;
}
