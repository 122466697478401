// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listMembershipsQuery = gql`
  query listMemberships(
    $loadMainDataOnly: Boolean = false
    $filters: MembershipFilters
  ) {
    listMemberships(filters: $filters) {
      tier
      createdDate
      docuSignStatus @skip(if: $loadMainDataOnly) {
        date
      }
      product @skip(if: $loadMainDataOnly) {
        family
        id
        name
      }
      projectName @skip(if: $loadMainDataOnly)
      systemModStamp @skip(if: $loadMainDataOnly)
      accountId @skip(if: $loadMainDataOnly)
      agreementDate @skip(if: $loadMainDataOnly)
      annualFullPrice @skip(if: $loadMainDataOnly)
      assetLevel @skip(if: $loadMainDataOnly)
      autoRenew @skip(if: $loadMainDataOnly)
      cancellationDate @skip(if: $loadMainDataOnly)
      endDate
      id
      installDate @skip(if: $loadMainDataOnly)
      name
      numberOfYearsRequired @skip(if: $loadMainDataOnly)
      order @skip(if: $loadMainDataOnly)
      paymentFrequency @skip(if: $loadMainDataOnly)
      paymentTerms @skip(if: $loadMainDataOnly)
      price @skip(if: $loadMainDataOnly)
      projectId @skip(if: $loadMainDataOnly)
      purchaseDate @skip(if: $loadMainDataOnly)
      quantity @skip(if: $loadMainDataOnly)
      renewalType @skip(if: $loadMainDataOnly)
      rootAssetId @skip(if: $loadMainDataOnly)
      status
      year @skip(if: $loadMainDataOnly)
      project {
        id
        name
        description
        logoUrl
        projectType
        type
        slug
        lfSponsored
        lfMembershipRequired
        entityType
        status
        projects {
          id
          name
          logoUrl
          projectLogo
          projectType
          lfSponsored
          slug
          lfMembershipRequired
          entityType
          category
          foundation {
            name
          }
        }
      }
    }
  }
`;
