// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationContributorProjects = gql`
  query organizationContributorProjects(
    $organizationId: ID = ""
    $dateRange: String = ""
    $userId: String = ""
  ) {
    organizationContributorProjects(
      organizationId: $organizationId
      dateRange: $dateRange
      userId: $userId
    ) {
      projectname
      commits
      logo
    }
  }
`;
