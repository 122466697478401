// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationCommitteesQuery = gql`
  query foundation($id: ID!) {
    foundation(id: $id) {
      committees {
        id
        name
        collaborationName
        category
        description
        url
      }
    }
  }
`;
