// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateKeyContactRolesMutation = gql`
  mutation updateKeyContactRoles($input: updateKeyContactRolesInput!) {
    updateKeyContactRoles(input: $input) {
      message
      code
      success
    }
  }
`;
