// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const trainedEmployeesByGeographyQuery = gql`
  query TrainedEmployeesByGeography(
    $organizationId: String!
    $dateRange: String!
  ) {
    trainedEmployeesByGeography(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      country
      value
    }
  }
`;
