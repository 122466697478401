// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getOrganizationMembershipActiveQuery = gql`
  query ($projectId: ID = "", $organizationId: ID = "") {
    getOrganizationMembershipActive(
      projectId: $projectId
      organizationId: $organizationId
    ) {
      parent_id
      parent_project_name
      parent_slug
      parent_logo
      contributors
      contributions
    }
  }
`;
