// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const searchComponentsQuery = gql`
  query searchComponents($input: SearchComponentsInput!) {
    searchComponents(input: $input) {
      code
      message
      totalDependencies
      data {
        childComponentCount
        fileId
        fileName
        fileUploadedDate
        id
        licenses
        name
        packageName
        reference
        version
      }
      metadata {
        offset
        pageSize
        totalSize
      }
    }
  }
`;
