// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getCodeContributionsCubeQuery = gql`
  query ($input: CodeContributionsInput) {
    getCodeContributions(input: $input) {
      code
      message
      codeContributionsRes {
        countContributions
        countContributors
        countCommits
        countPullrequests
        countIssues
        countCodeReviews
        compareDateRange
      }
    }
  }
`;
