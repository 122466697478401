// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const totalZoomMeetingsQuery = gql`
  query totalZoomMeetingsQuery($input: ZoomMeetingsTotalInput) {
    totalZoomMeetings(input: $input) {
      total
    }
  }
`;
