// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const sendNewEmployeesInviteMutation = gql`
  mutation sendBulkInvite($input: BulkInviteInput!) {
    sendBulkInvite(input: $input) {
      code
      message
      success
      invitation {
        status
        email
        type
        inviteId
        error
      }
    }
  }
`;
