// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  ProjectInfrastructureSummary,
  ProjectInfrastructureInfoResult,
  ZoomMeetingsRegistrants,
  ProjectInfrastructureGroup,
  ZoomMeetingResponse,
  ZoomMeetingResponseMetadata,
  ZoomMeetingsFilter,
} from '@models';
import { Observable } from 'rxjs';
import { ProjectInfrastructureServiceGql } from '../gql';

import { AddAliasInput } from '../gql/services/inputs';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfrastructureService {
  constructor(
    private projectInfrastructureServiceGql: ProjectInfrastructureServiceGql
  ) {}

  public getProjectInfrastructureSummary(
    projectName
  ): Observable<ProjectInfrastructureSummary> {
    return this.projectInfrastructureServiceGql.getProjectInfrastructureSummary(
      projectName
    );
  }

  public addEmailAlias(
    input: AddAliasInput
  ): Observable<ProjectInfrastructureInfoResult> {
    return this.projectInfrastructureServiceGql.addEmailAlias(input);
  }

  public getRecurringZoomMeetings(
    projectSlug: string,
    filter?: Omit<ZoomMeetingsFilter, 'projectSlug'>
  ): Observable<ZoomMeetingResponse> {
    return this.projectInfrastructureServiceGql.getRecurringZoomMeetings(
      projectSlug,
      filter
    );
  }

  public getRecurringZoomMeetingsOccurrence(
    projectSlug: string,
    filter?: Omit<ZoomMeetingsFilter, 'projectSlug'>
  ): Observable<ZoomMeetingResponse> {
    return this.projectInfrastructureServiceGql.getRecurringZoomMeetingsOccurrence(
      projectSlug,
      filter
    );
  }

  public getNonrecurringZoomMeetings(
    projectSlug: string,
    filter?: Omit<ZoomMeetingsFilter, 'projectSlug'>
  ): Observable<ZoomMeetingResponse> {
    return this.projectInfrastructureServiceGql.getNonrecurringZoomMeetings(
      projectSlug,
      filter
    );
  }

  public getPastZoomMeetings(
    projectSlug: string,
    filter?: Omit<ZoomMeetingsFilter, 'projectSlug'>
  ): Observable<ZoomMeetingResponse> {
    return this.projectInfrastructureServiceGql.getPastZoomMeetings(
      projectSlug,
      filter
    );
  }

  public getZoomMeetingsTotal(
    projectSlug: string,
    visibility?: string
  ): Observable<ZoomMeetingResponseMetadata> {
    return this.projectInfrastructureServiceGql.getZoomMeetingsTotal(
      projectSlug,
      visibility
    );
  }

  public getZoomMeetingsRegistrants(
    zoomMeetingId: string
  ): Observable<ZoomMeetingsRegistrants> {
    return this.projectInfrastructureServiceGql.getZoomMeetingsRegistrants(
      zoomMeetingId
    );
  }

  public getProjectGroup(
    projectSlug: string
  ): Observable<ProjectInfrastructureGroup> {
    return this.projectInfrastructureServiceGql.getProjectGroup(projectSlug);
  }
}
