// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const committerGrowthCubeQuery = gql`
  query ($input: CommittersGrowthInput) {
    getCommittersGrowth(input: $input) {
      code
      message
      committerGrowthCountsRes {
        date
        committerCount
        contributorCount
      }
    }
  }
`;
