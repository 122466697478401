// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationTeamsSummaryQuery = gql`
  query organizationTeamsSummary($organizationId: String!) {
    organizationTeamsSummary(organizationId: $organizationId) {
      data {
        communityRoles
        employees
        id
        lfxRoles
        logoUrl
        membershipId
        membershipRoles
        name
        slug
      }
      metadata {
        totalSize
        pageSize
        offset
      }
    }
  }
`;

export const organizationTeamsSummaryV2Query = gql`
  query organizationTeamsSummaryV2(
    $organizationId: String!
    $queryParams: OrgTeamsSummaryV2QueryParams
  ) {
    organizationTeamsSummaryV2(
      organizationId: $organizationId
      queryParams: $queryParams
    ) {
      data {
        committee {
          id
          name
        }
        id
        logoUrl
        membershipId
        name
        slug
      }
      metadata {
        offset
        pageSize
        totalSize
      }
    }
  }
`;
