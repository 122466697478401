// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationStatsQuery = gql`
  query organizationContribution(
    $organizationId: ID
    $contributionStatsFilters: ContributionFilters
  ) {
    contributionStats(
      organizationId: $organizationId
      contributionStatsFilters: $contributionStatsFilters
    ) {
      code_changed
      count_of_commit
      count_of_contribution
      count_of_contributor
      count_of_issue
      count_of_pr
      count_of_project
      count_of_user
      loc_added
      loc_del
      chat
      mailingList
    }
  }
`;
