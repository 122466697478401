// Copyright The Linux Foundation and each organization to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationLeaderboardQuery = gql`
  query organizationLeaderboard($input: OrganizationLeaderboardInput) {
    organizationLeaderboard(input: $input) {
      code
      message
      organizationLeaderboard {
        accountId
        accountName
        logoUrl
        contributionPercentage
        isMyOrg
      }
    }
  }
`;
