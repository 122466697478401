// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit } from '@angular/core';
import { pageSettings } from '@lfx/config';

@Component({
  templateUrl: './unauthorized.component.html',
})
export class UnauthorizedComponent implements OnInit {
  pageSettings;
  constructor() {
    this.pageSettings = pageSettings;
  }

  ngOnInit() {
    this.pageSettings.pageWithoutSidebar = true;
  }
}
