// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allProjectsCubeQuery = gql`
  query ($organizationId: String = "", $dateRange: String!) {
    getProjects(organizationId: $organizationId, dateRange: $dateRange) {
      projectId
      projectName
      foundationId
      projectType
      projectSlug
      membershipStatus
      commitCount
    }
  }
`;
