// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listMemberSponsorshipsQuery = gql`
  query listMemberSponsorships($input: MemberSponsorshipsInput) {
    listMemberSponsorships(input: $input) {
      data {
        name
        closeDate
        year
        endDate
        tier
        product {
          name
        }
        contact {
          firstName
          lastName
        }
        event {
          name
          startDate
        }
      }
      metadata {
        offset
        pageSize
        totalSize
      }
    }
  }
`;
