// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationCLAGroupsQuery = gql`
  query foundationCLAGroups($input: FoundationCLAGroupsInput!) {
    foundationCLAGroups(input: $input) {
      code
      message
      success
      foundationCLAGroups {
        claGroups {
          claGroupId
          projectSfidList
        }
        projectSignedAtFoundationLevel
      }
    }
  }
`;
