// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  Productivity,
  ContributionProject,
  ProjectsWeContributeCodeToResult,
  BaseProjectContributionInput,
  ContributorsGrowthResponse,
  ContributingCountriesResponse,
  Top10OrgsByCommitsResponse,
  AllOrgsContributionsResponse,
  TotalCommitsByOrgResponse,
  IssuesByOrgsResponse,
  PRsMergedByOrgsResponse,
  SubProjectsStatsResponse,
  DBTInput,
  ContributorLeaderboardResponse,
  OrganizationLeaderboardResponse,
  ContributorTeam,
  WorkTimeDistribution,
} from '@models';
import { Observable } from 'rxjs';
import { ProjectContributionsServiceGql } from '../gql';

@Injectable({
  providedIn: 'root',
})
export class ProjectContributionsService {
  membershipToProjectRoute = null;

  constructor(
    private projectContributionServiceGql: ProjectContributionsServiceGql
  ) {}

  public getAllProjects(
    organizationId: string,
    dateRange: string
  ): Observable<ContributionProject[]> {
    return this.projectContributionServiceGql.getAllProjects(
      organizationId,
      dateRange
    );
  }

  public getProductivity(
    organizationId?: string,
    projectId?: string,
    dateRange?: string
  ): Observable<Productivity[]> {
    return this.projectContributionServiceGql.getProductivity(
      organizationId,
      projectId,
      dateRange
    );
  }

  public getProjectsWeContributeCodeTo(
    organizationId?: string,
    foundationId?: string,
    projectId?: string,
    dateRange?: string,
    pageSize?: number,
    offset?: number
  ): Observable<ProjectsWeContributeCodeToResult> {
    return this.projectContributionServiceGql.getProjectsWeContributeCodeTo(
      organizationId,
      foundationId,
      projectId,
      dateRange,
      pageSize,
      offset
    );
  }

  getContributorsGrowth(
    input: BaseProjectContributionInput
  ): Observable<ContributorsGrowthResponse> {
    return this.projectContributionServiceGql.getContributorsGrowth(input);
  }

  getContributingCountries(
    input: BaseProjectContributionInput
  ): Observable<ContributingCountriesResponse> {
    return this.projectContributionServiceGql.getContributingCountries(input);
  }

  getTop10OrgsByCommits(
    input: BaseProjectContributionInput
  ): Observable<Top10OrgsByCommitsResponse> {
    return this.projectContributionServiceGql.getTop10OrgsByCommits(input);
  }

  getAllOrgsContributions(
    input: BaseProjectContributionInput
  ): Observable<AllOrgsContributionsResponse> {
    return this.projectContributionServiceGql.getAllOrgsContributions(input);
  }

  getTotalCommitsByOrgs(
    input: BaseProjectContributionInput
  ): Observable<TotalCommitsByOrgResponse> {
    return this.projectContributionServiceGql.getTotalCommitsByOrgs(input);
  }

  getIssuesByOrgs(
    input: BaseProjectContributionInput
  ): Observable<IssuesByOrgsResponse> {
    return this.projectContributionServiceGql.getIssuesByOrgs(input);
  }

  getPRsMergedByOrgs(
    input: BaseProjectContributionInput
  ): Observable<PRsMergedByOrgsResponse> {
    return this.projectContributionServiceGql.getPRsMergedByOrgs(input);
  }

  getContributorLeaderboard(
    input: BaseProjectContributionInput,
    noCache = false
  ): Observable<ContributorLeaderboardResponse> {
    return this.projectContributionServiceGql.getContributorLeaderboard(
      input,
      noCache
    );
  }

  getOrganizationLeaderboard(
    input: BaseProjectContributionInput,
    noCache = false
  ): Observable<OrganizationLeaderboardResponse> {
    return this.projectContributionServiceGql.getOrganizationLeaderboard(
      input,
      noCache
    );
  }

  getSubProjectsStats(
    input: BaseProjectContributionInput
  ): Observable<SubProjectsStatsResponse> {
    return this.projectContributionServiceGql.getSubProjectsStats(input);
  }

  getMailingLists(input: DBTInput) {
    return this.projectContributionServiceGql.getMailingLists(input);
  }

  getBoardMeetings(input: DBTInput) {
    return this.projectContributionServiceGql.getBoardMeetings(input);
  }

  getContributorTeam(
    organizationId: string,
    filters: {
      isMaintainer?: boolean;
      isCommitter?: boolean;
      isReviewer?: boolean;
      isOthers?: boolean;
      currentEmployeesOnly?: boolean;
    },
    projectId?: string,
    dateRange?: string
  ): Observable<ContributorTeam[]> {
    const {
      isMaintainer,
      isCommitter,
      isReviewer,
      isOthers,
      currentEmployeesOnly,
    } = filters;

    return this.projectContributionServiceGql.getContributorTeam(
      organizationId,
      projectId,
      dateRange,
      isMaintainer,
      isCommitter,
      isReviewer,
      isOthers,
      currentEmployeesOnly
    );
  }

  getWorkTimeDistribution(
    organizationId: string,
    projectId?: string,
    dateRange?: string
  ): Observable<WorkTimeDistribution[]> {
    return this.projectContributionServiceGql.getWorkTimeDistribution(
      organizationId,
      projectId,
      dateRange
    );
  }
}
