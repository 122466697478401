// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectGroupQuery = gql`
  query projectGroup($projectSlug: String!) {
    projectGroup(projectSlug: $projectSlug) {
      domain
    }
  }
`;
