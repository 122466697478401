// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationLevelCLAQuery = gql`
  query foundationLevelCla($id: ID!) {
    foundationLevelCla(id: $id) {
      foundationLevelCla
    }
  }
`;
