// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectsRemediationRateDetails = gql`
  fragment SecurityRemediationRateFragment on ProjectsRemediationRate {
    totalFixableIssues
    totalOpenIssues
    totalIssues
    totalFixedIssues
  }
  query {
    projectsRemediationRateDetails {
      base {
        ...SecurityRemediationRateFragment
      }
      upstream {
        ...SecurityRemediationRateFragment
      }
    }
  }
`;
