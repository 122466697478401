// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const certifiedEmployeesByGeographyQuery = gql`
  query CertifiedEmployeesByGeography(
    $organizationId: String!
    $dateRange: String!
  ) {
    certifiedEmployeesByGeography(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      country
      value
    }
  }
`;
