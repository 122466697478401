// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateMembershipContactMutation = gql`
  mutation updateMembershipContact($input: UpdateMembershipContactInput!) {
    updateMembershipContact(input: $input) {
      message
      code
      success
      contact {
        role
        primaryContact
        contact {
          id
        }
      }
    }
  }
`;
