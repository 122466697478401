// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const totalCommitsByOrgsCubeQuery = gql`
  query getTotalCommitsByMyOrgChart($input: TotalCommitsByOrgsInput) {
    getTotalCommitsByMyOrgChart(input: $input) {
      code
      message
      totalCommitsByOrgs {
        countCommits
        timestamp
        timestampMonth
      }
    }
  }
`;
