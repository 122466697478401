// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectResourcesQuery = gql`
  query projectResource($id: ID!) {
    projectResource(id: $id) {
      id
      name
      url
      type
      createdAt
      model
      updatedAt
    }
  }
`;
