// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  allProjectsQuery,
  projectQuery,
  projectCommitteesQuery,
  projectSubscribersQuery,
  projectStatisticsQuery,
  projectForStatisticsQuery,
  projectResourcesQuery,
  projectMeetingsQuery,
  lfSponsoredProjectsQuery,
  projectActivityLogsQuery,
  projectCommitteeCategoriesQuery,
  projectMembershipDetails,
  projectMembershipWithTiers,
  projectCommitteesV2Query,
  contributorProjectsQuery,
  projectRepositoriesQuery,
  projectOrganizationContributionsStatQuery,
  projectOrganizationContributionsStatGraphQueryResponse,
  projectOrganizationContributionsStatGraphQueryVariables,
  membershipProjectsQuery,
} from '../queries';
import {
  AllProjectsResult,
  ProjectResult,
  UpdateProjectEssentialsResult,
  CreateNewProjectPayload,
  CreateNewProjectResult,
  ProjectCommitteesResult,
  CreateProjectCommitteesResult,
  UpdateProjectCommitteeResult,
  DeleteProjectCommitteeResult,
  ProjectStatisticsResult,
  ProjectResourceResult,
  ProjectMeetingResult,
  LfSponsoredProjectsResult,
  ProjectActivityLogResult,
  ProjectCommitteesCategoriesResult,
  ContributorProjectsResult,
  ProjectRepositoriesResult,
  CreateRepositoryRolePayload,
  CreateRepositoryRoleResult,
  DeletRepositoryRoleeResult,
  DeletRepositoryRolePayload,
} from './results';
import { Observable } from 'rxjs';
import {
  FoundationProject,
  UserGroup,
  Statistics,
  Resource,
  Meeting,
  ActivityLog,
  MembershipProjectsRow,
} from '@models';
import { map } from 'rxjs/operators';
import {
  UpdateProjectEssentialsInput,
  CreateNewProjectInput,
  CreateProjectCommitteesInput,
  UpdateProjectCommitteeInput,
  DeleteProjectCommitteeInput,
  ProjectActivityLogsInput,
  CreateRepositoryRoleInput,
  DeleteRepositoryRoleInput,
} from './inputs';
import {
  updateProjectEssentialsMutation,
  createNewProjectMutation,
  createProjectCommitteesMutation,
  updateProjectCommitteeMutation,
  deleteProjectCommitteeMutation,
  createRepositoryRoleMutation,
  deleteRepositoryRoleMutation,
} from '../mutations';
import { cloneDeep } from 'lodash';
import {
  projectContributorsGraphQueryVariables,
  projectContributorsGraphQueryResponse,
  projectContributorsQuery,
} from '../queries/project/project-contributors';
import { getOrganizationMembershipActiveQuery } from '../queries/project/project-organization-membership-active';
import {
  organizationContributionsNonMembershipProjectsQuery,
  organizationContributionsNonMembershipProjectsResponse,
  organizationContributionsNonMembershipProjectsVariables,
} from '../queries/project/project-organization-non-membership';

@Injectable({
  providedIn: 'root',
})
export class ProjectServiceGql {
  private projectStatisticsQuery: QueryRef<ProjectResult> = null;

  constructor(private apollo: Apollo) {}

  getProjectForStatistics(id, filter) {
    if (!this.projectStatisticsQuery) {
      this.projectStatisticsQuery = this.getProjectForStatisticsQuery(
        id,
        filter
      );
    } else {
      this.projectStatisticsQuery.setVariables({
        id,
        filter,
      });
      this.projectStatisticsQuery.refetch();
    }

    return this.projectStatisticsQuery.valueChanges.pipe(
      map(res => res.data.project)
    );
  }

  creatNewProject(
    projectInfo: CreateNewProjectInput
  ): Observable<CreateNewProjectPayload> {
    return this.apollo
      .mutate<CreateNewProjectResult>({
        mutation: createNewProjectMutation,
        variables: {
          projectInfo,
        },
      })
      .pipe(
        map(res => res.data.createNewProject),
        map(cloneDeep)
      );
  }

  createRepositoryRole(
    input: CreateRepositoryRoleInput
  ): Observable<CreateRepositoryRolePayload> {
    return this.apollo
      .mutate<CreateRepositoryRoleResult>({
        mutation: createRepositoryRoleMutation,
        variables: input,
      })
      .pipe(
        map(res => res.data.createRepositoryRole),
        map(cloneDeep)
      );
  }

  deleteRepositoryRole(
    input: DeleteRepositoryRoleInput
  ): Observable<DeletRepositoryRolePayload> {
    return this.apollo
      .mutate<DeletRepositoryRoleeResult>({
        mutation: deleteRepositoryRoleMutation,
        variables: { deleteRepositoryRoleInput: input },
      })
      .pipe(
        map(res => res.data.deleteRepositoryRole),
        map(cloneDeep)
      );
  }

  getAllProjects(noCache = false): Observable<FoundationProject[]> {
    return this.apollo
      .watchQuery<AllProjectsResult>({
        query: allProjectsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.projects),
        map(cloneDeep)
      );
  }

  getProject(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.apollo
      .watchQuery<ProjectResult>({
        query: projectQuery,
        fetchPolicy: 'no-cache',
        variables: {
          id,
          withCommitteesData,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.project),
        map(cloneDeep)
      );
  }

  updateProjectEssential(
    projectEssential: UpdateProjectEssentialsInput
  ): Observable<FoundationProject> {
    return this.apollo
      .mutate<UpdateProjectEssentialsResult>({
        mutation: updateProjectEssentialsMutation,
        variables: {
          projectEssential,
        },
      })
      .pipe(
        map(res => res.data.updateProjectEssentials.project),
        map(cloneDeep)
      );
  }

  getProjectCommittees(
    id: string,
    isCommittee: boolean
  ): Observable<UserGroup[]> {
    return this.apollo
      .watchQuery<ProjectCommitteesResult>({
        query: projectCommitteesQuery,
        variables: {
          id,
          isCommittee,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.project.committees),
        map(cloneDeep)
      );
  }

  getProjectOrganizationContributionsStat(
    args: projectOrganizationContributionsStatGraphQueryVariables
  ) {
    return this.apollo
      .watchQuery<
        projectOrganizationContributionsStatGraphQueryResponse,
        projectOrganizationContributionsStatGraphQueryVariables
      >({
        query: projectOrganizationContributionsStatQuery,
        variables: args,
      })
      .valueChanges.pipe(
        map(res => res.data.projectOrganizationContributionsStat),
        map(cloneDeep)
      );
  }

  getOrganizationMembershipActive(args: any) {
    return this.apollo
      .watchQuery<any, any>({
        query: getOrganizationMembershipActiveQuery,
        variables: args,
      })
      .valueChanges.pipe(
        map(res => res.data.getOrganizationMembershipActive),
        map(cloneDeep)
      );
  }

  getOrganizationContributionsNonMembershipProjects(
    args: organizationContributionsNonMembershipProjectsVariables
  ) {
    return this.apollo
      .watchQuery<
        organizationContributionsNonMembershipProjectsResponse,
        organizationContributionsNonMembershipProjectsVariables
      >({
        query: organizationContributionsNonMembershipProjectsQuery,
        variables: args,
      })
      .valueChanges.pipe(
        map(res => res.data.organizationContributionsNonMembershipProjects),
        map(cloneDeep)
      );
  }

  getProjectContributors(args: projectContributorsGraphQueryVariables) {
    return this.apollo
      .watchQuery<
        projectContributorsGraphQueryResponse,
        projectContributorsGraphQueryVariables
      >({
        query: projectContributorsQuery,
        variables: args,
      })
      .valueChanges.pipe(
        map(res => res.data.projectContributors),
        map(cloneDeep)
      );
  }

  getProjectSubscribers(
    projectSlugs?: string[],
    categories?: string[],
    pageSize?: number,
    offset?: number
  ) {
    return this.apollo
      .watchQuery<any>({
        query: projectSubscribersQuery,
        variables: {
          projectSlugs,
          categories,
          pageSize,
          offset,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.subscribers),
        map(cloneDeep)
      );
  }

  getProjectCommitteesV2(
    projectId: string,
    pageSize?: number,
    offset?: number,
    noCache = false
  ) {
    return this.apollo
      .watchQuery<any>({
        query: projectCommitteesV2Query,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          projectId,
          pageSize,
          offset,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectCommitteesV2),
        map(cloneDeep)
      );
  }

  createProjectCommittees(
    committeesInfo: CreateProjectCommitteesInput
  ): Observable<UserGroup[]> {
    return this.apollo
      .mutate<CreateProjectCommitteesResult>({
        mutation: createProjectCommitteesMutation,
        variables: {
          committeesInfo,
        },
      })
      .pipe(
        map(res => res.data.createProjectCommittees.committees),
        map(cloneDeep)
      );
  }

  updateProjectCommittee(
    committeeInfo: UpdateProjectCommitteeInput
  ): Observable<UserGroup> {
    return this.apollo
      .mutate<UpdateProjectCommitteeResult>({
        mutation: updateProjectCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(
        map(res => res.data.updateProjectCommittee.committee),
        map(cloneDeep)
      );
  }

  getProjectMembershipDetails(
    projectId: string,
    organizationId?: string,
    accountB2bId?: string,
    filter?: string,
    noCache = false
  ) {
    return this.apollo.watchQuery({
      query: projectMembershipDetails,
      fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      variables: {
        projectId,
        organizationId,
        accountB2bId,
        filter,
      },
    }).valueChanges;
  }

  getProjectMembershipWithTiers(projectId: string, organizationId?: string) {
    return this.apollo.watchQuery({
      query: projectMembershipWithTiers,
      variables: {
        projectId,
        organizationId,
      },
    }).valueChanges;
  }

  deleteProjectCommittee(
    committeeInfo: DeleteProjectCommitteeInput
  ): Observable<boolean> {
    return this.apollo
      .mutate<DeleteProjectCommitteeResult>({
        mutation: deleteProjectCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(
        map(res => res.data.deleteProjectCommittee._empty),
        map(cloneDeep)
      );
  }

  getProjectMeetings(id: string): Observable<Meeting[]> {
    return this.apollo
      .watchQuery<ProjectMeetingResult>({
        query: projectMeetingsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectMeeting),
        map(cloneDeep)
      );
  }

  getProjectResources(id: string, noCache = false): Observable<Resource[]> {
    return this.apollo
      .watchQuery<ProjectResourceResult>({
        query: projectResourcesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectResource),
        map(cloneDeep)
      );
  }

  getProjectStatistics(id: string): Observable<Statistics> {
    return this.apollo
      .watchQuery<ProjectStatisticsResult>({
        query: projectStatisticsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectStatistics),
        map(cloneDeep)
      );
  }

  getLfSponsoredProjects(): Observable<FoundationProject[]> {
    return this.apollo
      .watchQuery<LfSponsoredProjectsResult>({
        query: lfSponsoredProjectsQuery,
      })
      .valueChanges.pipe(
        map(res => res.data.lfSponsoredProjects),
        map(cloneDeep)
      );
  }

  getActivityLogs(
    input: ProjectActivityLogsInput,
    useCache: boolean
  ): Observable<ActivityLog[]> {
    return this.apollo
      .watchQuery<ProjectActivityLogResult>({
        query: projectActivityLogsQuery,
        fetchPolicy: !useCache ? 'no-cache' : 'cache-and-network',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectActivityLogs),
        map(cloneDeep)
      );
  }

  getCommitteesCategories() {
    return this.apollo
      .watchQuery<ProjectCommitteesCategoriesResult>({
        query: projectCommitteeCategoriesQuery,
      })
      .valueChanges.pipe(
        map(res => res.data.projectsCommitteeCategory),
        map(cloneDeep)
      );
  }

  getContributorProjects(userId, organizationId, projectId) {
    return this.apollo
      .watchQuery<ContributorProjectsResult>({
        query: contributorProjectsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          userId,
          organizationId,
          projectId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.contributorProjects.projects),
        map(cloneDeep)
      );
  }

  getProjectRepositories(projectId) {
    return this.apollo
      .watchQuery<ProjectRepositoriesResult>({
        query: projectRepositoriesQuery,
        variables: {
          projectId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectRepositories.data),
        map(cloneDeep)
      );
  }

  getMembershipProjects() {
    return this.apollo
      .watchQuery<{ membershipProjects: MembershipProjectsRow[] }>({
        query: membershipProjectsQuery,
      })
      .valueChanges.pipe(
        map(res => res.data.membershipProjects),
        map(cloneDeep)
      );
  }

  private getProjectForStatisticsQuery(
    id: string,
    filter?: string
  ): QueryRef<ProjectResult> {
    const query = this.apollo.watchQuery<ProjectResult>({
      query: projectForStatisticsQuery,
      variables: {
        id,
        filter,
      },
    });

    return query;
  }
}
