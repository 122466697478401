// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributorAcknowledgementsQuery = gql`
  query getCompanyContributorAcknowledgements($input: ClaDefaultInput!) {
    getCompanyContributorAcknowledgements(input: $input) {
      code
      message
      success
      contributorsList {
        linuxFoundationId
        githubId
        name
        timestamp
        signatureVersion
        photoUrl
      }
    }
  }
`;
