// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  // authClientConfig,
  // authClientToken,
  localStorageToken,
  // socialAuthClientConfig,
  // socialAuthClientToken,
} from '@config';
import { environment } from '@environment';
import { ApolloClientsModule } from '@lfx/apollo-clients.module';
import { AppComponent } from '@lfx/core/containers/app.component';
import { CoreModule } from '@lfx/core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { WebAuth } from 'auth0-js';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';

import { AppRoutingModule } from './app-routing.module';
import { InitService } from './init.service';
import { AuthModule } from './modules/auth/auth.module';
import { metaReducers, reducers } from './state';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router, Event, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

export const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 4,
  spaceBetween: 0,
  navigation: {
    nextEl: '.custom-swiper-button-next',
    prevEl: '.custom-swiper-button-prev',
  },
  breakpoints: {
    1400: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 2,
    },
    760: {
      slidesPerView: 1,
    },
  },
};

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CoreModule,
    ApolloClientsModule,
    AuthModule,
    AppRoutingModule,
    SwiperModule,
    NgxPermissionsModule.forRoot(),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://ngrx.io/guide/effects#registering-root-effects
     */
    EffectsModule.forRoot([]),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'LFX',
          // In a production build you would want to disable the Store Devtools
          logOnly: environment.production,
          maxAge: 30,
        })
      : [],

    HighchartsChartModule,
    NgbModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    // {
    //   provide: socialAuthClientToken,
    //   useValue: new WebAuth(socialAuthClientConfig),
    // },
    // {
    //   provide: authClientToken,
    //   useValue: new WebAuth(authClientConfig),
    // },
    { provide: localStorageToken, useValue: localStorage },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: InitService) => () => initService.init(),
      deps: [InitService],
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        pairwise()
      )
      .subscribe(eventPair => {
        const previousEvent = eventPair[0];
        const event = eventPair[1];

        if (event.position) {
          // backward navigation
          viewportScroller.scrollToPosition(event.position);
        } else if (event.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(event.anchor);
        } else {
          // forward navigation
          if (
            previousEvent.routerEvent.urlAfterRedirects.split('/')[2] !==
            event.routerEvent.urlAfterRedirects.split('/')[2]
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
