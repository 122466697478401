// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const zoomMeetingsRegistrantsQuery = gql`
  query zoomMeetingsRegistrants($input: ZoomMeetingsRegistrantsInput) {
    zoomMeetingsRegistrants(input: $input) {
      registrants {
        email
        firstName
        id
        jobTitle
        lastName
        org
        type
        userId
      }
    }
  }
`;
