// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listUsersQuery = gql`
  query listUsers {
    listUsers {
      id
      username
      firstName
      lastName
      avatarUrl
      fullName
      emails {
        emailAddress
        emailType
        active
        isDeleted
        isPrimary
        isVerified
      }
      permissions {
        context
        actions
        resource
      }
      roles
      auth0Id
      timezone
      socialAccounts {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
