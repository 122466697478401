// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const uploadFileMutation = gql`
  mutation uploadContractDoc($file: Upload!) {
    uploadContractDoc(file: $file) {
      filename
      fileUrl
      code
      message
      success
    }
  }
`;
