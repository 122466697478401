// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const labelEmployeesAsAdministratorsMutation = gql`
  mutation labelEmployeesAsAdministrators($input: [SendInviteInput]!) {
    labelEmployeesAsAdministrators(input: $input) {
      code
      message
      success
      inviteSent
    }
  }
`;
