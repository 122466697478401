// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationAdminsQuery = gql`
  query organizationAdmins($salesforceId: ID!) {
    organizationAdmins(salesforceId: $salesforceId) {
      id
      name
      logoUrl
      email
      roleId
      scopeId
    }
  }
`;
