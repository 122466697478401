<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<input
  id="logoFile"
  #upload
  (change)="onImageUpload($event.target)"
  (cancel)="closeModal()"
  class="logo-input"
  type="file"
  accept=".svg"
/>

<ng-container *ngIf="errorMessage || svgImage">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload Logo</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body" ngbAutofocus>
    <ng-container *ngIf="svgImage; else errorMessageTpl">
      You are changing your organization's logo to:
      <div class="d-flex justify-content-center">
        <img
          [src]="svgImage"
          alt="Image"
          class="context-image context-image-dimensions"
          (error)="svgImage = null"
        />
      </div>
    </ng-container>
  </div>
  <div class="modal-footer justify-content-end">
    <button
      type="button"
      class="cancel-button cursor-pointer"
      [ngClass]="{ disabled: false }"
      (click)="closeModal()"
      [disabled]="false"
    >
      Cancel
    </button>
    <ng-container *ngIf="svgImage; else uploadButtonTpl">
      <button
        class="save-button cursor-pointer"
        [ngClass]="{
          'disable-color': loading
        }"
        (click)="saveChanges()"
        type="submit"
        [disabled]="loading"
      >
        Save Changes
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </ng-container>
  </div>
</ng-container>

<ng-template #errorMessageTpl>
  <div class="">
    <lfx-error-alert [message]="'Your upload failed. Please try again.'">
    </lfx-error-alert>
    The logo must be in the following format:
    <ul>
      <li>SVG</li>
      <li>Up to 1 MB</li>
      <li>SVG content with 'script' tag, 'entity' tag and external links are not allowed</li>
    </ul>
  </div>
</ng-template>

<ng-template #uploadButtonTpl>
  <button
    class="save-button cursor-pointer"
    (click)="upload.click()"
    type="submit"
  >
    Upload Again
  </button>
</ng-template>
