// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listSbomFilesQuery = gql`
  query listSbomFiles($input: ListSbomFilesInput!) {
    listSbomFiles(input: $input) {
      data {
        componentCount
        error
        firstName
        id
        isDeleted
        lastName
        licenseCount
        name
        uploadedBy
        uploadedDate
        isProceeded
        status
      }
      metadata {
        offset
        pageSize
        totalSize
      }
    }
  }
`;
