// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createAddressMutation = gql`
  mutation createAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      code
      message
      success
      address {
        id
        city
        country
        postalCode
        state
        street
      }
    }
  }
`;
