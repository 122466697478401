// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allOrgsCertificationsQuery = gql`
  query allOrgsTnCSummary($dateRange: String) {
    allOrgsTnCSummary(dateRange: $dateRange) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      certifications {
        product {
          name
        }
        thisOrg {
          learners
          certifiedLearners
          certificatesAchieved
        }
      }
    }
  }
`;
