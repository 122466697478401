// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const viewsEventsSummaryQuery = gql`
  query viewsEventsSummary($organizationId: String, $dateRange: String) {
    viewsEventsSummary(organizationId: $organizationId, dateRange: $dateRange) {
      myOrgEvents
      allEvents
      myOrgEmployeesEngaged
      myOrgTotalSpeakers
      myOrgTotalPresentations
      eventsSpokenAt
      totalAudienceReached
      myOrgTotalAttendees
      myOrgEventsAttended
    }
  }
`;

export const orgEventsParticipationSummaryQuery = gql`
  query orgEventsParticipationSummary($input: EventInput) {
    orgEventsParticipationSummary(input: $input) {
      myOrgEvents
      myOrgEmployeesEngaged
      myOrgTotalSpeakers
      eventsSpokenAt
      totalAudienceReached
      myOrgTotalAttendees
      myOrgEventsAttended
    }
  }
`;
