// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allProjectsQuery = gql`
  query allProjects {
    projects {
      badgeUrl
      autoJoinEnabled
      enabledServices
      membershipAutorenew
      name
      parent
      status
      category
      createdDate
      endDate
      id
      modifiedDate
      projectType
      systemModStamp
      type
      description
      logoUrl
      colorBand
      tag
      community {
        contributors
        commits
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      groupName
      slug
      model
      entityType
    }
  }
`;
