// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectCommitteesV2Query = gql`
  query projectCommitteesV2($projectId: ID!, $pageSize: Int, $offset: Int) {
    projectCommitteesV2(
      projectId: $projectId
      pageSize: $pageSize
      offset: $offset
    ) {
      data {
        name
        id
        totalMembers
        category
        members {
          data {
            firstName
            lastName
            logoUrl
            organization {
              logoUrl
              name
              id
            }
          }
        }
      }
      metadata {
        totalSize
        offset
        pageSize
      }
    }
  }
`;
