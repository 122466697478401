// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const emailTemplatesQuery = gql`
  query emailTemplates($name: String, $filter: String) {
    emailTemplates(name: $name, filter: $filter) {
      type
      title
      template
    }
  }
`;
