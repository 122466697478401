// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const securityLanguagesDistribution = gql`
  query securityLanguagesDistribution(
    $input: SecurityLanguagesDistributionInput
  ) {
    securityLanguagesDistribution(input: $input) {
      languages {
        language
        numberOfBytes
        sfdcProjectId
        externalId
      }
      summary {
        totalNumberOfBytes
        totalProjects
      }
    }
  }
`;
