// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationForStatisticsQuery = gql`
  fragment VisualizationTypes on visualizationTypes {
    ... on Visualizations {
      id
      type
      title
      rows
      cols
    }
    ... on ChartsVisualization {
      labels
      dataSets {
        label
        data
      }
    }
    ... on NumberVisualization {
      value
      prefix
      suffix
      color
    }
    ... on DataGridVisualization {
      columns {
        prop
        name
        type
      }
      data
    }
  }

  query foundation($id: ID!, $filter: String) {
    foundation(id: $id) {
      id
      statistics {
        trends(filter: $filter) {
          id
          title
          filter {
            name
            options {
              key
              value
              default
            }
          }
          visualizations {
            git {
              ...VisualizationTypes
            }
            commits {
              ...VisualizationTypes
            }
            commitsByTimeZone {
              ...VisualizationTypes
            }
            authorsList {
              ...VisualizationTypes
            }
            authors {
              ...VisualizationTypes
            }
            organizationsSummary {
              ...VisualizationTypes
            }
            organizationsInfo {
              ...VisualizationTypes
            }
            repositoriesList {
              ...VisualizationTypes
            }
            organizationsList {
              ...VisualizationTypes
            }
          }
        }
      }
    }
  }
`;
