// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateCommiteeContactMutation = gql`
  mutation updateCommiteeContact($input: UpdateCommiteeContactInput!) {
    updateCommiteeContact(input: $input) {
      message
    }
  }
`;
