// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const speakersSummaryQuery = gql`
  query speakersSummary(
    $organizationId: String
    $dateRange: String
    $pageSize: Int
    $orderBy: String
    $sortDir: String
    $eventId: String
    $jfilter: String
    $offset: Int
  ) {
    speakersSummary(
      organizationId: $organizationId
      dateRange: $dateRange
      pageSize: $pageSize
      orderBy: $orderBy
      sortDir: $sortDir
      eventId: $eventId
      jfilter: $jfilter
      offset: $offset
    ) {
      metadata {
        offset
        pageSize
        totalSize
      }
      speakers {
        city
        contactId
        country
        email
        firstName
        gender
        jobFunction
        jobLevel
        events {
          id
          name
          startDate
          presentations
        }
        lastName
        logo
        state
        title
        totalEventsSpokenAt
      }
    }
  }
`;

export const dashboardSpeakersSummaryQuery = gql`
  query dashboardSpeakersSummary($organizationId: String, $eventId: String) {
    dashboardSpeakersSummary(
      organizationId: $organizationId
      eventId: $eventId
    ) {
      speakers {
        contactId
        firstName
        lastName
        logo
        title
        email
      }
    }
  }
`;

export const dashboardOrgUserEventsQuery = gql`
  query dashboardOrgUserEvents($organizationId: ID, $userId: ID) {
    dashboardOrgUserEvents(organizationId: $organizationId, userId: $userId) {
      name
      image
      email
      eventName
      startDate
    }
  }
`;
