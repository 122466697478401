// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const registerNewDomainMutation = gql`
  mutation registerNewDomainMutation($domainInfo: RegisterDomainInput!) {
    registerDomain(input: $domainInfo) {
      code
      message
      success
    }
  }
`;
