// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deleteSbomFileMutation, uploadSbomFileMutation } from '../mutations';
import {
  DeleteSbomFileInput,
  GetResponse,
  ListLicensesAndPackagesInput,
  ListLicensesAndPackagesResponse,
  ListLicensesAndPackagesResults,
  ListSbomFilesData,
  ListSbomFilesInput,
  ListSbomFilesResults,
  SbomSearchComponentsResponse,
  SbomSoftwareEvaluationResponse,
  SbomSoftwareEvaluationsResults,
  SearchComponentsInput,
  SearchComponentsResults,
  UploadSbomFilePayload,
  UploadSbomFileResponse,
} from '@models';
import { MutationResponse } from '@lfx/core/models';
import {
  listSbomFilesQuery,
  listSbomLicensesQuery,
  listSbomInventoryStatsQuery,
  searchComponentsQuery,
  listLicensesAndPackagesQuery,
  sbomSoftwareValuationQuery,
} from '../queries';

@Injectable({
  providedIn: 'root',
})
export class SoftwareInventoryServiceGql {
  constructor(private apollo: Apollo) {}

  uploadSbomFile(
    input: UploadSbomFilePayload
  ): Observable<MutationResponse<UploadSbomFileResponse>> {
    return this.apollo
      .mutate<any>({
        mutation: uploadSbomFileMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data.uploadSbomFile),
        map(cloneDeep)
      );
  }

  listSbomFiles(
    input: ListSbomFilesInput,
    noCache = true
  ): Observable<GetResponse<ListSbomFilesData>> {
    return this.apollo
      .watchQuery<ListSbomFilesResults>({
        query: listSbomFilesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listSbomFiles),
        map(cloneDeep)
      );
  }

  deleteSbomFile(
    input: DeleteSbomFileInput
  ): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: deleteSbomFileMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data.deleteSbomFile),
        map(cloneDeep)
      );
  }

  listSbomLicenses(orgId, noCache = true): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: listSbomLicensesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          orgId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listSbomLicenses),
        map(cloneDeep)
      );
  }

  listSbomInventoryStats(orgId, noCache = true): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: listSbomInventoryStatsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          orgId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listSbomInventoryStats),
        map(cloneDeep)
      );
  }

  searchComponents(
    input: SearchComponentsInput,
    noCache = true
  ): Observable<SbomSearchComponentsResponse> {
    return this.apollo
      .watchQuery<SearchComponentsResults>({
        query: searchComponentsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.searchComponents),
        map(cloneDeep)
      );
  }

  listLicensesAndPackages(
    input: ListLicensesAndPackagesInput,
    noCache = false
  ): Observable<ListLicensesAndPackagesResponse> {
    return this.apollo
      .watchQuery<ListLicensesAndPackagesResults>({
        query: listLicensesAndPackagesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listLicensesAndPackages),
        map(cloneDeep)
      );
  }

  sbomSoftwareValuation(
    orgId: string,
    noCache = true
  ): Observable<SbomSoftwareEvaluationResponse> {
    return this.apollo
      .watchQuery<SbomSoftwareEvaluationsResults>({
        query: sbomSoftwareValuationQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          orgId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.sbomSoftwareValuation),
        map(cloneDeep)
      );
  }
}
