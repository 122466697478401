// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const membershipHistoryQuery = gql`
  query membershipHistory($accountId: ID!, $filter: String, $pageSize: Int) {
    membershipHistory(
      accountId: $accountId
      filter: $filter
      pageSize: $pageSize
    ) {
      projectId
      projectName
      id
      project {
        id
        projectType
        slug
      }
      product {
        name
      }
      installDate
      endDate
      status
      membershipDocDownloadUrl
    }
  }
`;
