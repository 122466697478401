// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allOrgsContributionsCubeQuery = gql`
  query getAllOrgsContributions($input: AllOrgsContributionsInput) {
    getAllOrgsContributions(input: $input) {
      code
      message
      allOrgsContributionsRes {
        countCommits
        countContributions
        countPullrequests
        countIssues
        countLinesAdded
        countLinesDeleted
        countProjects
        countContributors
        countContributorsCommitters
        countContributorsSubmitters
      }
    }
  }
`;
