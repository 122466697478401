// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const searchUserQuery = gql`
  query searchUser($input: SearchUserInput) {
    searchUser(input: $input) {
      id
      firstName
      lastName
      primaryEmail
      username
      phone
      title
      emails {
        emailAddress
        isPrimary
      }
    }
  }
`;
