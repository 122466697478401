// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getOrgEventsParticipantQuery = gql`
  query getOrgEventsParticipant($input: GetOrgEventsParticipantInput) {
    getOrgEventsParticipant(input: $input) {
      code
      message
      getOrgEventsParticipantRes {
        email
        events {
          country
          id
          name
          startDate
          type
        }
        firstname
        lastname
        photoUrl
        userId
        userName
        linkedInId
        githubId
        twitterId
      }
    }
  }
`;
