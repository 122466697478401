// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const userTrainingsQuery = gql`
  query (
    $dateRange: String = ""
    $userId: ID = ""
    $email: String = ""
    $organizationId: ID = ""
  ) {
    getUserTrainings(
      dateRange: $dateRange
      userId: $userId
      email: $email
      organizationId: $organizationId
    ) {
      userid
      ym
      value
      training
      certification
    }
  }
`;

export interface userTrainingsGraphQueryVariables {
  userId?: string;
  email?: string;
  dateRange?: string;
  organizationId?: string;
}

export interface userTrainingsGraphQueryResponse {
  getUserTrainings: userTrainingsRow[];
}

export interface userTrainingsRow {
  ym: string;
  value: number;
  training: number;
  certification: number;
}
