// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const enum PageIds {
  ProjectGroup,
  IndividualProject,
  OrganizationProfile,
  Dashboard,
  Faq,
}

export const helpSupportBase =
  'https://docs.linuxfoundation.org/docs/communitybridge/easycla';
export const easyclaHelp = {
  easyClaUrlRegex: '/company-dashboard/.*',
  easyClaHelpPaths: {
    [PageIds.Faq]:
      'https://docs.linuxfoundation.org/docs/communitybridge/communitybridge-easycla/getting-started/easycla-faqs',
    support: 'https://jira.linuxfoundation.org/servicedesk/customer/portal/4',
    [PageIds.Dashboard]:
      'https://docs.linuxfoundation.org/docs/v/v2/communitybridge/company-dashboard/dashboard',
    [PageIds.ProjectGroup]:
      'https://docs.linuxfoundation.org/docs/v/v2/communitybridge/company-dashboard/projects',
    [PageIds.IndividualProject]:
      'https://docs.linuxfoundation.org/docs/v/v2/communitybridge/company-dashboard/projects',
    [PageIds.OrganizationProfile]:
      ' https://docs.linuxfoundation.org/docs/v/v2/communitybridge/company-dashboard/manage-your-organization',
  },
};
