// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const environment = {
  production: false,
  log: true,
  featureFlags: true,
  auth0: {
    domain: 'linuxfoundation-ENV.auth0.com',
    audience: 'https://linuxfoundation-ENV.auth0.com/api/v2/',
    client_id: 'TO_BE_PROVIDED',
    redirect_uri: `${window.location.origin}/auth`,
    responseType: 'token id_token',
  },
  auth0Social: {
    domain: 'linuxfoundation-ENV.auth0.com',
    audience: 'https://linuxfoundation-ENV.auth0.com/api/v2/',
    client_id: 'TO_BE_PROVIDED',
    redirect_uri: `${window.location.origin}/user/account-settings`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  lfxHeaderUrl: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  ldClientKey: '65030d16cd72c511e3efc532',
  apiUrl: 'http://localhost:5000',
  graphqlUrl: 'http://localhost:5000/graphql',
  joinFoundationUrl:
    'https://joinnow.ENV.platform.linuxfoundation.org/?app=lfx&project=',
  cclaUrl: 'https://joinnow.ENV.platform.linuxfoundation.org/?app=lfx&project=',
  gtmId: 'GTM-MJ5SJTD',
  profileManagementUrl: 'https://myprofile.dev.platform.linuxfoundation.org/',
  enrollmentAPI: 'https://xxffhw47ze.execute-api.us-east-2.amazonaws.com/dev',
  enrollmentCDN:
    'https://joinnow.dev.platform.linuxfoundation.org/wc/joinnow-form.min.js',
  lfxAcsUICDN: 'https://lfx-acs-ui.dev.platform.linuxfoundation.org',
  v3Url: 'https://lfx.v3.dev.platform.linuxfoundation.org',
  v2Url: 'https://lfx.v2.dev.platform.linuxfoundation.org',
};
