// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const certificationsQuery = gql`
  query organizationCertifications($dateRange: String) {
    myOrganization {
      id
      certificationsConnection(dateRange: $dateRange) {
        certifications {
          product {
            name
          }
          thisOrg {
            learners
            certifiedLearners
            certificatesAchieved
          }
        }
      }
    }
  }
`;
