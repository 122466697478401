// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributorsGrowthCubeQuery = gql`
  query contributorsGrowth($input: ProjectContributorsInput) {
    contributorsGrowth(input: $input) {
      code
      message
      cummulativeCount {
        totalContributors {
          count
          prevCount
          percentageDiff
        }
        committers {
          count
          prevCount
          percentageDiff
        }
        reviewers {
          count
          prevCount
          percentageDiff
        }
        maintainers {
          count
          prevCount
          percentageDiff
        }
      }
      chart {
        countCommitters
        countMaintainers
        countReviewers
        timestamp
        label
      }
    }
  }
`;
