// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createMembershipContactMutation = gql`
  mutation createMembershipContact($input: CreateMembershipContactInput!) {
    createMembershipContact(input: $input) {
      message
      code
      success
      contact {
        role
        systemModstamp
        createdDate
        contact {
          id
        }
      }
    }
  }
`;
