// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listLicensesAndPackagesQuery = gql`
  query listLicensesAndPackages($input: ListLicensesAndPackagesInput!) {
    listLicensesAndPackages(input: $input) {
      code
      message
      payload {
        licenses {
          id
          isApproved
          name
        }
        packages {
          packageName
        }
      }
    }
  }
`;
