// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const insightsSummaryQuery = gql`
  query insightsSummary($organizationId: String, $pageSize: Int) {
    insightsSummary(organizationId: $organizationId, pageSize: $pageSize) {
      metadata {
        pageSize
        offset
        totalSize
      }
      data {
        totalContributions
        codeContributions
        lastContributionDate
        commits {
          contribution
          totalContribution
          contributors
          totalContributors
        }
        project {
          slug
          logoURL
        }
      }
    }
  }
`;
