// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';

import { convertObjectToArray } from '../helper';
import { imagePlaceholders } from '@config';

export const randomCompany = (): Company =>
  faker.random.arrayElement(convertObjectToArray(demoCompanies));

export const mockCompany = (): any => {
  const name = faker.company.companyName(0);

  return {
    id: faker.random.uuid(),
    name,
    description: faker.lorem.paragraph(),
    logoUrl: imagePlaceholders.company,
    address: faker.address.streetAddress(),
    billingAddress: {
      billingStreet: faker.address.streetAddress(),
      billingCity: faker.address.city(),
      billingState: faker.address.state(),
      billingPostalCode: faker.address.zipCode(),
      billingCountry: faker.address.country(),
    },
    city: faker.address.city(),
    state: faker.address.state(),
    country: 'United States',
    zip: faker.address.zipCode(),
    phone: faker.phone.phoneNumberFormat(),
    website: faker.internet.url(),
    link: '',
  };
};

import { UserBlock, MemberInvite, Foundation } from '@models';

export interface CompanyAdministrators {
  administrators: UserBlock[];
}

export interface BillingAddress {
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountry: string;
}

export interface Address {
  id: string;
  title: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  billToName: string;
}

export type OrganizationAddress = Pick<
  Address,
  'billToName' | 'city' | 'country' | 'postalCode' | 'state' | 'street'
>;

export interface CreateAddressInput {
  organizationId: string;
  address: OrganizationAddress;
}

export interface UpdateAddressInput {
  organizationId: string;
  addressId: string;
  address: Partial<OrganizationAddress>;
}

export interface UpdateBenefitClaimedInput {
  organizationId: string;
  membershipId: string;
  projectBenefitTierId: string;
  claimed: boolean;
}

export interface Company {
  id: string;
  sfId?: string;
  name: string;
  slug: string;
  description?: string;
  logoUrl: string;
  address: string;
  address2?: string;
  billingAddress: BillingAddress;
  city: string;
  country: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
  link?: string;
  lastModifiedAt?: string;
  admins?: UserBlock[];
  crunchBaseUrl?: string;
  serviceUsers?: ServiceUser[];
  committeeMembersConnection?: CommitteeMembersConnection;
  invites?: MemberInvite[];
  foundations?: OrganizationFoundation[];
  committeeFoundations?: OrganizationFoundation[];
  signingEntityName?: string[];
  employees: string;
  totalEmployees: number;
  industry: string;
  sector: string;
  lastEmployeeSizeVerfiedDate: string | null;
  joinNowNoOfEmployees: string;
  contribution: CompanyProjectContributionConnection;
  contributionStats: CompanyContributionStats;
  contributionPerEmployee: CompanyEmployeeContributionConnection;
  contributionMonthly: CompanyProjectContributionMonthlyConnection;
  contributionPerProjectMonthly: CompanyProjectContributionMonthlyConnection;
  contributionPerProject: CompanyProjectContributionConnection;
  domainAlias: string;
  salesforceB2BAccountId: string;
  isMember: boolean;
  isParent: boolean;
  parentId?: string;
  parentDetail?: ParentDetail;
  accountPrimaryDomain?: string;
}

export interface ParentDetail {
  id: string;
  logoUrl: string;
  name: string;
  salesforceB2BAccountId: string;
  slug: string;
}

export interface ContributionsResponse {
  contribution: CompanyProjectContributionConnection;
  contributionStats: CompanyContributionStats;
  contributionPerEmployee: CompanyEmployeeContributionConnection;
  contributionMonthly: CompanyProjectContributionMonthlyConnection;
  contributionPerProjectMonthly: CompanyProjectContributionMonthlyConnection;
  contributionPerProject: CompanyProjectContributionConnection;
  contributionPerProjectStats: CompanyContributionPerProjectStats;
}

export interface ServiceUser {
  id: string;
  username: string;
  email: string;
  name: string;
  avatar: string;
  roles: ServiceUserRole[];
}

export interface ServiceUserRole {
  roleId: string;
  name: string;
  projectName: string;
  foundationName: string;
  scopeId: string;
  scopeTypeId: string;
  projectId: string;
}

export interface DeleteUserRoleScopeInput {
  organizationId: string;
  roleId: string;
  scopeId: string;
}

export interface CommitteeMemberContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  logoUrl: string;
}

export interface CommitteeMember {
  appointedBy: string;
  category: string;
  committeeID: string;
  committeeMemberID: string;
  name: string;
  role: string;
  roleStartDate: string;
  roleEndDate: string;
  status: string;
  contact: CommitteeMemberContact;
  project: OrganizationFoundation;
}

export interface CompanyProjectContributionConnection {
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
  data: CompanyProjectContribution[];
}

export interface CompanyProjectContributionMonthlyConnection {
  months: CompanyProjectContributionMonthlyV2Connection[];
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
  data: CompanyProjectContributionMonthly[];
  projects: OrganizationProjectStatQueryResponse[];
}

export interface CompanyProjectContributionMonthlyV2Connection {
  __typename: string;
  countofcommit: number;
  countofissue: number;
  countofpr: number;
  ym: string;
}

export interface CompanyEmployeeContributionConnection {
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
  data: CompanyEmployeeContribution[];
}

export interface OrganizationContributorInsightsConnection {
  metadata: {
    offset: number;
    pageSize: number;
    totalSize: number;
  };
  data: OrganizationContributorInsight[];
}

export interface CompanyContribution {
  chat: number;
  codeContributions: number;
  commits: {
    contribution: number;
  };
  mailingList: number;
  totalContributions: number;
  code_changed: number;
  count_of_commit: number;
  count_of_contribution: number;
  count_of_contributor: number;
  count_of_issue: number;
  count_of_pr: number;
  count_of_project: number;
  count_of_user: number;
  loc_added: number;
  loc_del: number;
}

export interface CompanyProjectContributionMonthly {
  date: string;
  insights: {
    chat: number;
    mailingList: number;
  };
}

export interface CompanyEmployeeContribution extends CompanyContribution {
  name: string;
  pr: number;
  issues: number;
  projects: {
    contribution: number;
    description: string;
    id: string;
    industrySector: string;
    logoUrl: string;
    name: string;
    slug: string;
  };
}

export interface CompanyContributionPerProjectStats
  extends CompanyContribution {
  issues: {
    all: {
      contribution: number;
      totalContribution: number;
    };
    other: {
      contribution: number;
      totalContribution: number;
    };
    resolved: {
      contribution: number;
      totalContribution: number;
    };
    submitted: {
      contribution: number;
      totalContribution: number;
    };
  };
  pr: {
    all: {
      contribution: number;
      totalContribution: number;
    };
    approved: {
      contribution: number;
      totalContribution: number;
    };
    merged: {
      contribution: number;
      totalContribution: number;
    };
    other: {
      contribution: number;
      totalContribution: number;
    };
    reviewed: {
      contribution: number;
      totalContribution: number;
    };
    submitted: {
      contribution: number;
      totalContribution: number;
    };
  };
  commits: {
    contribution: number;
    contributors: number;
    totalContribution: number;
    totalContributors: number;
  };
}

export interface CompanyContributionStats
  extends CompanyContributionPerProjectStats {
  totalProjects: number;
}

export interface CompanyProjectContribution extends CompanyContribution {
  project: {
    contribution: number;
    description: string;
    id: string;
    industrySector: string;
    logoUrl: string;
    name: string;
    slug: string;
  };
  issues: {
    all: {
      contribution: number;
      totalContribution: number;
    };
    other: {
      contribution: number;
      totalContribution: number;
    };
    resolved: {
      contribution: number;
      totalContribution: number;
    };
    submitted: {
      contribution: number;
      totalContribution: number;
    };
  };
  pr: {
    all: {
      contribution: number;
      totalContribution: number;
    };
    approved: {
      contribution: number;
      totalContribution: number;
    };
    merged: {
      contribution: number;
      totalContribution: number;
    };
    other: {
      contribution: number;
      totalContribution: number;
    };
    reviewed: {
      contribution: number;
      totalContribution: number;
    };
    submitted: {
      contribution: number;
      totalContribution: number;
    };
  };
  commits: {
    contribution: number;
    contributors: number;
    totalContribution: number;
    totalContributors: number;
  };
}

export interface OrganizationContributorInsight extends CompanyContribution {
  logoUrl: string;
  pr: number;
  issues: number;
  name: string;
}

export interface CommitteeMembersConnection {
  committeeMembers: CommitteeMember[];
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    endCursor: number;
  };
}

export interface OrgCertification {
  product: {
    name: string;
  };
  similarOrgsByIndustry: CertificationsLearners;
  similarOrgsBySize: CertificationsLearners;
  thisOrg: CertificationsLearners;
}

export interface CertificationsLearners {
  certificatesAchieved: number;
  certificatesAchievedAverage: number;
  enrollments: number;
  enrollmentsAverage: number;
  learners: number;
  learnersAverage: number;
  certifiedLearners: number;
  corporateEnrollments: {
    certificatesAchieved: number;
    enrollments: number;
    learners: number;
  };
  individualEnrollments: {
    certificatesAchieved: number;
    enrollments: number;
    learners: number;
  };
}

export interface CertificationConnection {
  certifications: OrgCertification[];
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    endCursor: number;
  };
}

export interface CertificationsGrouped {
  year: string;
  months?: CertificationsGroupedMonth[];
  quarters?: CertificationsGroupedMonth[];
}

export interface CertificationsGroupedMonth {
  label: string;
  certifications: OrgCertification;
}

export const companySectors = [
  'Consumer Discretionary',
  'Consumer Staples',
  'Energy',
  'Financials',
  'Health Care',
  'Industrials',
  'Information Technology',
  'Materials',
  'Professional Services',
  'Telecommunication Services',
  'Utilities',
];

export const companyIndustries = [
  'Aerospace & Defense',
  'Air Freight & Logistics',
  'Airlines',
  'Automotive',
  'Banks',
  'Beverages',
  'Biotechnology',
  'Building Materials',
  'Capital Goods',
  'Capital Markets',
  'Chemicals',
  'Commercial Services & Supplies',
  'Communication Equipment',
  'Construction & Engineering',
  'Consumer Discretionary',
  'Consumer Goods',
  'Consumer Services',
  'Consumer Staples',
  'Containers & Packaging',
  'Distributors',
  'Diversified Consumer Services',
  'Diversified Financial Services',
  'Diversified Telecommunication Services',
  'Education Services',
  'Electrical Equipment',
  'Electric Utilities',
  'Electronic Equipment & Instruments',
  'Family Services',
  'Food & Staples Retailing',
  'Food Products',
  'Gas Utilities',
  'Health Care Equipment & Supplies',
  'Heath Care Providers & Services',
  'Hotels, Restaurants & Leisure',
  'Household Durables',
  'Industrial Conglomerates',
  'Industrials',
  'Insurance',
  'Internet Software & Services',
  'IT Services',
  'Leisure Products',
  'Life Sciences Tools & Services',
  'Machinery',
  'Marine',
  'Media',
  'Metals & Mining',
  'Paper & Forest Products',
  'Personal Products',
  'Pharmaceuticals',
  'Professional Services',
  'Real Estate',
  'Renewable Electricity',
  'Retailing',
  'Road & Rail',
  'Semiconductors & Semiconductor Equipment',
  'Software',
  'Specialized Consumer Services',
  'Specialty Retail',
  'Tech Hardware, Storage & Peripherals',
  'Textiles, Apparel & Luxury Goods',
  'Tobacco',
  'Trading Companies & Distributors',
  'Transportation',
  'Utilities',
  'Wireless Telecommunication Service',
];

export const demoCompanies: Record<string, Company> = {
  acme: {
    ...mockCompany(),
    id: 'acme',
    name: 'Acme Corp',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/acme.png',
  },
  apple: {
    ...mockCompany(),
    id: 'apple',
    name: 'Apple',
    logoUrl: 'https://logo.clearbit.com/apple.com',
  },
  gitlab: {
    ...mockCompany(),
    id: 'gitlab',
    name: 'GitLab BV',
    logoUrl: 'https://logo.clearbit.com/gitlab.com',
  },
  jpmorgan: {
    ...mockCompany(),
    id: 'jpmorgan',
    name: 'JP Morgan Chase',
    logoUrl: 'https://logo.clearbit.com/jpmorgan.com',
  },
  rancher: {
    ...mockCompany(),
    id: 'rancher',
    name: 'Rancher',
    logoUrl: 'https://logo.clearbit.com/rancher.com',
  },
  digitalocean: {
    ...mockCompany(),
    id: 'digitalocean',
    name: 'DigitalOcean',
    logoUrl: 'https://logo.clearbit.com/digitalocean.com',
  },
  google: {
    ...mockCompany(),
    id: 'google',
    name: 'Google',
    logoUrl: 'https://logo.clearbit.com/google.com',
  },
  microsoft: {
    ...mockCompany(),
    id: 'microsoft',
    name: 'Microsoft',
    logoUrl: 'https://logo.clearbit.com/microsoft.com',
  },
  aws: {
    ...mockCompany(),
    id: 'aws',
    name: 'Amazon Web Services',
    logoUrl: 'https://logo.clearbit.com/aws.amazon.com',
  },
  cisco: {
    ...mockCompany(),
    id: 'cisco',
    name: 'Cisco',
    logoUrl: 'https://logo.clearbit.com/cisco.com',
  },
  dell: {
    ...mockCompany(),
    id: 'dell',
    name: 'Dell',
    logoUrl: 'https://logo.clearbit.com/dell.com',
  },
  jfrog: {
    ...mockCompany(),
    id: 'jfrog',
    name: 'JFrog Ltd',
    logoUrl: 'https://logo.clearbit.com/jfrog.com',
  },
  netapp: {
    ...mockCompany(),
    id: 'netapp',
    name: 'NetApp Inc',
    logoUrl: 'https://logo.clearbit.com/netapp.com',
  },
  suse: {
    ...mockCompany(),
    id: 'suse',
    name: 'SUSE',
    logoUrl: 'https://logo.clearbit.com/suse.com',
  },
  linuxfoundation: {
    ...mockCompany(),
    id: 'linuxfoundation',
    name: 'The Linux Foundation',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/linuxfoundation.svg',
  },
  fujitsu: {
    ...mockCompany(),
    id: 'fujitsu',
    name: 'Fujitsu',
    logoUrl: 'https://logo.clearbit.com/fujitsu.com',
  },
  huawei: {
    ...mockCompany(),
    id: 'huawei',
    name: 'Huawei Technologies',
    logoUrl: 'https://logo.clearbit.com/huawei.com',
  },
  alibaba: {
    ...mockCompany(),
    id: 'alibaba',
    name: 'Alibaba Cloud',
    logoUrl: 'https://logo.clearbit.com/alibabacloud.com',
  },
  ibm: {
    ...mockCompany(),
    id: 'ibm',
    name: 'IBM',
    logoUrl: 'https://logo.clearbit.com/ibm.com',
  },
  intel: {
    ...mockCompany(),
    id: 'intel',
    name: 'Intel',
    logoUrl: 'https://logo.clearbit.com/intel.com',
  },
  jdcom: {
    ...mockCompany(),
    id: 'jdcom',
    name: 'JD.com',
    logoUrl: 'https://logo.clearbit.com/jd.com',
  },
  oracle: {
    ...mockCompany(),
    id: 'oracle',
    name: 'Oracle',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/oracle.png',
  },
  pivotal: {
    ...mockCompany(),
    id: 'pivotal',
    name: 'Pivotal',
    logoUrl: 'https://logo.clearbit.com/pivotal.com',
  },
  redhat: {
    ...mockCompany(),
    id: 'redhat',
    name: 'Red Hat',
    logoUrl: 'https://logo.clearbit.com/redhat.com',
  },
  samsung: {
    ...mockCompany(),
    id: 'samsung',
    name: 'Samsung SDS',
    logoUrl: 'https://logo.clearbit.com/samsung.com',
  },
  sap: {
    ...mockCompany(),
    id: 'sap',
    name: 'SAP',
    logoUrl: 'https://logo.clearbit.com/sap.com',
  },
  vmware: {
    ...mockCompany(),
    id: 'vmware',
    name: 'VMware',
    logoUrl: 'https://logo.clearbit.com/vmware.com',
  },
  dreamworks: {
    ...mockCompany(),
    id: 'dreamworks',
    name: 'Dreamworks',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/dreamworks.png',
  },
  netflix: {
    ...mockCompany(),
    id: 'netflix',
    name: 'Netflix',
    logoUrl: 'https://logo.clearbit.com/netflix.com',
  },
  blueskystudios: {
    ...mockCompany(),
    id: 'blueskystudios',
    name: 'Blue Sky Studios',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/blueskystudios.jpg',
  },
  autodesk: {
    ...mockCompany(),
    id: 'autodesk',
    name: 'Autodesk',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/autodesk.png',
  },
  animalogic: {
    ...mockCompany(),
    id: 'animallogic',
    name: 'Animal Logic',
    logoUrl: 'https://logo.clearbit.com/animallogic.com',
  },
  dneg: {
    ...mockCompany(),
    id: 'dneg',
    name: 'DNEG',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/dneg.jpg',
  },
  unreal: {
    ...mockCompany(),
    id: 'unreal',
    name: 'Unreal Engine',
    logoUrl: 'https://logo.clearbit.com/unrealengine.com',
  },
};

export type OrganizationFoundation = Pick<
  Foundation,
  'id' | 'name' | 'logoUrl'
>;

export interface Committee {
  Category: string;
  CollaborationName: string;
  ID: string;
  Project: OrganizationFoundation;
}

export interface OrganizationCreatedSigningEntity {
  logoUrl: string;
  companyWebsite: string;
  companyId: string;
  companyName: string;
  signingEntityName: string;
}

export interface OrganizationContributorInsightsQueryParams {
  pageSize?: string;
  orderBy?: string;
  sortDir?: string;
  year?: string;
  jFilter?: string;
}

export interface OrganizationResearchQueryParams {
  type?: string;
  pageSize?: string;
  offset?: string;
}

export interface OrganizationResearch {
  data: Research[];
  metadata: {
    offset: 0;
    pageSize: 100;
    totalSize: 1;
  };
}

export interface Author {
  authorName: string;
  organizationLogoUrl: string;
}

export interface Research {
  description: string;
  documentUrl: string;
  logoUrl: string;
  title: string;
  authors: Author[];
  date: string;
}

export interface CompanyUpdateRouterState {
  successMessage: string;
}

export interface OrganizationCommitteeContactsQueryParams {
  projectId?: string;
  contactId?: string;
  committeeId?: string;
  status?: string;
}

export interface OrganizationCommitteeContact {
  appointedBy: string;
  category: string;
  committeeDescription: string;
  committeeId: string;
  committeeMemberId: string;
  committeeTitle: string;
  contact: CommitteeContact;
  name: string;
  project: CommitteeProject;
  role: string;
  roleEndDate: string;
  roleStartDate: string;
  status: string;
  votingEndDate: string;
  votingStartDate: string;
  votingStatus: string;
  requestStatus: string;
  requestedChange: string;
}

export interface CommitteeContact {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  photoUrl: string;
  title: string;
}

export interface CommitteeProject {
  id: string;
  logoUrl: string;
  name: string;
}

export interface RequestChangeCommitteeInput {
  orgId: string;
  projectId: string;
  body: IRequestChangePayload;
}

export interface IRequestChangePayload {
  committeeId: string;
  committeeMemberId: string;
  currentCommittee: AcsOrganizationProjectContactCommittee;
  requestedChange: IRequestChangePayloadCommittee;
}

export interface AcsOrganizationProjectContactCommittee {
  appointedBy?: string;
  committeeDescription?: string;
  committeeID?: string;
  committeeMemberID?: string;
  committeeTitle?: string;
  contact?: AcsOrganizationProjectCommiteeRowContact;
  name?: string;
  project?: AcsOrganizationProjectCommiteeRowProject;
  requestStatus?: string;
  requestedChange?: string;
  role?: string;
  status?: string;
  votingStatus?: string;
  category?: string;
  votingEndDate?: string;
  votingStartDate?: string;
}

export interface IRequestChangePayloadCommittee {
  projectName: string;
  committee: string;
  role?: string;
  roleId?: string;
  username?: string;
  name: string;
  title?: string;
  email: string;
  reason?: string;
}

export interface AcsOrganizationProjectCommiteeRowContact {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  photoUrl: string;
  title: string;
}

export interface AcsOrganizationProjectCommiteeRowProject {
  id: string;
  logoUrl: string;
  name: string;
}

export interface UpdateCommiteeContactInput {
  orgId: string;
  projectId: string;
  committeeMemberId: string;
}

export interface UpdateOrganizationUserInput {
  orgId: string;
  userIds: string[];
  body: UpdateOrgUserBody;
}

export interface UpdateOrganizationUserPayload {
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  orgId: string;
  orgUserId: string;
  ospoLeader: boolean;
  reason: string;
  status: string;
  userId: string;
}

export interface UpdateOrgUserBody {
  endDate?: string;
  ospoLeader?: boolean;
  reason?: string;
  status?: string;
}

export interface OrganizationContributosV2Response {
  total: number;
  contributors: OrganizationContributorV2[];
}

export interface OrganizationContributorV2 {
  email: string;
  name: string;
  userlogo: string;
  commits: number;
  userid: string;
}

export interface OrganizationStatsQueryResponse {
  __typename: string;
  projects: OrganizationProjectStatQueryResponse[];
  metadata: any;
  data: any;
}

export interface OrganizationProjectStatQueryResponse {
  __typename: string;
  slug?: string;
  projectname?: string;
  project_id?: string;
  logo?: string;
  countofemployees?: number;
  countofcommits?: number;
  share?: number;
}

export interface UIPaginationObject {
  pagination: UIPagination;
}

export interface UIPagination {
  fetch: number;
  size: number;
  page: number;
  total: number;
  maxSize: number;
}

export interface ContributorV1 {
  __typename: string;
  chat: number;
  codeContributions: number;
  commits: ContributorV1Commits;
  issues: number;
  mailingList: number;
  name: string;
  pr: number;
  logoUrl: string;
  projects: ContributorV1Project[];
  totalContributions: number;
}

export interface ContributorV1Commits {
  __typename: string;
  contribution: number;
}

export interface ContributorV1Project {
  __typename: string;
  totalContribution: number;
  contribution: number;
  description: string;
  id: string;
  industrySector: any;
  logoUrl: string;
  name: string;
  slug: string;
  parent: string;
}

export interface FormattedProjectv1 {
  __typename: string;
  totalContribution: number;
  contribution: number;
  description: string;
  id: string;
  industrySector: any;
  logoUrl: string;
  name: string;
  slug: string;
  parent: string;
}

export interface FormattedEmployeeV1 {
  name: FormattedEmployeeV1Name;
  emails?: string;
  userId?: string;
  commits: number;
  projects: FormattedEmployeeV1Project[];
}

export interface FormattedEmployeeV1Name {
  text: string;
  color: string;
  bold: boolean;
  image: string;
}

export interface FormattedEmployeeV1Project {
  __typename: string;
  totalContribution: number;
  contribution: number;
  description: string;
  id: string;
  industrySector: any;
  logoUrl: string;
  name: string;
  slug: string;
  parent: string;
}

export interface OrganizationEmployeeV2 {
  __typename: string;
  first_name: string;
  last_name: string;
  user_id: string;
  commits: number;
  userlogo: string;
}
export interface EmailTemplate {
  type: string;
  title: string;
  template: string;
}

export interface RemoveAllUserAccessInput {
  username: string;
  userId: string;
  organizationId: string;
  orgUserId: string;
}

export interface ReassignProjectCommitteeContactsInput {
  organizationId: string;
  userId: string;
  newProjectCommitteeContacts: NewProjectCommitteeContact[];
}

export interface NewProjectCommitteeContact {
  email: string;
  firstName: string;
  lastName: string;
  projectId: string;
  committeeId: string;
  committeeMemberId: string;
  role: string;
  votingStatus: string;
  allowUserUpdate: boolean;
}

export interface NewProjectCommitteeContactResult
  extends NewProjectCommitteeContact {
  success: boolean;
  message: string;
}

export interface ProjectContributionList {
  code: number;
  message: string;
  projectContributionList: ProjectContributionListItem[];
}

export interface ProjectContributionListItem {
  pkid: string;
  accountId: string;
  commitCountAllTime: number;
  membershipStatus: string;
  projectId: string;
  projectName: string;
  projectType: string;
  foundationId: string;
  managerName: string;
  managerEmail: string;
}

export interface OpenSourceInvolvement {
  code: number;
  message: null | string;
  involvement: {
    accountId: string;
    accountName: string;
    lfMembershipTier: string;
    activeMembershipCount: number;
    foundationCount: number;
    committeeMemberCount: number;
    contributorsCount: number;
    maintainersCount: number;
    technicalProjectCount: number;
  };
}

export interface ProjectEngagement {
  code: number;
  message: string;
  projectEngagement: {
    accountId: string;
    projectId: string;
    projectSlug: string;
    projectName: string;
    commitCountAllTime: number;
    maintainersCountAllTime: number;
    contributorsCountAllTime: number;
    totalEventsAllTime: number;
    totalSponsorshipAudienceReached: number;
    enrollmentsAllTime: number;
    eligibleMembers: number;
  };
}

export interface ProductsKpiInput {
  action: 'CREATE' | 'UPDATE' | 'DELETE';
  details: string;
  organizationId: string;
  type:
    | 'user_access'
    | 'key_contact'
    | 'profile_update'
    | 'sbom'
    | 'committee'
    | 'project_selected';
}
