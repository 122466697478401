// Copyright The Linux Foundation and each contributor to LFX.

import { User } from './user';

// SPDX-License-Identifier: MITs
export interface GetOrgEmployeesInput {
  organizationId: string;
  engagement?: string;
  pageSize?: number;
  offset?: number;
  sortDir?: string;
  orderBy?: string;
  search?: string;
  status?: string;
  filter?: string;
  morefilter?: string;
  committeefilter?: string;
  lfidfilter?: string;
  projectId?: string;
  membershipfilter?: string;
  ospoLeader?: boolean | null;
  contributorfilter?: string;
  exportToCSV?: boolean;
  activityfilter?: string;
  typeview?: string;
  noCache?: boolean;
  hasmembershipentitlement?: boolean;
}

export interface Employee {
  administrator: boolean;
  createdAt: Date;
  emailAddress: string;
  engagementType: EngagementType[];
  lastLoginDate: Date;
  lastModifiedAt: Date;
  logoUrl: string;
  maintainer: boolean;
  name: string;
  orgUserId: string;
  ospoLeader: boolean;
  reason: string;
  status: string;
  title: string;
  userId: string;
  username: string;
  contactRoles?: EngagementType[];
  pillHoverData?: EngagementType[];
  userRoles?: EmployeeUserRole[];
  otherEmailAddress?: string[];
  address?: User['address'];
  githubId?: User['githubId'];
  orgAffiliation?: EmployeeOrgAffiliation[];
}

export interface EngagementType {
  appointedBy: string;
  category: string;
  description: string;
  engagementId: string;
  engagementType: string;
  engagementTypeId: string;
  groupProjectId: string;
  projectId: string;
  projectName: string;
  role: string;
  userId: string;
  committeeName: string;
  projectLogoUrl: string;
  primaryContact: boolean;
  votingStatus: string;
}

export interface GroupedViewData {
  id: string;
  projectName: string;
  logoUrl: string;
  employees: Employee[];
}

export enum DashboardAccess {
  Admin = 'Admin',
  Viewer = 'Viewer',
  Pending_Removal = 'Pending Removal',
  Pending_Invite = 'Invited',
  Default = '—',
}

export interface EmployeeUserRole {
  committeeRoles?: CommitteeRole[];
  project: {
    logoURL: string;
    name: string;
    id: string;
  };
}

export interface CommitteeRole {
  appointedBy: string;
  category: string;
  committeeDescription: string;
  committeeId: string;
  committeeMemberId: string;
  committeeTitle: string;
  name: string;
  requestStatus: string;
  role: string;
  roleStartDate: string;
  roleEndDate: string;
  status: string;
  votingEndDate: string;
  votingStartDate: string;
  votingStatus: string;
}

export interface EmployeeOrgAffiliation {
  startDate: string;
  pkid: string;
  endDate: string;
}

export type InfoCardEmployee = Pick<
  Employee,
  | 'name'
  | 'username'
  | 'logoUrl'
  | 'title'
  | 'ospoLeader'
  | 'maintainer'
  | 'emailAddress'
  | 'otherEmailAddress'
  | 'engagementType'
  | 'userId'
  | 'address'
  | 'githubId'
  | 'orgAffiliation'
>;
