// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateFoundationFinanceMutation = gql`
  mutation updateFoundationFinance(
    $financeInfo: UpdateFoundationFinanceInput!
  ) {
    updateFoundationFinance(input: $financeInfo) {
      foundationFinance {
        name
        internationalAddress
        phone
        emailFrom
        website
        emailAddress
        city
        country
        state
        postalCode
        netSuiteClass
        department
        incomeGlAccount
        deferredRevenueAccount
        jobCode
        projectCode
        subsidiaryId
      }
    }
  }
`;
