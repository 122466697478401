// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const prsMergedByOrgsCubeQuery = gql`
  query getPRsMergedByOrgsChart($input: PRsByOrgsInput) {
    getPRsMergedByOrgsChart(input: $input) {
      code
      message
      prsMergedByOrgs {
        merged
        approved
        submitted
        reviewed
      }
    }
  }
`;
