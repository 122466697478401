// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const topEventsByAttendeesFromMyOrgQuery = gql`
  query topEventsByAttendeesFromMyOrg(
    $organizationId: String
    $dateRange: String
  ) {
    topEventsByAttendeesFromMyOrg(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      pastEventsDataSet {
        topAttendeesFromMyOrg
        averageAttendeesFromOtherOrgs {
          similarSize
          sponsoredSameTier
          similarIndustry
        }
        chartLabels
      }
      upcomingEventsDataSet {
        topAttendeesFromMyOrg
        averageAttendeesFromOtherOrgs {
          similarSize
          sponsoredSameTier
          similarIndustry
        }
        chartLabels
      }
      chartLabels
    }
  }
`;
