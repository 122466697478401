// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateAddressMutation = gql`
  mutation updateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      code
      message
      success
      address {
        city
        country
        postalCode
        state
        street
      }
    }
  }
`;
