// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectCommitteesQuery = gql`
  query project($id: ID!, $isCommittee: Boolean) {
    project(id: $id) {
      id
      committees(isCommittee: $isCommittee) {
        id
        name
        collaborationName
        category
        description
        url
        totalMembers
        isCommittee
        groupsTitle
      }
    }
  }
`;
