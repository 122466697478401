// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const subProjectsStatsInputQuery = gql`
  query getSubProjectsStats($input: SubProjectsStatsInput) {
    getSubProjectsStats(input: $input) {
      code
      message
      subProjectsStatsRes {
        project
        projectName
        countContributions
        countContributors
        countCommits
        countCommitters
      }
    }
  }
`;
