// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lfx-info-offcanvas',
  templateUrl: './info-offcanvas.component.html',
  styleUrls: ['./info-offcanvas.component.scss'],
})
export class InfoOffcanvasComponent implements OnInit {
  @Input() info: Info;

  constructor(public activeOffcanvas: NgbActiveOffcanvas) {}

  ngOnInit(): void {}

  onCloseModal() {
    this.activeOffcanvas.close();
  }
}

export interface Info {
  title: string;
  body: string;
  children?: Info[];
}
