// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const certifiedEmployeesByAreaOfInterestQuery = gql`
  query CertifiedEmployeesByAreaOfInterest(
    $organizationId: String!
    $dateRange: String!
  ) {
    certifiedEmployeesByAreaOfInterest(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      area
      certified
    }
  }
`;
