// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateFoundationEssentialsMutation = gql`
  mutation updateFoundationEssentials(
    $foundationEssential: UpdateFoundationEssentialsInput!
  ) {
    updateFoundationEssentials(input: $foundationEssential) {
      foundation {
        id
        name
        status
        description
        logoUrl
        colorBand
        slug
        projectSector
        owner {
          id
          firstName
          lastName
          email
        }
        opportunityOwner {
          id
          firstName
          lastName
          email
        }
        programManager {
          id
          firstName
          lastName
          email
        }
        executiveDirector {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
