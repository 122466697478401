// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createUserRoleScopeMutation = gql`
  mutation createUserRoleScopeForService(
    $input: CreateUserRoleScopeForServiceInput!
  ) {
    createUserRoleScopeForService(input: $input) {
      code
      message
      success
      inviteSent
    }
  }
`;

export const createUserRoleScopesMutation = gql`
  mutation createUserRoleScopes($input: [CreateUserRoleScopeForServiceInput]!) {
    createUserRoleScopes(input: $input) {
      code
      message
      success
      inviteSent
    }
  }
`;
