// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const linkSocialIdentityMutation = gql`
  mutation linkUserIdentity($input: LinkUserIdentityInput!) {
    linkUserIdentity(input: $input) {
      code
      message
      success
      identities {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
