// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';

import { mockList, randomBadgeId, randomEnum } from '../mocks/generator';
import { Company, mockCompany, randomCompany } from './company';
import { demoFoundationProjects } from './foundation-project';
import {
  FundingGoalSummary,
  FundingGoalType,
  mockFundingGoalSummary,
  mockBugsSummary,
} from './funding-summary';
import { mockPerson, Person } from './person';
import { imagePlaceholders } from '@config';

export interface CommunityInitiative {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  colorBand: string;
  tag: string;
  type: InitiativeType;
  category: string;
  badgeUrl?: string;
  siteUrl?: string;
  cocUrl?: string;
  status: InitiativeStatus;
  funding: {
    current: number;
    target: number;
    currencyCode: string;
    sponsors: Company[];
    backers: Person[];
    ledger: Ledger;
    goals: FundingGoalSummary[];
  };
  donation?: {
    current: number;
    interval?: DonationInterval;
    subscriberSince: Date;
  };
  relatedIds: string[];
}

export interface ScholarshipInitiative extends CommunityInitiative {
  noOfApplicants: number;
  noOfApprovedBeneficiary: number;
  paidOut: number;
}

export interface BugBountyInitiative extends CommunityInitiative {
  policyUrl: string;
  startDate: Date;
  endDate: Date;
  hackers: Hackers;
  bugReport: number;
  hackerPaid: number;
  bugVerified: number;
  bugsGoal: FundingGoalSummary[];
  bugBountyLedger: BugBountyLedger;
  bugsDetails: BugsDetails[];
}

export interface FundingInitiative extends CommunityInitiative {
  repoUrl?: string;
}

export interface MeetupInitiative extends CommunityInitiative {
  registeredPeople: Person[];
  eventStartDate: Date;
  eventEndDate: Date;
  city: string;
  country: string;
}

export interface MentorshipInitiative extends CommunityInitiative {
  stipendPaid: number;
  jobOpportunities: number;
  menteesPaid: Person[];
  terms: MentorshipTerm[];
  skills: string[];
  mentors: Person[];
  opportunities: Company[];
}

export type Initiative =
  | ScholarshipInitiative
  | BugBountyInitiative
  | FundingInitiative
  | MeetupInitiative
  | MentorshipInitiative;

export enum DonationInterval {
  Monthly = 'monthly',
  Yearly = 'yearly',
  Weekly = 'weekly',
}

export enum InitiativeStatus {
  Pending = 'pending',
  Active = 'active',
  Closed = 'closed',
}

export enum BugType {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface BugBountyLedger {
  date: string;
  bugName: string;
  hackerName: string;
  type: string;
  amount: number;
  fee: number;
  credit: number;
  creditBy: string;
  platformAmount: number;
  processingAmount: number;
  transactions: Transaction[];
}

export interface Ledger {
  name: string;
  logoUrl: string;
  companyPhrase: string;
  amount: number;
  fees: number;
  credit: number;
  platformAmount: number;
  processingAmount: number;
  transactions: Transaction[];
}

export interface BugsDetails {
  date: string;
  reported: string;
  validated: boolean;
  resolved: boolean;
}

export enum MentorshipTerm {
  Winter = 'winter',
  Fall = 'fall',
  Summer = 'summer',
  Spring = 'spring',
}

export enum InitiativeType {
  Funding = 'Funding',
  Mentorship = 'Mentorship',
  Bug = 'Bug Bounty',
  Travel = 'Travel Scholarship',
  Meetup = 'Meetup',
}

export enum InitiativeCategoryEnum {
  Development = 'Development',
  HostingOrInfrastructure = 'Hosting/Infrastructure',
  Documentation = 'Documentation',
  Marketing = 'Marketing',
  MenteeStipend = 'Mentee Stipend',
  MenteeTravel = 'Mentee Travel',
  Venue = 'Venue',
  FoodAndBeverage = 'Food & Beverage',
  Travel = 'Travel',
  Equipment = 'Equipment',
  Diversity = 'Diversity',
  High = 'High Priority Bugs',
  Medium = 'Medium Priority Bugs',
  Low = 'Low Priority Bugs',
}

export interface InitiativeCategory {
  initiativeType: InitiativeType;
  categories: string[];
}

export const communityInitiativeCategories: InitiativeCategory[] = [
  {
    initiativeType: InitiativeType.Funding,
    categories: [
      InitiativeCategoryEnum.Development,
      InitiativeCategoryEnum.HostingOrInfrastructure,
      InitiativeCategoryEnum.Documentation,
      InitiativeCategoryEnum.Marketing,
    ],
  },
  {
    initiativeType: InitiativeType.Mentorship,
    categories: [
      InitiativeCategoryEnum.MenteeStipend,
      InitiativeCategoryEnum.MenteeTravel,
    ],
  },
  {
    initiativeType: InitiativeType.Meetup,
    categories: [
      InitiativeCategoryEnum.Venue,
      InitiativeCategoryEnum.FoodAndBeverage,
      InitiativeCategoryEnum.Travel,
      InitiativeCategoryEnum.Equipment,
      InitiativeCategoryEnum.Marketing,
    ],
  },
  {
    initiativeType: InitiativeType.Travel,
    categories: [
      InitiativeCategoryEnum.Travel,
      InitiativeCategoryEnum.Diversity,
    ],
  },
  {
    initiativeType: InitiativeType.Bug,
    categories: [
      InitiativeCategoryEnum.High,
      InitiativeCategoryEnum.Medium,
      InitiativeCategoryEnum.Low,
    ],
  },
];

export interface Transaction {
  id: string;
  currencyCode: string;
  accountId: string;
  amount: number;
  description: string;
  createdAt: Date | string;
  transactionCategory: string;
  runningBalance: number;
  goal: FundingGoalType;
}

export interface Hackers {
  rewards: HackersRewards;
  paidToHackers: PaidToHackers[];
}

export interface HackersRewards {
  highBugAmount: number;
  mediumBugAmount: number;
  lowBugAmount: number;
}

export interface PaidToHackers {
  person: Person;
  reward: number;
}

export const mockTransactions = (
  count?: number,
  startBalance?: number,
  endBalance?: number
): Transaction[] =>
  mockList(
    () => ({
      id: faker.random.uuid(),
      accountId: faker.random.uuid(),
      amount: faker.random.number(5000),
      description: faker.name.firstName() + ' ' + faker.name.lastName(),
      createdAt: faker.date.future(),
      transactionCategory: faker.random.arrayElement(
        Object.keys(InitiativeType)
      ),
      currencyCode: 'USD',
      runningBalance: startBalance + endBalance,
      goal: randomEnum(FundingGoalType),
    }),
    { min: 1, max: count }
  );

export const mockLedger = (): Ledger => {
  const startBalance = faker.random.number(10000);
  const endBalance = faker.random.number(5000);

  return {
    name: faker.company.companyName(),
    logoUrl: imagePlaceholders.company,
    companyPhrase: faker.company.catchPhrase(),
    amount: faker.random.number(),
    fees: faker.random.number(),
    credit: faker.random.number(),
    platformAmount: faker.random.number(50),
    processingAmount: faker.random.number(25),
    transactions: mockTransactions(5, startBalance, endBalance),
  };
};

export const mockBugBountyLedger = (): BugBountyLedger => {
  const startBalance = faker.random.number(10000);
  const endBalance = faker.random.number(5000);

  return {
    date: faker.date.past(4),
    bugName: faker.company.companyName(),
    hackerName: faker.name.firstName() + ' ' + faker.name.lastName(),
    type: randomEnum(BugType),
    amount: faker.random.number({ min: 5000, max: 7000 }),
    fee: faker.random.number({ min: 3000, max: 5000 }),
    credit: faker.random.number({ min: 5000, max: 7000 }),
    creditBy: faker.company.companyName(),
    platformAmount: faker.random.number(50),
    processingAmount: faker.random.number(25),
    transactions: mockTransactions(5, startBalance, endBalance),
  };
};

export const mockBugDeails = (): BugsDetails => ({
  date: faker.date.past(4),
  reported: faker.company.companyName(),
  validated: faker.random.boolean(2),
  resolved: faker.random.boolean(1),
});

export const mockHackersData = (): Hackers => ({
  rewards: mockHackerRewards(),
  paidToHackers: mockList(mockPaidToHackers),
});

export const mockHackerRewards = (): HackersRewards => ({
  highBugAmount: faker.random.number({ min: 5000, max: 7000 }),
  mediumBugAmount: faker.random.number({ min: 3000, max: 5000 }),
  lowBugAmount: faker.random.number({ min: 3000, max: 1000 }),
});

export const mockCommunityInitiative = (): CommunityInitiative => {
  const product: string = faker.commerce.product();
  const adjective: string = faker.commerce.productAdjective();
  const material: string = faker.commerce.productMaterial();
  const name = `${adjective} ${material} ${product}`;
  const fundingTarget = faker.random.number();
  const mockGoals = mockList(mockFundingGoalSummary).filter(
    (value, index, goals) =>
      goals.findIndex(goal => goal.type === value.type) === index
  );
  const categories = communityInitiativeCategories.map(
    initiative => initiative.categories
  );

  return {
    id: faker.random.uuid(),
    name,
    description: faker.lorem.paragraph(),
    logoUrl: imagePlaceholders.logo,
    colorBand: faker.internet.color(),
    tag: product,
    type: randomEnum(InitiativeType),
    category: faker.random.arrayElement([].concat(...categories)),
    badgeUrl: `https://bestpractices.coreinfrastructure.org/projects/${randomBadgeId()}`,
    siteUrl: faker.internet.url(),
    cocUrl: faker.internet.url(),
    status: InitiativeStatus.Active,
    funding: {
      current: faker.random.number(fundingTarget),
      target: fundingTarget,
      currencyCode: 'USD',
      sponsors: mockList(randomCompany),
      backers: mockList(mockPerson),
      ledger: mockLedger(),
      goals: mockGoals,
    },
    relatedIds: [],
  };
};
export const mockScholarshipInitiative = (): ScholarshipInitiative => ({
  ...mockCommunityInitiative(),
  noOfApplicants: faker.random.number({ min: 10, max: 100 }),
  noOfApprovedBeneficiary: faker.random.number({ min: 10, max: 100 }),
  paidOut: faker.random.number({ min: 400, max: 2000 }),
});

export const mockPaidToHackers = (): PaidToHackers => ({
  person: mockPerson(),
  reward: faker.random.number({ min: 500, max: 5000 }),
});
export const mockMentorshipInitiative = (): MentorshipInitiative => ({
  ...mockCommunityInitiative(),
  stipendPaid: faker.random.number({ min: 400, max: 2000 }),
  menteesPaid: mockList(mockPerson),
  jobOpportunities: faker.random.number({ min: 10, max: 100 }),
  mentors: mockList(mockPerson),
  opportunities: mockList(mockCompany),
  terms: mockList(() => randomEnum(MentorshipTerm), {
    min: 1,
    max: 4,
  }) as MentorshipTerm[],
  skills: ['C', 'GNU Make', 'Shell', 'Kernel'],
});

export const mockMeetupInitiative = (): MeetupInitiative => {
  const date = faker.date.future();

  return {
    ...mockCommunityInitiative(),
    registeredPeople: mockList(mockPerson),
    eventStartDate: new Date(date),
    eventEndDate: new Date(
      date.setDate(date.getDate() + faker.random.number({ min: 0, max: 5 }))
    ),
    city: faker.address.city(),
    country: faker.address.country(),
  };
};

export const mockBugBountyInitiative = (): BugBountyInitiative => {
  const date = faker.date.future();
  const mockBugTypes = mockList(mockBugsSummary).filter(
    (value, index, goals) =>
      goals.findIndex(goal => goal.type === value.type) === index
  );

  return {
    ...mockCommunityInitiative(),
    policyUrl: faker.internet.url(),
    startDate: new Date(date),
    endDate: faker.date.future(),
    hackers: mockHackersData(),
    bugsDetails: mockList(mockBugDeails),
    bugBountyLedger: mockBugBountyLedger(),
    bugsGoal: mockBugTypes,
    bugReport: faker.random.number({ min: 10, max: 999 }),
    hackerPaid: faker.random.number({ min: 10, max: 999 }),
    bugVerified: faker.random.number({ min: 10, max: 999 }),
  };
};
export const demoCommunityInitiatives: Record<string, Initiative> = {
  'k8sday-india': {
    ...mockMeetupInitiative(),
    id: 'k8sday-india',
    name: 'Kubernetes Day India',
    status: InitiativeStatus.Closed,
    relatedIds: ['kubernetes', 'cncf'],
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/kubernetes.svg',
    colorBand: '#3371e3',
    type: InitiativeType.Meetup,
    category: InitiativeCategoryEnum.Travel,
  },
  'k8s-mentorship': {
    ...mockMentorshipInitiative(),
    ...demoFoundationProjects,
    id: 'k8s-mentorship',
    name: 'Kubernetes Mentorship',
    status: InitiativeStatus.Active,
    relatedIds: ['kubernetes', 'cncf'],
    type: InitiativeType.Mentorship,
    category: InitiativeCategoryEnum.MenteeStipend,
  },
  'kernel-funding': {
    ...mockMentorshipInitiative(),
    id: 'kernel-funding',
    name: 'Linux Kernel Mentorship',
    description: `Linux is the world's largest and most pervasive open source software project in the history of
     computing. The Linux kernel is the largest component of the Linux operating system.`,
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/linuxkernelmentorship.svg',
    colorBand: 'rgb(230, 202, 43)',
    tag: 'Kernel',
    status: InitiativeStatus.Active,
    relatedIds: ['linuxkernel'],
    type: InitiativeType.Funding,
    category: InitiativeCategoryEnum.Development,
  },
  'K7-Bug Bounty': {
    ...mockBugBountyInitiative(),
    id: 'K7-Bug Bounty',
    name: 'Linux Kernel Bug Bounty',
    description: `Linux is the world's largest and most pervasive open source software project in the history of
     computing. The Linux kernel is the largest component of the Linux operating system.`,
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/linuxkernelmentorship.svg',
    colorBand: 'rgb(230, 202, 43)',
    tag: 'Kernel',
    status: InitiativeStatus.Active,
    relatedIds: ['linuxkernel'],
    type: InitiativeType.Bug,
    category: InitiativeCategoryEnum.High,
  },
  'prometheus-scholarship': {
    ...mockScholarshipInitiative(),
    ...demoFoundationProjects,
    id: 'prometheus-scholarship',
    status: InitiativeStatus.Active,
    type: InitiativeType.Travel,
    category: InitiativeCategoryEnum.Travel,
    relatedIds: ['prometheus', 'cncf'],
  },
};

export const myCommunityInitiatives: Initiative[] = [
  demoCommunityInitiatives['k8sday-india'],
  demoCommunityInitiatives['k8s-mentorship'],
  demoCommunityInitiatives['K7-Bug Bounty'],
];

export const suggestedCommunityInitiatives: Initiative[] = [
  demoCommunityInitiatives['kernel-funding'],
  demoCommunityInitiatives['prometheus-scholarship'],
];
