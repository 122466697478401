// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectSubscribersQuery = gql`
  query subscribers(
    $projectSlugs: [String]
    $categories: [String]
    $pageSize: Int
    $offset: Int
  ) {
    subscribers(
      projectSlugs: $projectSlugs
      categories: $categories
      pageSize: $pageSize
      offset: $offset
    ) {
      data {
        subscriberLogo
        name
        email
        id
        mailingList {
          id
          name
          groupsTitle
          category
          collaborationName
        }
        organization {
          name
          id
          logo
          website
        }
        role
        status
        type
        project {
          name
          logoUrl
          id
          slug
        }
      }
      metadata {
        pageSize
        totalSize
        offset
      }
    }
  }
`;
