// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const checkDomainAvailabiltyQuery = gql`
  query domainAvailability($domainName: String!) {
    domainAvailability(domainName: $domainName) {
      action
      available
      domain
      premium
      price
    }
  }
`;
