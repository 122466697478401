// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const engagementSummaryQuery = gql`
  query EngagementSummary(
    $organizationId: ID!
    $projectId: ID
    $foundationId: ID
  ) {
    engagementSummary(
      organizationId: $organizationId
      projectId: $projectId
      foundationId: $foundationId
    ) {
      code
      message
      engagementSummary {
        trainingAndCertifications {
          courseName
          enrollmentCount
        }
        engagementAndTeam {
          accountId
          projectId
          year
          month
          quarter
          eventRegistrationCount
          commitCount
          contributorsCount
          maintainersCount
          enrollmentsCount
          certificationsCount
          committeeMembersCount
          keyContactsCount
          sponsorshipEventReachedCount
        }
        upcomingEvents {
          eventId
          eventName
          eventStartDate
          eventEndDate
          eventIsPast
          eventUrl
          eventCity
          eventCountry
          countRegistrationsAllTime
          acceptedSpeakerProposalsAllTime
        }
        myOrgVsTopContributingOrgs {
          accountName
          commitCountAllTime
        }
      }
    }
  }
`;
