// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const orgProjectBenefitsQuery = gql`
  query orgProjectBenefits(
    $organizationId: String
    $slug: String
    $category: String
    $salesforceMembershipId: String
  ) {
    orgProjectBenefits(
      organizationId: $organizationId
      slug: $slug
      category: $category
      salesforceMembershipId: $salesforceMembershipId
    ) {
      data {
        memberShipId
        benefits {
          actionEmail
          actionLabel
          canClaim
          claimed
          limit
          category
          type
          description
          comment
          projectBenefitTierId
        }
      }
    }
  }
`;
