// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectTransitiveDependencyQuery = gql`
  query projectTransitiveDependencies($input: ProjectsDependencyInput) {
    projectTransitiveDependencies(input: $input) {
      dependencyCount
      sfdcProjectId
    }
  }
`;
