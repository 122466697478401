// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectRolesStatsQuery = gql`
  query projectRolesStats($accountId: String!, $projectId: String) {
    projectRolesStats(accountId: $accountId, projectId: $projectId) {
      data {
        projectId
        projectLogoUrl
        projectName
        membershipDetailCount {
          authorizedSignatoryCount
          billingCount
          eventSponsorshipCount
          legalCount
          marketingCount
          poCount
          prCount
          primaryVotingCount
          technicalCount
        }
      }
      metadata {
        offset
        pageSize
        totalSize
      }
    }
  }
`;
