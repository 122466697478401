// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listSbomInventoryStatsQuery = gql`
  query listSbomInventoryStats($orgId: String!) {
    listSbomInventoryStats(orgId: $orgId) {
      dependencyCount
      packageCount
    }
  }
`;
