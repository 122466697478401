// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '@lfx/core/services';

@Component({
  selector: 'lfx-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  // eslint-disable-next-line @typescript-eslint/tslint/config
  host: {
    class: 'toast-container position-fixed bottom-0',
    style: 'z-index: 1200',
  },
})
export class ToastComponent implements OnInit {
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
