// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationActivityLogsQuery = gql`
  query claActivityLogs($input: FoundationActivityLogsInput) {
    claActivityLogs(input: $input) {
      message
      tip
      name
      logoUrl
      createdAt
    }
  }
`;
