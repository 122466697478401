// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const topEventsBySpeakersFromMyOrgQuery = gql`
  query topEventsBySpeakersFromMyOrg(
    $organizationId: String
    $dateRange: String
  ) {
    topEventsBySpeakersFromMyOrg(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      pastEventsDataSet {
        topSpeakersFromMyOrg
        averageSpeakersFromOtherOrgs {
          similarSize
          sponsoredSameTier
          similarIndustry
        }
        chartLabels
      }
      upcomingEventsDataSet {
        topSpeakersFromMyOrg
        averageSpeakersFromOtherOrgs {
          similarSize
          sponsoredSameTier
          similarIndustry
        }
        chartLabels
      }
      chartLabels
    }
  }
`;
