// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationContributionsNonMembershipProjectsQuery = gql`
  query ($organizationId: ID = "", $projectId: ID = "") {
    organizationContributionsNonMembershipProjects(
      organizationId: $organizationId
      projectId: $projectId
    ) {
      id
      slug
      name
      logo
      contributions
      contributors
    }
  }
`;

export interface organizationContributionsNonMembershipProjectsVariables {
  organizationId: string;
  projectId?: string;
}

export interface organizationContributionsNonMembershipProjectsResponse {
  organizationContributionsNonMembershipProjects: organizationContributionsNonMembershipProjects[];
}

export interface organizationContributionsNonMembershipProjects {
  id: string;
  slug: string;
  name: string;
  logo: string;
  contributions: number;
  contributors: number;
}
