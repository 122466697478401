// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectOrganizationContributionsStatQuery = gql`
  query (
    $organizationId: ID = ""
    $dateRange: String = ""
    $projectId: ID = ""
  ) {
    projectOrganizationContributionsStat(
      projectId: $projectId
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      employees
      projects
      contributions
      commits
      prs
      issues
      changed
    }
  }
`;

export interface projectOrganizationContributionsStatGraphQueryVariables {
  projectId: string;
  organizationId?: string;
  dateRange?: string;
}

export interface projectOrganizationContributionsStatGraphQueryResponse {
  projectOrganizationContributionsStat: ProjectOrganizationContributionsStat;
}

export interface ProjectOrganizationContributionsStat {
  employees: number;
  projects: number;
  contributions: number;
  commits: number;
  prs: number;
  issues: number;
  changed: number;
}
