// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectInfrastructureSummaryQuery = gql`
  query projectInfrastructureSummary($projectName: String!) {
    projectInfrastructureSummary(projectName: $projectName) {
      domains {
        name
        state
        primary
      }
      aliases {
        alias
        recipients
        status
      }
      mailingLists {
        title
        description
        tag
        url
        alias
      }
    }
  }
`;
