// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tncSeriesQuery = gql`
  query tncSeries(
    $organizationId: String!
    $dateRange: String!
    $companySize: String!
    $industry: String!
    $type: String!
  ) {
    tncSeries(
      organizationId: $organizationId
      dateRange: $dateRange
      companySize: $companySize
      industry: $industry
      type: $type
    ) {
      trainingsSimilarEmployeeSize {
        month
        year
        myOrgCount
        otherOrgCount
        avgOtherOrgCount
      }
      trainingsSimilarIndustry {
        month
        year
        myOrgCount
        otherOrgCount
        avgOtherOrgCount
      }
      certificationsSimilarEmployeeSize {
        month
        year
        myOrgCount
        otherOrgCount
        avgOtherOrgCount
      }
      certificationsSimilarIndustry {
        month
        year
        myOrgCount
        otherOrgCount
        avgOtherOrgCount
      }
    }
  }
`;
