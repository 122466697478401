// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import * as faker from 'faker';
import { generalConstants } from '@config';
export interface Email {
  emailAddress: string;
  emailType: string;
  active: boolean;
  isDeleted: boolean;
  isPrimary: boolean;
  isVerified?: boolean;
}

export const mockEmail = (): Email => {
  const primary = faker.random.boolean();

  return {
    emailAddress: faker.internet.email(),
    emailType: generalConstants.other,
    active: primary || faker.random.boolean(),
    isDeleted: !primary && faker.random.boolean(),
    isPrimary: primary,
    isVerified: primary || faker.random.boolean(),
  };
};
