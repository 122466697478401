// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributorTeamQuery = gql`
  query ContributorTeam(
    $organizationId: String!
    $projectId: String
    $dateRange: String
    $isMaintainer: Boolean
    $isCommitter: Boolean
    $isReviewer: Boolean
    $currentEmployeesOnly: Boolean
  ) {
    contributorTeam(
      organizationId: $organizationId
      projectId: $projectId
      dateRange: $dateRange
      isMaintainer: $isMaintainer
      isCommitter: $isCommitter
      isReviewer: $isReviewer
      currentEmployeesOnly: $currentEmployeesOnly
    ) {
      contributorId
      userId
      userDisplayName
      userName
      userPhotoUrl
      jobTitle
      country
      github
      memberEmail
      accountName
      orgAffiliation {
        endDate
        pkid
        startDate
      }
      commitCount
      lastCommitTs
      isCommitter
      isMaintainer
      isReviewer
    }
  }
`;
