// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const certificationsForOrgQuery = gql`
  query tncOrgCertifications($organizationId: String, $dateRange: String) {
    tncOrgCertifications(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      certifications {
        product {
          name
        }
        thisOrg {
          learners
          certifiedLearners
          certificatesAchieved
          enrollments
        }
      }
    }
  }
`;
