// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const addEmailAliasMutation = gql`
  mutation addEmailAliasMutation($input: AddAliasInput!) {
    addEmailAlias(input: $input) {
      code
      message
      success
    }
  }
`;
