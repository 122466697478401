// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsAttendeesForOrgQuery = gql`
  query eventsAttendeesForOrg($salesforceId: ID!, $dateRange: String) {
    organization(salesforceId: $salesforceId) {
      id
      events(dateRange: $dateRange) {
        year
        months {
          label
          speakers {
            speakers
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          attendees {
            attendees
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalAttendees
            totalSpeakers
            location
            startDate
            name
            attendees
            similarindustry {
              avgTotalAttendance
            }
            similarsize {
              avgTotalAttendance
            }
            sponsoredsametier {
              avgTotalAttendance
            }
            speakers
            similarindustry {
              avgTotalSpeakers
            }
            similarsize {
              avgTotalSpeakers
            }
            sponsoredsametier {
              avgTotalSpeakers
            }
          }
        }
        quarters {
          label
          attendees {
            attendees
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          speakers {
            speakers
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalAttendees
            totalSpeakers
            location
            startDate
            name
            attendees
            similarindustry {
              avgTotalAttendance
            }
            similarsize {
              avgTotalAttendance
            }
            sponsoredsametier {
              avgTotalAttendance
            }
            speakers
            similarindustry {
              avgTotalSpeakers
            }
            similarsize {
              avgTotalSpeakers
            }
            sponsoredsametier {
              avgTotalSpeakers
            }
          }
        }
      }
    }
  }
`;
