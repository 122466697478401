// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationCommitteeContactsQuery = gql`
  query organizationCommitteeContacts(
    $organizationId: ID!
    $queryParams: OrganizationCommitteeContactsQueryInput
  ) {
    organizationCommitteeContacts(
      organizationId: $organizationId
      queryParams: $queryParams
    ) {
      data {
        requestStatus
        requestedChange
        committeeId
        committeeMemberId
        name
        status
        committeeTitle
        category
        contact {
          email
          firstname
          id
          lastname
          photoUrl
        }
        project {
          id
          logoUrl
          name
        }
      }
    }
  }
`;
