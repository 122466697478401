// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateFoundationLegalMutation = gql`
  mutation updateFoundationLegal($legalInfo: UpdateFoundationLegalInput!) {
    updateFoundationLegal(input: $legalInfo) {
      code
      message
      success
      foundationLegal {
        legalEntity
        formationDate
        dissolutionDate
        entityType
        msaUrl
        charterUrl
        cocUrl
      }
    }
  }
`;
