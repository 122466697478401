// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationEmployeesQuery = gql`
  query organizationEmployees(
    $organizationId: String!
    $engagement: String
    $pageSize: Int
    $offset: Int
    $sortDir: String
    $orderBy: String
    $search: String
    $status: String
    $filter: String
    $morefilter: String
    $committeefilter: String
    $lfidfilter: String
    $projectId: String
    $membershipfilter: String
    $contributorfilter: String
    $ospoLeader: Boolean
    $activityfilter: String
    $exportToCSV: Boolean
    $typeview: String
    $hasmembershipentitlement: Boolean
  ) {
    organizationEmployees(
      organizationId: $organizationId
      engagement: $engagement
      pageSize: $pageSize
      offset: $offset
      sortDir: $sortDir
      orderBy: $orderBy
      search: $search
      status: $status
      filter: $filter
      morefilter: $morefilter
      committeefilter: $committeefilter
      lfidfilter: $lfidfilter
      projectId: $projectId
      membershipfilter: $membershipfilter
      ospoLeader: $ospoLeader
      contributorfilter: $contributorfilter
      exportToCSV: $exportToCSV
      activityfilter: $activityfilter
      typeview: $typeview
      hasmembershipentitlement: $hasmembershipentitlement
    ) {
      data {
        emailAddress
        administrator
        createdAt
        lastLoginDate
        lastModifiedAt
        logoUrl
        name
        userId
        username
        orgUserId
        reason
        status
        title
        ospoLeader
        maintainer
        engagementType {
          description
          engagementId
          engagementType
          engagementTypeId
          groupProjectId
          projectId
          projectName
          userId
          appointedBy
          category
          role
          committeeName
          projectLogoUrl
          primaryContact
          votingStatus
        }
      }
      url
      metadata {
        totalSize
        pageSize
        offset
      }
    }
  }
`;
