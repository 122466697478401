// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteFoundationCommitteeMutation = gql`
  mutation deleteFoundationCommittee($committeeInfo: DeleteCommitteeInput!) {
    deleteCommittee(input: $committeeInfo) {
      code
      message
      success
    }
  }
`;
