// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  projectGroupQuery,
  projectInfrastructureSummaryQuery,
  recurringZoomMeetingsQuery,
  zoomMeetingsRegistrantsQuery,
  pastZoomMeetingsQuery,
  nonrecurringZoomMeetingsQuery,
  totalZoomMeetingsQuery,
  recurringZoomMeetingsOccurrenceQuery,
} from '../queries';
import { addEmailAliasMutation } from '../mutations';

import { AddAliasInput } from './inputs';
import { cloneDeep } from 'lodash';
import {
  ProjectInfrastructureGroup,
  ZoomMeetingResponse,
  ZoomMeetingResponseMetadata,
  ZoomMeetingsFilter,
  ZoomMeetingsRegistrants,
} from '@lfx/core/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfrastructureServiceGql {
  constructor(private apollo: Apollo) {}

  getProjectInfrastructureSummary(projectName: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: projectInfrastructureSummaryQuery,
        variables: {
          projectName,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectInfrastructureSummary),
        map(cloneDeep)
      );
  }

  addEmailAlias(input: AddAliasInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: addEmailAliasMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(res => res.data.addEmailAlias),
        map(cloneDeep)
      );
  }

  getRecurringZoomMeetings(
    projectSlug: string,
    filter: Omit<ZoomMeetingsFilter, 'projectSlug'> = {},
    noCache = false
  ): Observable<ZoomMeetingResponse> {
    return this.apollo
      .watchQuery<any>({
        query: recurringZoomMeetingsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            projectSlug,
            visibility: filter.visibility || 'public',
            limit: filter.limit || 5,
            offset: filter.offset || 0,
            startDate: filter.startDate,
            endDate: filter.endDate,
            searchTerm: filter.searchTerm,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.recurringZoomMeetings));
  }

  getRecurringZoomMeetingsOccurrence(
    projectSlug: string,
    filter: Omit<ZoomMeetingsFilter, 'projectSlug'> = {},
    noCache = false
  ): Observable<ZoomMeetingResponse> {
    return this.apollo
      .watchQuery<any>({
        query: recurringZoomMeetingsOccurrenceQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            projectSlug,
            visibility: filter.visibility || 'public',
            limit: filter.limit || 5,
            offset: filter.offset || 0,
            startDate: filter.startDate,
            endDate: filter.endDate,
            searchTerm: filter.searchTerm,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.recurringZoomMeetingsOccurrence));
  }

  getNonrecurringZoomMeetings(
    projectSlug: string,
    filter: Omit<ZoomMeetingsFilter, 'projectSlug'> = {},
    noCache = false
  ): Observable<ZoomMeetingResponse> {
    return this.apollo
      .watchQuery<any>({
        query: nonrecurringZoomMeetingsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            projectSlug,
            visibility: filter.visibility || 'public',
            limit: filter.limit || 5,
            offset: filter.offset || 0,
            startDate: filter.startDate,
            endDate: filter.endDate,
            searchTerm: filter.searchTerm,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.nonrecurringZoomMeetings));
  }

  getPastZoomMeetings(
    projectSlug: string,
    filter: Omit<ZoomMeetingsFilter, 'projectSlug'> = {},
    noCache = false
  ): Observable<ZoomMeetingResponse> {
    return this.apollo
      .watchQuery<any>({
        query: pastZoomMeetingsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            projectSlug,
            visibility: filter.visibility || 'public',
            limit: filter.limit || 5,
            offset: filter.offset || 0,
            startDate: filter.startDate,
            endDate: filter.endDate,
            searchTerm: filter.searchTerm,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.pastZoomMeetings));
  }

  getZoomMeetingsTotal(
    projectSlug: string,
    visibility?: string,
    noCache = false
  ): Observable<ZoomMeetingResponseMetadata> {
    return this.apollo
      .watchQuery<any>({
        query: totalZoomMeetingsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            projectSlug,
            visibility: visibility || 'public',
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.totalZoomMeetings));
  }

  getZoomMeetingsRegistrants(
    zoomMeetingId: string,
    noCache = false
  ): Observable<ZoomMeetingsRegistrants> {
    return this.apollo
      .watchQuery<any>({
        query: zoomMeetingsRegistrantsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            zoomMeetingId,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.zoomMeetingsRegistrants));
  }

  getProjectGroup(projectSlug: string): Observable<ProjectInfrastructureGroup> {
    return this.apollo
      .query<any>({
        query: projectGroupQuery,
        fetchPolicy: 'cache-first',
        variables: {
          projectSlug,
        },
      })
      .pipe(map(res => res.data.projectGroup));
  }
}
