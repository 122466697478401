// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { generalConstants } from '@lfx/config';
import { CompanyService, ToastService } from '@lfx/core/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'lfx-upload-logo-dialog',
  templateUrl: './upload-logo-dialog.component.html',
  styleUrls: ['./upload-logo-dialog.component.scss'],
})
export class UploadLogoDialogComponent implements OnInit {
  @ViewChild('upload', { static: true })
  uploadInput: ElementRef<HTMLInputElement>;

  loading = false;
  maxSvgSize = 1000000; // 1mb
  errorMessage = '';
  svgImage: SafeResourceUrl;
  file: File;

  @Input() companyId: string;

  constructor(
    private sanitizer: DomSanitizer,
    private ngbActiveModal: NgbActiveModal,
    private companyService: CompanyService,
    private cookieService: CookieService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.openFile();
  }

  openFile() {
    const fileInput = this.uploadInput.nativeElement;

    fileInput.click();
  }

  async onImageUpload(target: HTMLInputElement) {
    const files = target.files;
    const file = files && files.item(0);

    if (file.type !== 'image/svg+xml') {
      this.errorMessage = 'Only SVG allowed';

      return;
    } else {
      this.errorMessage = '';
    }

    if (file.size > this.maxSvgSize) {
      this.errorMessage = 'Maximum file size of 1mb exceeded';

      return;
    } else {
      this.errorMessage = '';
    }

    const text = await file.text();

    if (
      text.search('<script') !== -1 ||
      text.search('<!ENTITY') !== -1 ||
      text.search('<!entity') !== -1 ||
      text.search('xlink:href') !== -1 ||
      text.search('XLINK:HREF') !== -1
    ) {
      this.errorMessage = `SVG content with 'script' tag, 'entity' tag and external links are not allowed`;

      return;
    } else {
      this.errorMessage = '';
    }

    const fileReader = new FileReader();

    fileReader.onload = () => {
      this.svgImage = this.sanitizer.bypassSecurityTrustUrl(
        fileReader.result as string
      );
    };

    fileReader.readAsDataURL(file);
    const customFile = new File([file], this.customFileName(), {
      type: file.type,
    });

    this.file = customFile;
  }

  saveChanges() {
    const data = {
      file: this.file,
      formData: { logoUrl: null },
    };

    this.loading = true;

    return this.companyService
      .uploadLogo(data.file, this.companyId)
      .pipe(
        switchMap(response => {
          if (response.success) {
            return this.companyService.updateOrganizationProfile({
              id: this.companyId,
              logoUrl: response.fileUrl,
            });
          } else {
            this.loading = false;

            // eslint-disable-next-line no-console
            console.error(response.message);
            this.errorMessage = 'Unable to upload logo. Please try again later';

            return of({
              message: this.errorMessage,
              organization: null,
            });
          }
        })
      )
      .subscribe(
        result => {
          this.loading = false;

          if (result.message && !result.organization) {
            this.toastService.show(
              'Unable to upload logo. Please try again later',
              {
                classname: 'new-employee-toast text-light',
                delay: 15000,
              }
            );

            this.closeModal({
              canceled: false,
              success: false,
              networkErrorMessage: result.message,
            });
          } else {
            this.closeModal({
              canceled: false,
              success: true,
            });

            this.cookieService.set(
              generalConstants.uploadYourLogoCookieName + '-' + this.companyId,
              'true',
              { path: '/', expires: 30 }
            );

            this.toastService.show('Success! Your logo was uploaded.', {
              classname: 'new-employee-toast text-light',
              delay: 15000,
            });
          }
        },
        () => {
          this.loading = false;
          this.closeModal({
            canceled: false,
            success: false,
            networkErrorMessage: this.errorMessage,
          });
        }
      );
  }

  closeModal(
    result: UploadLogoDialogResult = {
      success: false,
      canceled: true,
    }
  ) {
    this.ngbActiveModal.close(result);
  }

  customFileName() {
    const pattern = /[^a-zA-Z\d\.]+/g;
    const name = `${this.companyService.getCurrentCompanySlug()}_${Date.now()}_${
      this.companyId
    }.svg`;

    return name.replace(pattern, '_');
  }
}

export interface UploadLogoDialogResult {
  success: boolean;
  canceled: boolean;
  svgErrorMessage?: string;
  networkErrorMessage?: string;
}
