// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const openSourceInvolvementQuery = gql`
  query OpenSourceInvolvement($organizationId: ID!, $isAllOrgs: Boolean) {
    openSourceInvolvement(
      organizationId: $organizationId
      isAllOrgs: $isAllOrgs
    ) {
      code
      message
      involvement {
        accountId
        accountName
        activeMembershipCount
        foundationCount
        committeeMemberCount
        contributorsCount
        maintainersCount
        technicalProjectCount
        lfMembershipTier
      }
    }
  }
`;
