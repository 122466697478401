// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Directive, HostListener, HostBinding } from '@angular/core';
import { DraggableDirective } from './draggable.directive';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Position } from '@models';

@Directive({
  selector: '[lfxMovable]',
})
export class MovableDirective extends DraggableDirective {
  private position: Position = { x: 0, y: 0 };
  private initialPosition: Position;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  @HostBinding('style.transform')
  get transform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `translateX(${this.position.x}px) translateY(${this.position.y}px)`
    );
  }

  @HostListener('dragStart', ['$event'])
  onDragStart(event): void {
    this.initialPosition = {
      x: event.clientX - this.position.x,
      y: event.clientY - this.position.y,
    };
  }

  @HostListener('dragMove', ['$event'])
  onDragMove(event): void {
    this.position = {
      x: event.clientX - this.initialPosition.x,
      y: event.clientY - this.initialPosition.y,
    };
  }
}
