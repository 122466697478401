// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const certificationsGroupedQuery = gql`
  query certificationsGrouped($dateRange: String, $type: String) {
    myOrganization {
      id
      certificationsGrouped(dateRange: $dateRange, type: $type) {
        year
        months {
          label
          certifications {
            thisOrg {
              individualEnrollments {
                enrollments
                certifiedLearners
                learners
              }
              corporateEnrollments {
                enrollments
                certifiedLearners
                learners
              }
            }
          }
        }
        quarters {
          label
          certifications {
            thisOrg {
              individualEnrollments {
                enrollments
                certifiedLearners
                learners
              }
              corporateEnrollments {
                enrollments
                certifiedLearners
                learners
              }
            }
          }
        }
      }
    }
  }
`;
