<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="search-container">
  <span class="fa fa-search cursor-pointer"></span>
  <input
    [(ngModel)]="modal"
    (selectItem)="onSelectItem($event, input)"
    (focus)="focus$.next($any($event).target.value);"
    (blur)="onInputBlur()"
    [ngbTypeahead]="searchClient"
    [resultTemplate]="rt"
    #instance="ngbTypeahead"
    type="text"
    class="form-control search-text"
    aria-describedby="basic-addon1"
    #input
    [placeholder]="placeholderText"
    [style.font-style]="italicText ? 'italic' : 'normal'">
  <span *ngIf="loadingClients"><i class="fa fa-spinner fa-spin"></i></span>
  <span id="fa_times" class="fas fa-times" (click)="remove()" *ngIf="!loadingClients && modal"></span>
</div>

<ng-template #rt let-res="result" let-t="term">
  <div class="row" *ngIf="usedFor === 'search-user'">
    <div class="col-sm-6">
      <span class="mr-03" *ngIf="res.firstName" [innerHTML]="res.firstName | highlight: modal"></span>
      <span class="mr-03" *ngIf="res.lastName" [innerHTML]="res.lastName | highlight: modal"></span>
      <span class="mr-03" *ngIf="res.name && !res.firstName && !res.lastName" [innerHTML]="res.name | highlight: modal"></span>
    </div>
    <div class="col-sm-6 text-md-right" *ngIf="res?.emails && res?.emails.length"
         [innerHTML]="res?.emails[0].emailAddress | highlight: modal"></div>
  </div>
  <div class="row" *ngIf="usedFor === 'search-project'">
    <div class="col-sm-6">
      <span class="mr-03" *ngIf="res.name" [innerHTML]="res.name | highlight: modal"></span>
    </div>
  </div>
</ng-template>
<div class="position-relative">
  <div class="not-found project"
       *ngIf="!notFoundWithButton && usedFor === 'search-project' && ((searchResult$ | async) == null || (searchResult$ | async)?.length === 0) && modal && modal.length >= textThreshold && isFocused && isTyping && !loadingClients">
  <span class="text">
    No project found
  </span>
  </div>
  <div class="not-found project-with-button"
       *ngIf="notFoundWithButton && usedFor === 'search-project' && ((searchResult$ | async) == null || (searchResult$ | async)?.length === 0) && modal && modal.length >= textThreshold && isFocused && isTyping && !loadingClients">
       <span class="text">
        No project found
      </span>
      <span class="btn-text" (click)="notFoundAction()">
        {{ notFoundWithButtonText }}
      </span>
  </div>
</div>

