// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { InjectionToken } from '@angular/core';
import { environment } from '@environment';

export const socialAuthClientToken = new InjectionToken('socialAuth');
export const socialAuthClientConfig = {
  domain: environment.auth0Social.domain,
  clientID: environment.auth0Social.client_id,
  audience: environment.auth0Social.audience,
  redirectUri: environment.auth0Social.redirect_uri,
  responseType: environment.auth0Social.responseType,
  scope: environment.auth0Social.scope,
};

export const authClientToken = new InjectionToken('authClientToken');
export const authClientConfig = {
  domain: environment.auth0.domain,
  clientID: environment.auth0.client_id,
  audience: environment.auth0Social.audience,
  redirectUri: environment.auth0.redirect_uri,
  responseType: environment.auth0.responseType,
};

export const localStorageToken = new InjectionToken('localStorage');
