// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateOrganizationUserMutation = gql`
  mutation updateOrganizationUser($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      createdAt
      createdBy
      orgId
      ospoLeader
      orgUserId
    }
  }
`;
