// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationSigningEntityByIdQuery = gql`
  query organizationSigningEntityById($signingEntityId: String!) {
    organizationSigningEntityById(signingEntityId: $signingEntityId) {
      companyAcl
      companyExternalId
      companyId
      companyManagerId
      companyName
      signingEntityName
    }
  }
`;
