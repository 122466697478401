// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteMembershipContactMutation = gql`
  mutation deleteMembershipContact($input: DeleteMembershipContactInput!) {
    deleteMembershipContact(input: $input) {
      message
      code
      success
    }
  }
`;
