// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const repositoriesDependenciesVulnerabilitiesQuery = gql`
  query RepositoriesDependenciesVulnerabilities(
    $input: VulnerabilitiesDependenciesInput!
  ) {
    repositoriesDependenciesVulnerabilities(input: $input) {
      repositoryId
      repositoryName
      dependencyTree {
        childDependencyIds
        dependencyId
        dependencyName
        dependencyVersion
        issueHighCount
        issueHighIds
        issueLowCount
        issueLowIds
        issueMediumCount
        issueMediumIds
        licenses
        nodeId
        packageManager
        rootDependency
        dependencies {
          childDependencyIds
          dependencyId
          dependencyName
          dependencyVersion
          issueHighCount
          issueHighIds
          issueLowCount
          issueLowIds
          issueMediumCount
          issueMediumIds
          licenses
          nodeId
          packageManager
          rootDependency
        }
      }
    }
  }
`;
