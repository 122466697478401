// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const changeUserPasswordMutation = gql`
  mutation changeUserPassword($userInfo: UserPasswordInput!) {
    changeUserPassword(input: $userInfo) {
      code
      message
      currentUser {
        id
        auth0Id
        username
        firstName
        lastName
        fullName
      }
    }
  }
`;
