// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationResourcesQuery = gql`
  query foundationResource($id: ID!) {
    foundationResource(id: $id) {
      id
      name
      description
      resourceUrl
      resourceType
    }
  }
`;
