// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectSecurityIssuesQuery = gql`
  query rootSecurityIssues($id: ID!) {
    rootSecurityIssues(projectId: $id) {
      cveIdentifiers
      cweIdentifiers
      dependencyId
      dependencyName
      overview
      references
      remediation
      severity
      snykId
      status
      title
      repositoryId
      isFixable
    }
  }
`;
