// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationContributorsStatsQuery = gql`
  query organizationContributorsStats(
    $organizationId: String!
    $projectId: String!
    $year: String
  ) {
    organizationContributorsStats(
      organizationId: $organizationId
      projectId: $projectId
      year: $year
    ) {
      chatContributors
      codeContributors
      prContributors
      issuesContributors
      mailingListContributors
      totalContributors
      totalContributions
    }
  }
`;
