// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

export enum SortArrayOfObjectsPipeType {
  Date,
  Number,
  String,
}

/**
 * used to sort array of objects by a given property name, sort direction, type of property
 */
@Pipe({
  name: 'sortArrayOfObjects',
})
export class SortArrayOfObjectsPipe implements PipeTransform {
  /**
   *
   * @param value Pipe input has to be an Array Of Object any[]
   * @param property the name of the property to sort by
   * @param isASC sorting direction true for ASC false For DESC
   * @param type SortArrayOfObjectsPipeType Date, String, Number the type of the property to sort
   */
  transform(
    value: any[],
    property = 'id',
    isASC = true,
    type: SortArrayOfObjectsPipeType = SortArrayOfObjectsPipeType.Number
  ): any {
    return (
      value &&
      value
        .map(x => x)
        .sort((a, b) => {
          switch (type) {
            case SortArrayOfObjectsPipeType.Date:
              return this.sortByDate(a[property], b[property], isASC);
            case SortArrayOfObjectsPipeType.Number:
              return this.sortByNumber(a[property], b[property], isASC);
            case SortArrayOfObjectsPipeType.String:
              return this.sortByString(a[property], b[property], isASC);
          }
        })
    );
  }

  private sortByString(a: string, b: string, isASC: boolean): number {
    return isASC
      ? a.toLowerCase() > b.toLowerCase()
        ? 1
        : -1
      : b.toLowerCase() > a.toLowerCase()
      ? 1
      : -1;
  }

  private sortByNumber(a: any, b: any, isASC: boolean) {
    if (isNaN(a) || isNaN(b)) {
      return 0;
    }

    return isASC ? +a - +b : +b - +a;
  }

  private sortByDate(a: any, b: any, isASC: boolean) {
    let first: Date;
    let second: Date;

    if (typeof a === 'string') {
      first = new Date(a);
      second = new Date(b);

      if (isNaN(first.getTime()) || isNaN(second.getTime())) {
        return 0;
      }
    } else if (a instanceof Date) {
      first = a;
      second = b;
    } else {
      return 0;
    }

    return isASC
      ? first.getTime() - second.getTime()
      : second.getTime() - first.getTime();
  }
}
