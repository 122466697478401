// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const recurringZoomMeetingsOccurrenceQuery = gql`
  query recurringZoomMeetingsOccurrence($input: ZoomMeetingsInput) {
    recurringZoomMeetingsOccurrence(input: $input) {
      data {
        agenda
        duration
        id
        project
        startTime
        topic
        participants
      }
      metadata {
        total
      }
    }
  }
`;
