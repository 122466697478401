// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { SoftwareInventoryServiceGql } from '../gql';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  DeleteSbomFileInput,
  GetResponse,
  ListLicensesAndPackagesInput,
  ListLicensesAndPackagesResponse,
  ListSbomFilesData,
  ListSbomFilesInput,
  SbomSoftwareEvaluationResponse,
  SearchComponentsInput,
} from '@models';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SoftwareInventoryService {
  private sbomFileUploadingSource = new Subject<boolean>();
  sbomFileUploading$ = this.sbomFileUploadingSource.asObservable();

  constructor(
    private softwareInventoryServiceGql: SoftwareInventoryServiceGql,
    private httpClient: HttpClient
  ) {}

  uploadSbomFile(
    file: string | ArrayBuffer,
    filename,
    orgId: string,
    repositoryUrl?: string
  ) {
    return this.softwareInventoryServiceGql
      .uploadSbomFile({
        filename,
        orgId,
        overwrite: true,
        repositoryUrl,
      })
      .pipe(
        switchMap(response =>
          this.presignedUrlFileUpload(
            file,
            response.payload.presignedUrl,
            response.payload.contentType
          )
        )
      );
  }

  presignedUrlFileUpload(
    file: string | ArrayBuffer,
    url: string,
    contentType: string
  ) {
    const headers = new HttpHeaders({
      'Content-Type': contentType || '',
      skipAuthInterceptor: 'true',
    });

    return this.httpClient.put(url, file, {
      headers,
      withCredentials: false,
    });
  }

  listSbomFiles(
    input: ListSbomFilesInput
  ): Observable<GetResponse<ListSbomFilesData>> {
    return this.softwareInventoryServiceGql.listSbomFiles(input);
  }

  listSbomLicenses(orgId) {
    return this.softwareInventoryServiceGql.listSbomLicenses(orgId);
  }

  listSbomInventoryStats(orgId) {
    return this.softwareInventoryServiceGql.listSbomInventoryStats(orgId);
  }

  deleteSbomFile(input: DeleteSbomFileInput) {
    return this.softwareInventoryServiceGql.deleteSbomFile(input);
  }

  searchComponents(input: SearchComponentsInput) {
    return this.softwareInventoryServiceGql.searchComponents(input);
  }

  listLicensesAndPackages(
    input: ListLicensesAndPackagesInput
  ): Observable<ListLicensesAndPackagesResponse> {
    return this.softwareInventoryServiceGql.listLicensesAndPackages(input);
  }

  hasActiveSbomFiles(orgId: string): Observable<boolean> {
    return this.listSbomFiles({
      orgId,
      queryParams: {
        filter: 'isDeleted eq false',
      },
    }).pipe(
      catchError(() => of(null)),
      map(
        res =>
          !!(
            res?.data?.length &&
            res.data.find(file => file.status.toLowerCase() === 'active')
          )
      )
    );
  }

  sbomSoftwareValuation(
    orgId: string
  ): Observable<SbomSoftwareEvaluationResponse> {
    return this.softwareInventoryServiceGql.sbomSoftwareValuation(orgId);
  }

  setSbomFileUploading(uploading: boolean) {
    this.sbomFileUploadingSource.next(uploading);
  }
}
