// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationEmployeeQuery = gql`
  query organizationEmployee(
    $organizationId: String!
    $userId: String
    $basic: Boolean
    $email: String
  ) {
    organizationEmployee(
      organizationId: $organizationId
      userId: $userId
      basic: $basic
      email: $email
    ) {
      createdAt
      emailAddress
      engagementType {
        description
        engagementId
        engagementType
        engagementTypeId
        groupProjectId
        projectId
        userId
        projectName
        appointedBy
        category
        role
        committeeName
        projectLogoUrl
        primaryContact
      }
      githubId
      lastLoginDate
      lastModifiedAt
      logoUrl
      name
      orgUserId
      reason
      status
      userId
      username
      title
      otherEmailAddress
      userRoles {
        acsRoles {
          objectName
          objectTypeName
          roleName
        }
        committeeRoles {
          appointedBy
          category
          committeeDescription
          committeeId
          committeeMemberId
          committeeTitle
          name
          requestStatus
          role
          roleStartDate
          roleEndDate
          status
          votingEndDate
          votingStartDate
          votingStatus
        }
        contributor {
          repositories {
            repositoryName
            repositoryUrl
          }
          totalRepoCount
        }
        membershipRoles {
          boardMember
          membershipId
          primaryContact
          role
          roleId
        }
        project {
          logoUrl
          name
          id
        }
      }
    }
  }
`;
