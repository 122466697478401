// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const trainingAndCertificationSummaryQuery = gql`
  query TrainingAndCertificationSummary(
    $organizationId: String
    $dateRange: String = ""
  ) {
    trainingAndCertificationSummary(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      totalEmployees
      trainingSummary {
        peopleTrained
        topTrainingPrograms {
          name
          id
          enrollments
        }
        chartData
        chartLabels
        chartMonths
      }
      certificationSummary {
        peopleCertified
        topCertifications {
          name
          id
          certificatesAchieved
        }
        chartData
        chartLabels
        chartMonths
      }
    }
  }
`;
