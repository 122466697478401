// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const suggestedFoundationsQuery = gql`
  query suggestedFoundations {
    suggestedFoundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      community {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
    }
  }
`;
