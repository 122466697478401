// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const userTechnicalQuery = gql`
  query (
    $dateRange: String = ""
    $userId: ID = ""
    $email: String = ""
    $organizationId: ID = ""
  ) {
    getUserTechnical(
      dateRange: $dateRange
      userId: $userId
      email: $email
      organizationId: $organizationId
    ) {
      queryname
      userid
      ym
      value
      propened
      prmerged
      issuesopen
      issuesclosed
      added
      deleted
      coauthored
      commits
    }
  }
`;

export interface userTechnicalGraphQueryVariables {
  userId?: string;
  organizationId?: string;
  email?: string;
  dateRange?: string;
}

export interface userTechnicalGraphQueryResponse {
  getUserTechnical: userTechnicalRow[];
}

export interface userTechnicalRow {
  queryname: string;
  userid: string;
  ym: string;
  value: number;
  propened: number;
  prmerged: number;
  issuesopen: number;
  issuesclosed: number;
  added: number;
  deleted: number;
  coauthored: number;
  commits: number;
}
