// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const requestAddCLAManagerDesignee = gql`
  mutation requestAddCLAManagerDesignee(
    $input: RequestAddCLAManagerDesigneeInput!
  ) {
    requestAddCLAManagerDesignee(input: $input) {
      code
      message
      success
      data
    }
  }
`;
