// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { validationLimits } from './validation-limits';

export const validationMessages = {
  profile: {
    firstName: {
      required: 'First name is required.',
      minlength: `First name should be at least ${validationLimits.firstNameMinimumCharacterLength} characters long.`,
      invalidFirstName: 'Enter valid First name',
    },
    lastName: {
      required: 'Last name is required.',
      minlength: `Last name should be at least ${validationLimits.lastNameMinimumCharacterLength} characters long.`,
      invalidLastName: 'Enter valid Last name',
    },
    timezone: {
      required: 'Timezone is required.',
    },
    emailAddress: {
      required: 'Email is a required.',
      email: 'Invalid email format.',
      emailConflict: 'You cannot add the same email twice',
      emailInuse: 'Email associated with another user account.',
    },
  },
  organizationAdministrator: {
    firstName: {
      required: 'First name is required.',
      invalidFirstName: 'Enter valid First name',
    },
    lastName: {
      required: 'Last name is required.',
      invalidLastName: 'Enter valid Last name',
    },
    email: {
      required: 'Email is a required.',
      invalidEmail: 'Invalid email format.',
      invalidEmailDomain: 'Email Domain must match organization domain.',
    },
  },
  organizationTeamMemberRoleService: {
    email: {
      required: 'Email is required',
      invalidEmail: 'Enter a Valid Email',
      invalidEmailDomain: 'Email Domain must match organization domain',
    },
  },
  addAddressForm: {
    street: {
      required: 'Street Address is required.',
    },
    city: {
      required: 'City is required.',
    },
    state: {
      required: 'State/Province is required.',
    },
    postalCode: {
      required: 'Postal Code is required.',
    },
    country: {
      required: 'Country is required.',
    },
  },
};
