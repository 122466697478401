// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createProjectCommitteesMutation = gql`
  mutation createProjectCommittees(
    $committeesInfo: CreateProjectCommitteesInput!
  ) {
    createProjectCommittees(input: $committeesInfo) {
      code
      message
      success
      committees {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
