// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateProjectEssentialsMutation = gql`
  mutation updateProjectEssentials(
    $projectEssential: UpdateProjectEssentialsInput!
  ) {
    updateProjectEssentials(input: $projectEssential) {
      project {
        id
        name
        status
        description
        logoUrl
        colorBand
        slug
        projectSector
      }
    }
  }
`;
