// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const issuesByOrgsCubeQuery = gql`
  query getIssuesByOrgsChart($input: IssuesByOrgsInput) {
    getIssuesByOrgsChart(input: $input) {
      code
      message
      issuesByOrgs {
        countIssues
        countIssuesCreated
        countIssuesCommented
        countIssuesResolved
        timestamp
        timestampMonth
      }
    }
  }
`;
