// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export interface Member {
  id?: any;
  user: MemberUser;
  roles: MemberRole[];
}

export interface MemberUser {
  id?: string;
  name?: string;
  email: string;
}

export interface MemberRole {
  roleType: string;
  role: string;
  startDate: string;
  endDate: string;
  options: RoleOptions[];
}

export interface RoleOptions {
  parent: string;
  child: string;
}

export const MockCommunityPeople = [
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Gb',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'Vice Chair',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Outreach',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Jonathan',
      ID: '0034100000dFrA6AAK',
      Lastname: 'Burns',
      Title: 'Senior Patent Counsel',
    },
    Name: 'Legal',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Legal',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Legal',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Kari',
      ID: '0032M00002YNbtPQAT',
      Lastname: 'Szul',
      Title: 'Global Media and Entertainment Marketing Lead, Google Cloud',
    },
    Name: 'Outreach',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sarah',
      ID: '0032M00002YNbu3QAD',
      Lastname: 'Lum',
      Title: 'Marketing Programs & Events',
    },
    Name: 'Outreach',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dFWFgAAO',
      Lastname: 'Drysdale',
      Title: 'Senior Software Engineer',
    },
    Name: 'Cii-Core',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Konstantin',
      ID: '0034100000dh2V5AAI',
      Lastname: 'Serebryany',
      Title: 'Software Engineer',
    },
    Name: 'Cii-Core',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Meredith',
      ID: '0034100000dFWfqAAG',
      Lastname: 'Whittaker',
      Title: 'Program Manager, Google Research',
    },
    Name: 'Cii-Core',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Opencue-User',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Don',
      ID: '0032M00002cRcBCQA0',
      Lastname: 'Stark',
    },
    Name: 'Allmem',
    Project: {
      ID: 'a094100001FqiLzAAJ',
      Name: 'RISC-V Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Dmitry',
      ID: '0034100000dh2S8AAI',
      Lastname: 'Shmidt',
      Title: 'SWE',
    },
    Name: 'Devel',
    Project: {
      ID: 'a0941000002wBzIAAU',
      Name: 'Zephyr Project',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Don',
      ID: '0032M00002cRcBCQA0',
      Lastname: 'Stark',
    },
    Name: 'Prog',
    Project: {
      ID: 'a094100001FqiLzAAJ',
      Name: 'RISC-V Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'John',
      ID: '0034100000vtH68AAE',
      Lastname: 'Kozura',
      Title: 'Technical contact for EdgeX Project',
    },
    Name: 'EdgeX-Members',
    Project: {
      ID: 'a0941000005osNnAAI',
      Name: 'EdgeX Foundry',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Max',
      ID: '0034100000vtH5HAAU',
      Lastname: 'Senges',
      Title: 'Research Manager',
    },
    Name: 'EdgeX-Members',
    Project: {
      ID: 'a0941000005osNnAAI',
      Name: 'EdgeX Foundry',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Dan',
      ID: '0034100000dh2YLAAY',
      Lastname: 'Kegel',
      Title: 'Staff Software Engineer',
    },
    Name: 'Fossology-Devel',
    Project: {
      ID: 'a0941000002wByXAAU',
      Name: 'Fossology',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Coc-Escalation',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Board',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Report',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Marketing',
    Contact: {
      Firstname: 'Jacinda',
      ID: '00341000025cWmRAAU',
      Lastname: 'Mein',
      Title: 'Comms Manager',
    },
    Name: 'Marketing',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Marketing',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Marketing',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Member-Legal',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Max',
      ID: '0034100000vtH5HAAU',
      Lastname: 'Senges',
      Title: 'Research Manager',
    },
    Name: 'EdgeX-Silver-Reps',
    Project: {
      ID: 'a0941000005osNnAAI',
      Name: 'EdgeX Foundry',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Kurt',
      ID: '0032M00002Xyb8kQAB',
      Lastname: 'Lichtner',
      Title: 'Director Engineering GKE',
    },
    Name: 'OpenSDS-Newsletter',
    Project: {
      ID: 'a0941000017GkisAAC',
      Name: 'OpenSDS Formation Fund',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Antonio',
      ID: '0032M00002XyaPfQAJ',
      Lastname: 'Gulli',
      Title: 'Site Lead and Google Cloud Director in Google Warsaw',
    },
    Name: 'OpenSDS-Newsletter',
    Project: {
      ID: 'a0941000017GkisAAC',
      Name: 'OpenSDS Formation Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Riccardo',
      ID: '0034100000dGCsyAAG',
      Lastname: 'Carlesso',
      Title: 'Technical Solution Engineer',
    },
    Name: 'OpenSDS-Newsletter',
    Project: {
      ID: 'a0941000017GkisAAC',
      Name: 'OpenSDS Formation Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Lakshmi',
      ID: '0034100001syqAwAAI',
      Lastname: 'Sharma',
      Title: 'Director of PM, Google Cloud',
    },
    Name: 'OpenSDS-Newsletter',
    Project: {
      ID: 'a0941000017GkisAAC',
      Name: 'OpenSDS Formation Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Announce',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'New-Projects',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'New-Projects',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Meredith',
      ID: '0034100000dFWfqAAG',
      Lastname: 'Whittaker',
      Title: 'Program Manager, Google Research',
    },
    Name: 'Cii-Strategy',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'John',
      ID: '0034100000vtH68AAE',
      Lastname: 'Kozura',
      Title: 'Technical contact for EdgeX Project',
    },
    Name: 'EdgeX-TSC',
    Project: {
      ID: 'a0941000005osNnAAI',
      Name: 'EdgeX Foundry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Meredith',
      ID: '0034100000dFWfqAAG',
      Lastname: 'Whittaker',
      Title: 'Program Manager, Google Research',
    },
    Name: 'Cii-Census',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Meredith',
      ID: '0034100000dFWfqAAG',
      Lastname: 'Whittaker',
      Title: 'Program Manager, Google Research',
    },
    Name: 'Cii-Discuss',
    Project: {
      ID: 'a0941000002wBzAAAU',
      Name: 'Core Infrastructure Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Ian',
      ID: '0032M00002mGfrWQAS',
      Lastname: 'Rogers',
      Title: 'Software Engineer',
    },
    Name: 'Tech-J-Ext',
    Project: {
      ID: 'a094100001FqiLzAAJ',
      Name: 'RISC-V Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Michael',
      ID: '0034100000dh2UOAAY',
      Lastname: 'Halcrow',
      Title: 'Software Engineer',
    },
    Name: 'Users',
    Project: {
      ID: 'a092M00001IV4JEQA1',
      Name: 'Linux Kernel',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Projects',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Members',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Members',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Projects',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Don',
      ID: '0032M00002cRcBCQA0',
      Lastname: 'Stark',
    },
    Name: 'Tech',
    Project: {
      ID: 'a094100001FqiLzAAJ',
      Name: 'RISC-V Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Harry',
      ID: '0034100000vtH6fAAE',
      Lastname: 'Wang',
      Title: 'Head of Products',
    },
    Name: 'Cncf-Chinese-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Ian',
      ID: '0034100000dFqm2AAC',
      Lastname: 'Lewis',
      Title: 'Developer Advocate',
    },
    Name: 'Cncf-Chinese-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Hedieh',
      ID: '0034100000dFpWdAAK',
      Lastname: 'Yaghmai',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Miek',
      ID: '0034100001gvUyTAAU',
      Lastname: 'Gieben',
      Title: 'SRE',
    },
    Name: 'Coredns-Distributors-Announce',
    Project: {
      ID: 'a092M00001IV43BQAT',
      Name: 'CoreDNS',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Aaron',
      ID: '0034100001gve0CAAQ',
      Lastname: 'Crickenberger',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Maya',
      ID: '0034100001qTLw1AAG',
      Lastname: 'Kaczorowski',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Sig-Security',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Andrew',
      ID: '0034100001Oba6GAAR',
      Lastname: 'Chen',
      Title: 'Tech Writer',
    },
    Name: 'K8s-Japanese-Translators',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Christian',
      ID: '0034100001ObaFjAAJ',
      Lastname: 'Kemper',
      Title: 'Staff Engineer',
    },
    Name: 'Cncf-Sig-Security',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Vic',
      ID: '0034100000dh2YiAAI',
      Lastname: 'Iglesias',
      Title: 'Solutions Architect',
    },
    Name: 'Cncf-Helm-Maintainers',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Mayur',
      ID: '0032M00002XyaoCQAR',
      Lastname: 'Kale',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Dmytro',
      ID: '0034100001ObaLLAAZ',
      Lastname: 'Melnyk',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Sarah',
      ID: '0032M00002XyZBQQA3',
      Lastname: 'Allen',
      Title: 'Engineering Manager',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Simon',
      ID: '0032M00002cO9keQAC',
      Lastname: 'Zeltser',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Antonio',
      ID: '0032M00002duM9mQAE',
      Lastname: 'Gulli',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Ville',
      ID: '0034100000vtH6uAAE',
      Lastname: 'Aikas',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sam',
      ID: '0034100001ObaREAAZ',
      Lastname: 'Ramji',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Mike',
      ID: '0034100000dh2SXAAY',
      Lastname: 'Danese',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Wg-Networking',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dFUMVAA4',
      Lastname: 'Aronchick',
      Title: 'Product Manager, Kubernetes',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-Selection',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'Chair',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Board',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Board',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Board',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'Vice Chair',
    RoleEndDate: '2019-12-31',
    RoleStartDate: '2019-01-01',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Governing Board',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStartDate: '2018-07-31',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Audit-Finance-Committee',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Audit-Finance-Committee',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Audit-Finance-Committee',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002mIbzMQAS',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Compensation-Committee',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Compensation-Committee',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Don',
      ID: '0032M00002XybMrQAJ',
      Lastname: 'McCasland',
      Title: 'Senior Release Engineer',
    },
    Name: 'Sig-Security',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Thomas',
      ID: '0034100000vtH6yAAE',
      Lastname: 'Bouldin',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'ADRIAN',
      ID: '0032M00002nu49IQAQ',
      Lastname: 'GRAHAM',
      Title: 'Cloud Solutions Architect',
    },
    Name: 'Opencue-User',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Board',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-Gb',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'Chair',
    RoleEndDate: '2020-02-29',
    RoleStartDate: '2019-04-19',
    Status: 'Active',
    VotingEndDate: '2020-02-29',
    VotingStartDate: '2019-03-01',
    VotingStatus: 'Voting Rep',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Board',
    Contact: {
      Firstname: 'Aparna',
      ID: '0034100000dFcorAAC',
      Lastname: 'Sinha',
      Title: 'Director, Product Management',
    },
    Name: 'Cncf-Gb',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'Chair',
    RoleStartDate: '2019-09-27',
    Status: 'Active',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Technical',
    Contact: {
      Firstname: 'Myles',
      ID: '0032M00002XyZTFQA3',
      Lastname: 'Borins',
      Title: 'Developer Advocate',
    },
    Name: 'Cpc-Private',
    Project: {
      ID: 'a0941000002wBygAAE',
      Name: 'OpenJS Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Ilias',
      ID: '0032M00002h4JEiQAM',
      Lastname: 'Katsardis',
    },
    Name: 'Tsc',
    Project: {
      ID: 'a0941000002wByrAAE',
      Name: 'OpenHPC Project',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Jamie',
      ID: '0032M00002h3fWOQAY',
      Lastname: 'Rachel',
      Title: 'Dev Rel Event Manager',
    },
    Name: 'Cncf-San-Diego-Contrib-Summit',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Andrew',
      ID: '0034100001Oba6GAAR',
      Lastname: 'Chen',
      Title: 'Tech Writer',
    },
    Name: 'K8s-Chinese-Translators',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Michael',
      ID: '0032M00002bvCQ8QAM',
      Lastname: 'Winser',
      Title: 'Product Manager',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of TOC Committee',
    Category: 'Committee',
    Contact: {
      Firstname: 'Tara',
      ID: '0032M00002XyaWRQAZ',
      Lastname: 'Hernandez',
      Title: 'Senior Engineering Manager',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2020-04-20',
    VotingStartDate: '2019-05-19',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Jaice',
      ID: '0032M00002XycMdQAJ',
      Lastname: 'Singer DuMars',
      Title: 'Open Source Strategy Program Manager',
    },
    Name: 'Cdf-Gb',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-Outreach',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of Governing Board',
    Category: 'Committee',
    Contact: {
      Firstname: 'Dan',
      ID: '0034100000dFedqAAC',
      Lastname: 'Lorenc',
      Title: 'Software Engineer',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2020-02-29',
    VotingStartDate: '2019-03-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jaice',
      ID: '0032M00002XycMdQAJ',
      Lastname: 'Singer DuMars',
      Title: 'Open Source Strategy Program Manager',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jacinda',
      ID: '00341000025cWmRAAU',
      Lastname: 'Mein',
      Title: 'Comms Manager',
    },
    Name: 'Cdf-Outreach',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Committee',
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Cdf-Legal',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Voting Rep',
  },
  {
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dFUmcAAG',
      Lastname: 'Burks',
      Title: 'Software Engineer',
    },
    Name: 'Asc-Program',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'Asc-Program',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Dan',
      ID: '0034100000dFedqAAC',
      Lastname: 'Lorenc',
      Title: 'Software Engineer',
    },
    Name: 'Sig-Security',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'April',
      ID: '00341000014hTgoAAE',
      Lastname: 'Nassi',
      Title: 'Program Manager',
    },
    Name: 'Asc-Program',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of TSC Committee',
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'TSC-PRIVATE',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    AppointedBy: 'Vote of TSC Committee',
    Contact: {
      Firstname: 'Jeremy',
      ID: '00341000014hTw6AAE',
      Lastname: 'Whitlock',
      Title: 'Software Engineer',
    },
    Name: 'TSC-PRIVATE',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Brian',
      ID: '0032M00002flkDxQAI',
      Lastname: 'Russell',
      Title: 'Product Manager',
    },
    Name: 'Sig-Security',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'Asc',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Board',
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'Bgb',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'Marketing',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of TSC Committee',
    Contact: {
      Firstname: 'Jeremy',
      ID: '00341000014hTw6AAE',
      Lastname: 'Whitlock',
      Title: 'Software Engineer',
    },
    Name: 'Tsc',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    AppointedBy: 'Vote of TSC Committee',
    Contact: {
      Firstname: 'Marsh',
      ID: '0034100001ObaB9AAJ',
      Lastname: 'Gardiner',
      Title: 'Product Manager',
    },
    Name: 'Tsc',
    Project: {
      ID: 'a0941000002wBykAAE',
      Name: 'OpenAPI Initiative',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-01-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Shahid',
      ID: '0034100000vtH7sAAE',
      Lastname: 'Masud',
      Title: 'Deployment Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dFS20AAG',
      Lastname: 'Hockin',
      Title: 'Principal Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Aaron',
      ID: '0034100001gve0CAAQ',
      Lastname: 'Crickenberger',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-Kcsp-Council',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'paris',
      ID: '00341000014hTjwAAE',
      Lastname: 'pittman',
      Title: 'Kubernetes Program Manager',
    },
    Name: 'Cncf-San-Diego-Contrib-Summit',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Vic',
      ID: '0034100000dh2YiAAI',
      Lastname: 'Iglesias',
      Title: 'Solutions Architect',
    },
    Name: 'Cncf-Helm-Cartographers',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dh2RcAAI',
      Lastname: 'Hesterburg',
      Title: 'Senior Quantitiatve Analyst',
    },
    Name: 'Rconsortium-Members',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Leah',
      ID: '0034100000vtH5ZAAU',
      Lastname: 'Stupak',
      Title: 'Corporate Counsel',
    },
    Name: 'Rconsortium-Members',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Karl',
      ID: '0034100000vtH5CAAU',
      Lastname: 'Millar',
      Title: 'Quantitative Software Engineer',
    },
    Name: 'Rconsortium-Members',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Monica',
      ID: '0034100000dh2V9AAI',
      Lastname: 'Blanco',
      Title: 'Administrative Business Partner',
    },
    Name: 'Rconsortium-Members',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Karl',
      ID: '0034100000vtH5CAAU',
      Lastname: 'Millar',
      Title: 'Quantitative Software Engineer',
    },
    Name: 'Rconsortium-Wg-Api',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100002ND8aCAAT',
      Lastname: 'Cipriano',
      Title: 'Software Engineer / TSC Lead',
    },
    Name: 'Opencue-Tsc-Private',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Greg',
      ID: '0032M00002nu4ENQAY',
      Lastname: 'Denton',
      Title: 'Software Engineer',
    },
    Name: 'Opencue-Tsc-Private',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Jayant',
      ID: '0034100000vKd5DAAS',
      Lastname: 'jkolhe@google.com',
      Title: 'Engineering Director',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Sarah',
      ID: '0032M00002XyZBQQA3',
      Lastname: 'Allen',
      Title: 'Engineering Manager',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Kelsey',
      ID: '0034100000dFyOXAA0',
      Lastname: 'Hightower',
      Title: 'Director, Developer Advocacy',
    },
    Name: 'Cncf-Gb',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Alternate Voting Rep',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bob',
      ID: '0034100000dFQqFAAW',
      Lastname: 'Hrdinsky',
      Title: 'Product Marketing',
    },
    Name: 'Cncf-Reinvent',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Sam',
      ID: '0034100001ObaREAAZ',
      Lastname: 'Ramji',
    },
    Name: 'Cncf-Gb',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Joshua',
      ID: '0032M00002XybIfQAJ',
      Lastname: 'Marantz',
      Title: 'Member Technical Staff',
    },
    Name: 'Cncf-Envoy-Maintainers',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Lizan',
      ID: '0032M00002cOBr2QAG',
      Lastname: 'Zhou',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Envoy-Maintainers',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Louis',
      ID: '0032M00002gL3HnQAK',
      Lastname: 'Jimenez',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Sig-App-Delivery',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Harvey',
      ID: '0032M00002Xych3QAB',
      Lastname: 'Tuch',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Envoy-Maintainers',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Dan',
      ID: '0032M00002XybfHQAR',
      Lastname: 'Noe',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Envoy-Maintainers',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Alyssa',
      ID: '0032M00002cOE9cQAG',
      Lastname: 'Wilk',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Envoy-Maintainers',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Jay',
      ID: '0032M00002XycO3QAJ',
      Lastname: 'Smith',
      Title: 'Hybrid/Multi-Cloud Specialist',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      ID: '0034100000vKd5EAAS',
      Lastname: 'alainjobart@google.com',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Ci-Public',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Evan',
      ID: '0034100001qTLs4AAG',
      Lastname: 'Anderson',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Louis',
      ID: '0034100000dh2XTAAY',
      Lastname: 'Ryan',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Spiffe-Maintainers',
    Project: {
      ID: 'a092M00001IV4RIQA1',
      Name: 'SPIFFE',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Aaron',
      ID: '0034100001gve0CAAQ',
      Lastname: 'Crickenberger',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-Ci-Public',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'paris',
      ID: '00341000014hTjwAAE',
      Lastname: 'pittman',
      Title: 'Kubernetes Program Manager',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Shahid',
      ID: '0034100000vtH7sAAE',
      Lastname: 'Masud',
      Title: 'Deployment Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Ville',
      ID: '0034100000vtH6uAAE',
      Lastname: 'Aikas',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Matthew',
      ID: '0032M00002XybVSQAZ',
      Lastname: 'Moore',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Katherine',
      ID: '0034100000vtH66AAE',
      Lastname: 'Miller',
      Title: 'Program Manager',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Carissa',
      ID: '0034100000vtH1eAAE',
      Lastname: 'Karcher',
      Title: 'Events Manager',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jacinda',
      ID: '00341000025cWmRAAU',
      Lastname: 'Mein',
      Title: 'Comms Manager',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of TOC Committee',
    Category: 'Committee',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Private-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2020-03-17',
    VotingStartDate: '2018-03-17',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Kelsey',
      ID: '0034100000dFyOXAA0',
      Lastname: 'Hightower',
      Title: 'Director, Developer Advocacy',
    },
    Name: 'Cncf-Private-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Wg-Serverless',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Membership Entitlement',
    Category: 'Board',
    Contact: {
      Firstname: 'Chris',
      ID: '0034100000dFUvBAAW',
      Lastname: 'Dibona',
      Title: 'Director, Open Source',
    },
    Name: 'Cncf-Gb',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Alternate Voting Rep',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jan',
      ID: '0034100000vtH6sAAE',
      Lastname: 'Tattermusch',
      Title: 'Senior Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Ucf-Tac',
    Project: {
      ID: 'a092M00001IV4RrQAL',
      Name: 'Urban Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2020-07-31',
    VotingStartDate: '2019-08-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Coredns-Distributors-Announce',
    Project: {
      ID: 'a092M00001IV43BQAT',
      Name: 'CoreDNS',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Richard',
      ID: '0032M00002eQnd5QAC',
      Lastname: 'Ho',
      Title: 'Principal Engineer',
    },
    Name: 'Technical-Discuss',
    Project: {
      ID: 'a092M00001G4R7xQAF',
      Name: 'Chips Alliance Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Maya',
      ID: '0034100001qTLw1AAG',
      Lastname: 'Kaczorowski',
      Title: 'Product Manager',
    },
    Name: 'Sig-Security',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Legal',
    Project: {
      ID: 'a092M00001G4R7xQAF',
      Name: 'Chips Alliance Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Peeyush',
      ID: '0032M00002mGjHkQAK',
      Lastname: 'Tugnawat',
      Title: 'Google Cloud Customer Engineer',
    },
    Name: 'Announce',
    Project: {
      ID: 'a092M00001G4R7xQAF',
      Name: 'Chips Alliance Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-General-Members',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Tac-Private',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Brian',
      ID: '0034100002ND8aCAAT',
      Lastname: 'Cipriano',
      Title: 'Software Engineer / TSC Lead',
    },
    Name: 'Tac-Private',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'William',
      ID: '0034100000vtH1fAAE',
      Lastname: 'Denniss',
      Title: 'Product Manager',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Aaron',
      ID: '0034100001gve0CAAQ',
      Lastname: 'Crickenberger',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Mik',
      ID: '0034100000vtH60AAE',
      Lastname: 'Vyatskov',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Mithravindhaa',
      ID: '0034100001qTM0zAAG',
      Lastname: 'Seshadrirajah',
      Title: 'Software Engineer, Tools and Infrastructure',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Mehdy',
      ID: '0034100000dh2RkAAI',
      Lastname: 'Bohlool',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dFS20AAG',
      Lastname: 'Hockin',
      Title: 'Principal Software Engineer',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Aaron',
      ID: '0034100001gve0CAAQ',
      Lastname: 'Crickenberger',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Governance',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Governance',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Alex',
      ID: '0032M00002XyZYAQA3',
      Lastname: 'Burnos',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Bowei',
      ID: '0032M00002XyZxRQAV',
      Lastname: 'Du',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Purvi',
      ID: '0034100000vtH6LAAU',
      Lastname: 'Desai',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Piotr',
      ID: '0032M00002XycMAQAZ',
      Lastname: 'Sikora',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Eric',
      ID: '0032M00002XyZEdQAN',
      Lastname: 'Anderson',
      Title: 'Staff Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Doug',
      ID: '0032M00002euPS3QAM',
      Lastname: 'Fawley',
      Title: 'SWE',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Vishal',
      ID: '0032M00002evoaTQAQ',
      Lastname: 'Powar',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Jay',
      ID: '0034100000dFsPfAAK',
      Lastname: 'Judkowitz',
      Title: 'Sr. Product Manager',
    },
    Name: 'Cncf-Wg-Servicebroker',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Craig',
      ID: '0034100000dFRvXAAW',
      Lastname: 'Mcluckie',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Wg-Servicebroker',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Jason',
      ID: '0032M00002cODfIQAW',
      Lastname: 'Young',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Louis',
      ID: '0034100000dh2XTAAY',
      Lastname: 'Ryan',
      Title: 'Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Arunkumar',
      ID: '0032M00002ewS0gQAE',
      Lastname: 'Jayaraman',
      Title: 'Software Eng',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Anna',
      ID: '0032M00002cODeeQAG',
      Lastname: 'Berenberg',
      Title: 'Distinguished Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Mark',
      ID: '0032M00002ewNEdQAM',
      Lastname: 'Roth',
      Title: 'Staff Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Hong',
      ID: '0034100001ObaaCAAR',
      Lastname: 'Zhang',
      Title: 'Senior Staff Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Harvey',
      ID: '0032M00002Xych3QAB',
      Lastname: 'Tuch',
      Title: 'Staff Software Engineer',
    },
    Name: 'Udpa-Wg',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Vic',
      ID: '0034100000dh2YiAAI',
      Lastname: 'Iglesias',
      Title: 'Solutions Architect',
    },
    Name: 'Cncf-Helm-Security',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Yu-Ju',
      ID: '0034100000dGNgHAAW',
      Lastname: 'Hong',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Containerd-Maintainers',
    Project: {
      ID: 'a092M00001IV436QAD',
      Name: 'Containerd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Craig',
      ID: '0034100000dFWxFAAW',
      Lastname: 'Box',
      Title: 'Podcast Host',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Morgan',
      ID: '0034100000vKd5BAAS',
      Lastname: 'McLean',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Opentelemetry-Comms',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Comms',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Comms',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      ID: '0034100000vKd5EAAS',
      Lastname: 'alainjobart@google.com',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Lantao',
      ID: '0034100000dFwKAAA0',
      Lastname: 'Liu',
      Title: 'Senior Software Engineer',
    },
    Name: 'Cncf-Containerd-Maintainers',
    Project: {
      ID: 'a092M00001IV436QAD',
      Name: 'Containerd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      // eslint-disable-next-line prettier/prettier
      Firstname: 'Xiaohui \'Dawn\'',
      ID: '0034100000dGQ5XAAW',
      Lastname: 'Chen',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Containerd-Maintainers',
    Project: {
      ID: 'a092M00001IV436QAD',
      Name: 'Containerd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Dmytro',
      ID: '0034100001ObaLLAAZ',
      Lastname: 'Melnyk',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Matthew',
      ID: '0032M00002XybVSQAZ',
      Lastname: 'Moore',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Evan',
      ID: '0034100001qTLs4AAG',
      Lastname: 'Anderson',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Working Group',
    Contact: {
      Firstname: 'Allan',
      ID: '0034100000dh2RjAAI',
      Lastname: 'Naim',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Kubernetescertwg',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Thomas',
      ID: '0034100000vtH6yAAE',
      Lastname: 'Bouldin',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Jingyi',
      ID: '0032M00002XyaayQAB',
      Lastname: 'Hu',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Etcd-Maintainers',
    Project: {
      ID: 'a092M00001IV452QAD',
      Name: 'Etcd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'April',
      ID: '00341000014hTgoAAE',
      Lastname: 'Nassi',
      Title: 'Program Manager',
    },
    Name: 'Cncf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'paris',
      ID: '00341000014hTjwAAE',
      Lastname: 'pittman',
      Title: 'Kubernetes Program Manager',
    },
    Name: 'Cncf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Joseph',
      ID: '0032M00002cODsbQAG',
      Lastname: 'Betz',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Etcd-Maintainers',
    Project: {
      ID: 'a092M00001IV452QAD',
      Name: 'Etcd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'April',
      ID: '00341000014hTgoAAE',
      Lastname: 'Nassi',
      Title: 'Program Manager',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Contributors',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Yang',
      ID: '0032M00002XyaPMQAZ',
      Lastname: 'Guan',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Lizan',
      ID: '0032M00002cOBr2QAG',
      Lastname: 'Zhou',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Oliver',
      ID: '0034100001ObaIyAAJ',
      Lastname: 'Liu',
      Title: 'Senior Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Tc',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Tc',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Caleb',
      ID: '0032M00002XybRJQAZ',
      Lastname: 'Miles',
      Title: 'Technical Program Manager',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Phillip',
      ID: '0034100000dG6jDAAS',
      Lastname: 'Wittrock',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'paris',
      ID: '00341000014hTjwAAE',
      Lastname: 'pittman',
      Title: 'Kubernetes Program Manager',
    },
    Name: 'Cncf-Kubernetes-Maintainers',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'paris',
      ID: '00341000014hTjwAAE',
      Lastname: 'pittman',
      Title: 'Kubernetes Program Manager',
    },
    Name: 'Cncf-Speakers-Bureau',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Ahmet Alp',
      ID: '0034100001qTLsGAAW',
      Lastname: 'Balkan',
      Title: 'Developer Advocate',
    },
    Name: 'Cncf-Speakers-Bureau',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Morgan',
      ID: '0034100000vKd5BAAS',
      Lastname: 'McLean',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Opentelemetry-Community',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Vic',
      ID: '0034100000dh2YiAAI',
      Lastname: 'Iglesias',
      Title: 'Solutions Architect',
    },
    Name: 'Cncf-Helm-Core-Maintainers',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Craig',
      ID: '0034100000dFWxFAAW',
      Lastname: 'Box',
      Title: 'Podcast Host',
    },
    Name: 'Cncf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Matt',
      ID: '0032M00002Xyc2wQAB',
      Lastname: 'Rickard',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Jaice',
      ID: '0032M00002XycMdQAJ',
      Lastname: 'Singer DuMars',
      Title: 'Open Source Strategy Program Manager',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Jago',
      ID: '0032M00002XybGvQAJ',
      Lastname: 'Macleod',
      Title: 'Engineering Director',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Aparna',
      ID: '0034100000dFcorAAC',
      Lastname: 'Sinha',
      Title: 'Director, Product Management',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Lantao',
      ID: '0034100000dFwKAAA0',
      Lastname: 'Liu',
      Title: 'Senior Software Engineer',
    },
    Name: 'Containerd-Security-Announce',
    Project: {
      ID: 'a092M00001IV436QAD',
      Name: 'Containerd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Filip',
      ID: '0034100000dh2RPAAY',
      Lastname: 'Grządkowski',
      Title: 'Tech Lead/Manager',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dh2SAAAY',
      Lastname: 'Oppenheimer',
      Title: 'software engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Chen',
      ID: '0034100000dFdyDAAS',
      Lastname: 'Goldberg',
      Title: 'Engineering Director',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Mike',
      ID: '0034100000dh2SXAAY',
      Lastname: 'Danese',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100002ND8aCAAT',
      Lastname: 'Cipriano',
      Title: 'Software Engineer / TSC Lead',
    },
    Name: 'Opencue-User',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Greg',
      ID: '0032M00002nu4ENQAY',
      Lastname: 'Denton',
      Title: 'Software Engineer',
    },
    Name: 'Opencue-User',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100002ND8aCAAT',
      Lastname: 'Cipriano',
      Title: 'Software Engineer / TSC Lead',
    },
    Name: 'Opencue-Dev',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Greg',
      ID: '0032M00002nu4ENQAY',
      Lastname: 'Denton',
      Title: 'Software Engineer',
    },
    Name: 'Opencue-Dev',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Saad',
      ID: '0034100000dh2VaAAI',
      Lastname: 'Ali',
      Title: 'Senior Software Engineer',
    },
    Name: 'Cncf-Sig-Storage',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Opencue-Dev',
    Project: {
      ID: 'a092M00001If9ueQAB',
      Name: 'Open Cue',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jaice',
      ID: '0032M00002XycMdQAJ',
      Lastname: 'Singer DuMars',
      Title: 'Open Source Strategy Program Manager',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Navneet',
      ID: '0034100000dG74hAAC',
      Lastname: 'Joneja',
      Title: 'Product',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Images',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jaice',
      ID: '0032M00002XycMdQAJ',
      Lastname: 'Singer DuMars',
      Title: 'Open Source Strategy Program Manager',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Justin',
      ID: '0032M00002Xyc9VQAR',
      Lastname: 'Santa Barbara',
      Title: 'Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'William',
      ID: '0034100000vtH1fAAE',
      Lastname: 'Denniss',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Images',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dFS20AAG',
      Lastname: 'Hockin',
      Title: 'Principal Software Engineer',
    },
    Name: 'Cncf-Images',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Piotr',
      ID: '0032M00002XycMAQAZ',
      Lastname: 'Sikora',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Envoy-Distributors-Announce',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      ID: '0034100000vKd5EAAS',
      Lastname: 'alainjobart@google.com',
    },
    Name: 'Cncf-Vitess-Maintainers',
    Project: {
      ID: 'a092M00001IV44KQAT',
      Name: 'Vitess',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Harvey',
      ID: '0032M00002Xych3QAB',
      Lastname: 'Tuch',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Envoy-Distributors-Announce',
    Project: {
      ID: 'a092M00001IV43GQAT',
      Name: 'Envoy',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0032M00002XyZBQQA3',
      Lastname: 'Allen',
      Title: 'Engineering Manager',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dFUMVAA4',
      Lastname: 'Aronchick',
      Title: 'Product Manager, Kubernetes',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Morten',
      ID: '0032M00002Xycg7QAB',
      Lastname: 'Torkildsen',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Helm',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Morgan',
      ID: '0034100000vKd5BAAS',
      Lastname: 'McLean',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Opentelemetry-Contributors',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Contributors',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Miek',
      ID: '0034100001gvUyTAAU',
      Lastname: 'Gieben',
      Title: 'SRE',
    },
    Name: 'Cncf-Coredns-Maintainers',
    Project: {
      ID: 'a092M00001IV43BQAT',
      Name: 'CoreDNS',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Coredns-Maintainers',
    Project: {
      ID: 'a092M00001IV43BQAT',
      Name: 'CoreDNS',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Katherine',
      ID: '0034100000vtH66AAE',
      Lastname: 'Miller',
      Title: 'Program Manager',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Carissa',
      ID: '0034100000vtH1eAAE',
      Lastname: 'Karcher',
      Title: 'Events Manager',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Palak',
      ID: '0032M00002XyZP3QAN',
      Lastname: 'Bhatia',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Helm',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Vic',
      ID: '0034100000dh2YiAAI',
      Lastname: 'Iglesias',
      Title: 'Solutions Architect',
    },
    Name: 'Cncf-Helm',
    Project: {
      ID: 'a092M00001IV44JQAT',
      Name: 'Helm',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFTv6AAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk, Azure OCTO',
    },
    Name: 'Cncf-Opentelemetry-Maintainers',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dFUMVAA4',
      Lastname: 'Aronchick',
      Title: 'Product Manager, Kubernetes',
    },
    Name: 'Cncf-Images',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Kuat',
      ID: '0034100001ObaZhAAJ',
      Lastname: 'Yessenov',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Linkerd-Dev',
    Project: {
      ID: 'a092M00001IV43kQAD',
      Name: 'linkerd',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Bogdan',
      ID: '0032M00002benD9QAI',
      Lastname: 'Drutu',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Opentelemetry-Maintainers',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Images',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Sarah',
      ID: '0034100000dFSbfAAG',
      Lastname: 'Novotny',
      Title: 'Open Source Wonk',
    },
    Name: 'Cncf-Legal-Committee',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Cncf-Legal-Committee',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Rebecca',
      ID: '0034100001NfiEnAAJ',
      Lastname: 'Ward',
      Title: 'Senior Product Counsel',
    },
    Name: 'Cncf-Legal-Committee',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Aparna',
      ID: '0034100000dFcorAAC',
      Lastname: 'Sinha',
      Title: 'Director, Product Management',
    },
    Name: 'Cncf-Legal-Committee',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Karl',
      ID: '0034100000vtH5CAAU',
      Lastname: 'Millar',
      Title: 'Quantitative Software Engineer',
    },
    Name: 'Rconsortium-Announce',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Tim',
      ID: '0034100000dh2RcAAI',
      Lastname: 'Hesterburg',
      Title: 'Senior Quantitiatve Analyst',
    },
    Name: 'Rconsortium-Announce',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Monica',
      ID: '0034100000dh2V9AAI',
      Lastname: 'Blanco',
      Title: 'Administrative Business Partner',
    },
    Name: 'Rconsortium-Announce',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Craig',
      ID: '0032M00002XyZK3QAN',
      Lastname: 'Barber',
      Title: 'Software Engineer',
    },
    Name: 'Jenkinsx-Steering',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Leah',
      ID: '0034100000vtH5ZAAU',
      Lastname: 'Stupak',
      Title: 'Corporate Counsel',
    },
    Name: 'Rconsortium-Announce',
    Project: {
      ID: 'a0941000002wByyAAE',
      Name: 'R Consortium',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Reference-Architecture',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'David',
      ID: '0034100000dFUMVAA4',
      Lastname: 'Aronchick',
      Title: 'Product Manager, Kubernetes',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Christian',
      ID: '0034100001ObaFjAAJ',
      Lastname: 'Kemper',
      Title: 'Staff Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Aparna',
      ID: '0034100000dFcorAAC',
      Lastname: 'Sinha',
      Title: 'Director, Product Management',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Matt',
      ID: '0032M00002Xyc2wQAB',
      Lastname: 'Rickard',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Reference-Architecture',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Katharine',
      ID: '0032M00002XyZOfQAN',
      Lastname: 'Berry',
      Title: 'Software Engineer, Tools and Infrastructure',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Chris',
      ID: '0032M00002XycRQQAZ',
      Lastname: 'Sosa',
      Title: 'Engineering Manager',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Yu-Ju',
      ID: '0034100000dGNgHAAW',
      Lastname: 'Hong',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Scott',
      ID: '0032M00002XybcCQAR',
      Lastname: 'Nichols',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-Cloudevents',
    Project: {
      ID: 'a092M00001IV4PvQAL',
      Name: 'CloudEvents',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    AppointedBy: 'Vote of TOC Committee',
    Category: 'Committee',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100000dh2VDAAY',
      Lastname: 'Grant',
      Title: 'Principal Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    RoleEndDate: '2020-03-17',
    RoleStartDate: '2018-03-17',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Eric',
      ID: '0032M00002XyZEdQAN',
      Lastname: 'Anderson',
      Title: 'Staff Software Engineer',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Doug',
      ID: '0032M00002euPS3QAM',
      Lastname: 'Fawley',
      Title: 'SWE',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Srini',
      ID: '0032M00002euVoEQAU',
      Lastname: 'Polavarapu',
      Title: 'Engineering Manager',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'April',
      ID: '00341000014hTgoAAE',
      Lastname: 'Nassi',
      Title: 'Program Manager',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Kailash',
      ID: '0032M00002XycGxQAJ',
      Lastname: 'Sethuraman',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Jayant',
      ID: '0034100000vKd5DAAS',
      Lastname: 'jkolhe@google.com',
      Title: 'Engineering Director',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Varun',
      ID: '0034100000vtH5rAAE',
      Lastname: 'Talwar',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Grpc-Maintainers',
    Project: {
      ID: 'a092M00001IV43LQAT',
      Name: 'gRPC',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Aishwarya',
      ID: '0032M00002XycWcQAJ',
      Lastname: 'Sundar',
      Title: 'Senior Test Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Phillip',
      ID: '0034100000dG6jDAAS',
      Lastname: 'Wittrock',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Benjamin',
      ID: '0032M00002Xya1CQAR',
      Lastname: 'Benjamin',
      Title: 'Software Engineer',
    },
    Name: 'Cncf-K8s-Conformance',
    Project: {
      ID: 'a092M00001IV42DQAT',
      Name: 'Kubernetes',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Hedieh',
      ID: '0034100000dFpWdAAK',
      Lastname: 'Yaghmai',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Kelsey',
      ID: '0034100000dFyOXAA0',
      Lastname: 'Hightower',
      Title: 'Director, Developer Advocacy',
    },
    Name: 'Cncf-Members',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jayant',
      ID: '0034100000vKd5DAAS',
      Lastname: 'jkolhe@google.com',
      Title: 'Engineering Director',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Bob',
      ID: '0034100000dFQqFAAW',
      Lastname: 'Hrdinsky',
      Title: 'Product Marketing',
    },
    Name: 'Cncf-Marketing',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'John',
      ID: '0032M00002cODsqQAG',
      Lastname: 'Belamaric',
      Title: 'Senior Staff Software Engineer',
    },
    Name: 'Cncf-Toc',
    Project: {
      ID: 'a0941000002wBz4AAE',
      Name: 'Cloud Native Computing Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Jeremy',
      ID: '0034100001qTLxBAAW',
      Lastname: 'Lewi',
      Title: 'Software Engineer',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Jeremy',
      ID: '0034100001qTLxBAAW',
      Lastname: 'Lewi',
      Title: 'Software Engineer',
    },
    Name: 'Sig-Mlops',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'SIG',
    Contact: {
      Firstname: 'Pavel',
      ID: '0032M00002XyZxDQAV',
      Lastname: 'Dournov',
      Title: 'Sr Staff Engineering Manager',
    },
    Name: 'Sig-Mlops',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'Board',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Kim',
      ID: '0032M00002Xyb88QAB',
      Lastname: 'Lewandowski',
      Title: 'Product Manager',
    },
    Name: 'Cdf-End-User-Members',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Pavel',
      ID: '0032M00002XyZxDQAV',
      Lastname: 'Dournov',
      Title: 'Sr Staff Engineering Manager',
    },
    Name: 'Cdf-Toc',
    Project: {
      ID: 'a092M00001G3xhxQAB',
      Name: 'Continuous Delivery Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Other',
    Contact: {
      Firstname: 'Aysylu',
      ID: '0032M00002XyaLxQAJ',
      Lastname: 'Greenberg',
      Title: 'Sr Software Engineer',
    },
    Name: 'Spdx-Tech',
    Project: {
      ID: 'a0941000002wBz0AAE',
      Name: 'SPDX',
    },
    Role: 'None',
    Status: 'Inactive',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Jeff',
      ID: '0034100000vtH6kAAE',
      Lastname: 'Johnson',
      Title: 'Software Engineer',
    },
    Name: 'Cf-Dev',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Evan',
      ID: '0034100000dFmgmAAC',
      Lastname: 'Brown',
      Title: 'Technical Lead Manager',
    },
    Name: 'Cf-Dev',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Joseph',
      ID: '0032M00002eSRcnQAG',
      Lastname: 'Lewis III',
      Title: 'Software Engineer',
    },
    Name: 'Cf-Dev',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Eric',
      ID: '0034100000dh2RfAAI',
      Lastname: 'Johnson',
      Title: 'Tech Lead/Manager',
    },
    Name: 'Cf-Dev',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Kim',
      ID: '0034100001Oba3FAAR',
      Lastname: 'Bannerman',
      Title: 'PgM Technical Customer Engagement',
    },
    Name: 'Cf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Casey',
      ID: '0034100000vtH5WAAU',
      Lastname: 'West',
      Title: 'Architecture Advocate',
    },
    Name: 'Cf-Ambassadors',
    Project: {
      ID: 'a0941000002wBz5AAE',
      Name: 'CloudFoundry.org Foundation, Inc.',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Soonson',
      ID: '0034100001gvXJVAA2',
      Lastname: 'Kwon',
      Title: 'Developer Relations Program Manager',
    },
    Name: 'Celinux-Dev',
    Project: {
      ID: 'a092M00001IbSW0QAN',
      Name: 'Consumer Electronics Linux Forum',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Board',
    Contact: {
      Firstname: 'Richard',
      ID: '0032M00002eQnd5QAC',
      Lastname: 'Ho',
      Title: 'Principal Engineer',
    },
    Name: 'Governing-Board',
    Project: {
      ID: 'a092M00001G4R7xQAF',
      Name: 'Chips Alliance Fund',
    },
    Role: 'None',
    Status: 'Active',
    VotingEndDate: '2019-12-31',
    VotingStartDate: '2019-03-01',
    VotingStatus: 'Voting Rep',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Brian',
      ID: '0034100002ND8aCAAT',
      Lastname: 'Cipriano',
      Title: 'Software Engineer / TSC Lead',
    },
    Name: 'TAC',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Todd',
      ID: '0034100002GNLhzAAH',
      Lastname: 'Prives',
      Title: 'Product Manager',
    },
    Name: 'TAC',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Committee',
    Contact: {
      Firstname: 'Greg',
      ID: '0032M00002nu4ENQAY',
      Lastname: 'Denton',
      Title: 'Software Engineer',
    },
    Name: 'TAC',
    Project: {
      ID: 'a09410000182dD2AAI',
      Name: 'Academy Software Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Chris',
      ID: '0034100000dFUvBAAW',
      Lastname: 'Dibona',
      Title: 'Director, Open Source',
    },
    Name: 'Board of Directors',
    Project: {
      ID: 'a0941000002wBz9AAE',
      Name: 'The Linux Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Laura',
      ID: '0034100000dFwLmAAK',
      Lastname: 'Majerus',
      Title: 'attorney',
    },
    Name: 'Member Legal Counsels',
    Project: {
      ID: 'a0941000002wBz9AAE',
      Name: 'The Linux Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Max',
      ID: '0034100001NfhyDAAR',
      Lastname: 'Sills',
      Title: 'Open Source Attorney',
    },
    Name: 'Member Legal Counsels',
    Project: {
      ID: 'a0941000002wBz9AAE',
      Name: 'The Linux Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Jonathan',
      ID: '0034100000dFrA6AAK',
      Lastname: 'Burns',
      Title: 'Senior Patent Counsel',
    },
    Name: 'Member Legal Counsels',
    Project: {
      ID: 'a0941000002wBz9AAE',
      Name: 'The Linux Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Contact: {
      Firstname: 'Daniel',
      ID: '0034100000dh2VXAAY',
      Lastname: 'Berlin',
      Title: 'Lawyer, Open Source Programs Office',
    },
    Name: 'Member Legal Counsels',
    Project: {
      ID: 'a0941000002wBz9AAE',
      Name: 'The Linux Foundation',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
  {
    Category: 'Project',
    Contact: {
      Firstname: 'Morgan',
      ID: '0034100000vKd5BAAS',
      Lastname: 'McLean',
      Title: 'Product Manager',
    },
    Name: 'Cncf-Opentelemetry-Governance',
    Project: {
      ID: 'a092M00001IfTjEQAV',
      Name: 'OpenTelemetry',
    },
    Status: 'Active',
    VotingStatus: 'Observer',
  },
];

const excludeCommunityTypes = ['Committee', 'Project', 'Other'];
const MockCommunityPeopleValid = MockCommunityPeople.filter(item =>
  item.Project.Name.toLowerCase().includes('found')
)
  .filter(
    item => item.Category && !excludeCommunityTypes.includes(item.Category)
  )
  .reduce((acc, item: any) => {
    const id = item.Contact.ID;
    const name = `${item.Contact.Firstname} ${item.Contact.Lastname}`;
    const email = `${item.Contact.Firstname}${item.Contact.Lastname}@email.com`;

    if (!acc[id]) {
      acc[id] = {
        user: { id, name, email },
        roles: [],
        id,
      };
    }

    acc[id].roles.push({
      roleType: 'Community',
      role:
        !item.Role || (item.Role && item.Role.toLowerCase() === 'none')
          ? 'Observer'
          : item.Role,
      communityType: item.Category,
      foundation: item.Project.Name,
      startDate: item.RoleStartDate || '',
      endDate: item.RoleEndDate || '',
      name: item.Name,
    });

    return acc;
  }, {});

const MockCommunityPeopleDisplayed = Object.keys(MockCommunityPeopleValid)
  .filter(key => MockCommunityPeopleValid[key].roles.length)
  .map(key => MockCommunityPeopleValid[key]);

MockCommunityPeopleDisplayed.forEach(member => {
  const rolesGroup = member.roles.reduce((acc, role) => {
    const key = role.role;

    const { name, foundation, communityType, ...extras } = role;

    if (!acc[key]) {
      acc[key] = {
        ...extras,
        options: [],
      };
    }

    acc[key].options.push({
      parent: foundation,
      child: communityType || 'Observer',
      name,
    });

    return acc;
  }, {});

  const memberRoles = Object.keys(rolesGroup).map(key => rolesGroup[key]);

  member.roles = memberRoles;
});
