// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const maintainersCubeQuery = gql`
  query ($organizationId: String!, $dateRange: String!) {
    maintainers(organizationId: $organizationId, dateRange: $dateRange) {
      memberId
      memberUsername
      displayName
      logoUrl
      githubUrl
      twitterUrl
      linkedinUrl
      countChannels
      lastMaintainDate
      countMaintainersProjects
    }
  }
`;
