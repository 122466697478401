// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributingCountriesCubeQuery = gql`
  query contributingCountries($input: ProjectContributorsInput) {
    contributingCountries(input: $input) {
      code
      message
      chart {
        countContributors
        country
      }
      top5 {
        country
        countPercent
        prevCountPercent
        percentageDiff
      }
    }
  }
`;
