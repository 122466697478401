// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateOrganizationMutation = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      code
      message
      success
      organization {
        id
        billingAddress {
          billingStreet
          billingCity
          billingCountry
          billingPostalCode
          billingState
        }
        description
        github
        gitlab
        industry
        logoUrl
        name
        phone
        source
        type
        website
        createdAt
        employees
        totalEmployees
        lastModifiedAt
        lastModifiedBy
        link
        sector
        crunchBaseUrl
      }
    }
  }
`;
