// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const orgsByCommitsCubeQuery = gql`
  query getTop10OrgsByCommits($input: OrgsByCommitsInput) {
    getTop10OrgsByCommits(input: $input) {
      code
      message
      orgCommitsRes {
        orgName
        logoUrl
        commits
      }
    }
  }
`;
