// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  BaseProjectContributionInput,
  CodeContributionsResult,
  EngagementSummaryResult,
  NextEventsResult,
} from '@lfx/core/models';
import { Apollo } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCodeContributionsCubeQuery, nextEventsCubeQuery } from '../queries';
import { engagementSummaryQuery } from '../queries/new-dashboard/engagement-summary';

@Injectable({
  providedIn: 'root',
})
export class NewDashboardServiceGql {
  constructor(private apollo: Apollo) {}

  getCodeContributions(
    input: BaseProjectContributionInput
  ): Observable<CodeContributionsResult> {
    return this.apollo
      .watchQuery<any>({
        query: getCodeContributionsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.getCodeContributions),
        map(cloneDeep)
      );
  }

  nextEvents(input: {
    organizationId: string;
    pageSize?: number;
  }): Observable<NextEventsResult> {
    return this.apollo
      .watchQuery<any>({
        query: nextEventsCubeQuery,
        variables: { input },
      })
      .valueChanges.pipe(
        map(res => res.data.nextEvents),
        map(cloneDeep)
      );
  }

  engagementSummary(
    organizationId: string,
    projectId: string,
    foundationId: string
  ): Observable<EngagementSummaryResult> {
    return this.apollo
      .watchQuery<any>({
        query: engagementSummaryQuery,
        variables: {
          organizationId,
          projectId,
          foundationId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.engagementSummary),
        map(cloneDeep)
      );
  }
}
