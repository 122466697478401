// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const sbomSoftwareValuationQuery = gql`
  query sbomSoftwareValuation($orgId: String) {
    sbomSoftwareValuation(orgId: $orgId) {
      code
      message
      costSavings
      effort
      noOfPeople
      status
    }
  }
`;
