// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const approvedContributorsQuery = gql`
  query getApprovedContributorsList($input: ApprovedContributorInput!) {
    approvedContributorsList(input: $input) {
      username
      email
      image
      githubUsername
      domainUrl
      coverageType
      githubOrgUrl
      dateCreated
    }
  }
`;
