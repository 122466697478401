// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const getUserQuery = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      githubId
      gender
      address {
        city
        country
      }
    }
  }
`;
