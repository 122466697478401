// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const singleCommitterActivitiesCubeQuery = gql`
  query ($organizationId: String!, $memberId: String!, $dateRange: String!) {
    singleCommitterActivities(
      organizationId: $organizationId
      memberId: $memberId
      dateRange: $dateRange
    ) {
      type
      platform
      countContributions
    }
  }
`;
