// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const securityActivityLogQuery = gql`
  query rootSecurityActivityLogs($input: SecurityActivityLogsInput) {
    rootSecurityActivityLogs(input: $input) {
      id
      message
      activityType
      name
      logoUrl
      createdAt
    }
  }
`;
