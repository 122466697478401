// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const nextEventsCubeQuery = gql`
  query nextEvents($input: NextEventsInput) {
    nextEvents(input: $input) {
      code
      nextEvents {
        attendees
        audience
        eventCity
        eventCountry
        eventId
        eventName
        speakers
        sponsorships
        startDate
        status
      }
    }
  }
`;
