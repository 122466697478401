// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateInviteMutation = gql`
  mutation updateInvite($input: UpdateInviteInput!) {
    updateInvite(input: $input) {
      code
      message
      success
      invite {
        inviteId
        email
        roleName
        status
      }
    }
  }
`;
