// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createNewProjectMutation = gql`
  mutation createNewProject($projectInfo: CreateNewProjectInput!) {
    createNewProject(input: $projectInfo) {
      code
      message
      success
      newProject {
        id
      }
    }
  }
`;
