// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const boardMeetingsQuery = gql`
  query BoardMeetings($input: DBTInput!) {
    boardMeetings(input: $input) {
      code
      data {
        attendenceFieldCombined
        orgAttendancePercent
        orgAttendancePercentChange
        orgLastMeetingAttended
        orgMeetings
        orgMeetingsChange
        projectId
        projectName
      }
    }
  }
`;
