// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectEngagementQuery = gql`
  query ProjectEngagement($organizationId: ID!, $projectId: ID!) {
    projectEngagement(organizationId: $organizationId, projectId: $projectId) {
      code
      message
      projectEngagement {
        accountId
        projectId
        projectSlug
        projectName
        commitCountAllTime
        maintainersCountAllTime
        contributorsCountAllTime
        totalEventsAllTime
        totalSponsorshipAudienceReached
        enrollmentsAllTime
        eligibleMembers
      }
    }
  }
`;
