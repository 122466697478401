// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const addressesQuery = gql`
  query OrganizationAdresses($organizationId: ID!, $inUseOnly: Boolean) {
    organizationAdresses(
      organizationId: $organizationId
      inUseOnly: $inUseOnly
    ) {
      id
      title
      city
      country
      postalCode
      state
      street
    }
  }
`;
