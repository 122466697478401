// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const mailingListsQuery = gql`
  query MailingLists($input: DBTInput!) {
    mailingLists(input: $input) {
      code
      message
      data {
        activeMailingLists
        activeMailingListsChange
        newSubscribers
        newSubscribersChange
        pkId
        totalSubscribers
        totalSubscribersChange
      }
    }
    mailingListsByList(input: $input) {
      code
      message
      data {
        isListActive
        mailingList
        newSubscribers
        newSubscribersChange
        totalSubscribers
      }
    }
  }
`;
