// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const dashboardEventsSummaryQuery = gql`
  query dashboardEventsSummary($organizationId: String) {
    dashboardEventsSummary(organizationId: $organizationId) {
      totalAttendances
      totalSpeakers
      totalSponsored
      chartLabels
      attendeesChartData
      eventsBySpeakers {
        name
        speakers
        eventId
      }
      eventsByAttendees {
        eventId
        name
        attendees
      }
      speakers {
        userId
        counts
        name
        logo
      }
      metaData {
        startDate
        endDate
      }
    }
  }
`;
