// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
// tslint:disable: max-line-length
import * as faker from 'faker';
import { randomBadgeId } from '../mocks/generator';
import { generalConstants, imagePlaceholders } from '@config';
import { UserGroup } from './foundation-governance';
import { VulnerableProjectModel } from '@models';

export const foundationCategories = [
  'AI & ML',
  'Blockchain, Data & Analytics',
  'Cloud',
  'Compliance & Security',
  'Embedded & IOT',
  'Platforms',
  'Networking',
];

export interface FoundationProject {
  id: string;
  foundationId: string;
  parent?: string;
  name: string;
  displayName: string;
  status: string;
  description: string;
  logoUrl: string; // URL pointing to the logo of the project
  colorBand: string; // This is a color for the project band
  tag: string;
  isFavorite: boolean;
  slug: string;
  projectSector: string;
  badgeUrl: string; // This should be a URL of your badge generated from https://www.coreinfrastructure.org/programs/badge-program/
  metrics?: MetricsData;
  projectType: string;
  community: {
    contributors: number;
    commits: number;
  };
  committees?: UserGroup[];
  organization: {
    contributors: number;
    commits: number;
    totalCodeContribution: number; // This is a percentage. So simply this number will be added beside % in the UI. Ex: 5%
  };
  stats: {
    contributors: number;
    contributions: number;
    contributorList: any[];
    contributionList: any[];
  };
  creationDate: Date;
  groupName: string;
  ownerId: string;
  programManagerId: string;
  opportunityOwnerId: string;
  enabledServices: [];
  vulnerability: VulnerableProjectModel;
  repositoryUrl?: string;
  website?: string;
  notificationsGroup?: string;
  model?: string[];
  foundation?: {
    id: string;
  };
  entityType?: string;
  funding?: string;
}

interface MetricsData {
  affiliations: number;
  commits: number;
  contributions: number;
  contributors: number;
  organizations: number;
  repositories: number;
}

export const mockFoundationProject = () => {
  const product: string = faker.commerce.product();
  const adjective: string = faker.commerce.productAdjective();
  const material: string = faker.commerce.productMaterial();
  const name = `${adjective} ${material} ${product}`;
  const totalCommits = faker.random.number();
  const orgCommits = faker.random.number(totalCommits);
  const totalContributors = faker.random.number(totalCommits);
  const orgContributors = faker.random.number(totalContributors);

  return {
    id: faker.random.uuid(),
    foundationId: faker.random.uuid(),
    name,
    displayName: name,
    sector: 'Cloud',
    status: 'Active',
    description: faker.lorem.paragraph(),
    logoUrl: imagePlaceholders.foundation,
    colorBand: faker.internet.color(),
    slug: faker.random.name,
    projectSector: faker.random.arrayElement(generalConstants.sector),
    tag: faker.random.arrayElement(foundationCategories),
    isFavorite: false,
    badgeUrl: `https://bestpractices.coreinfrastructure.org/projects/${randomBadgeId()}`,
    projectType: 'Project',
    community: {
      contributors: totalContributors,
      commits: totalCommits,
    },
    organization: {
      contributors: orgContributors,
      commits: orgCommits,
      totalCodeContribution: (orgCommits / totalCommits) * 100,
    },
    stats: {
      contributors: totalContributors,
      contributions: totalContributors,
      contributorList: [],
      contributionList: [],
    },
    creationDate: faker.date.past(1),
    groupName: faker.random.arrayElement([
      'graduated',
      'incubated',
      'sandboxed',
    ]),
    ownerId: faker.internet.email(),
    programManagerId: faker.internet.email(),
    opportunityOwnerId: faker.internet.email(),
    vulnerability: null,
    enabledServices: null,
  };
};

export const demoFoundationProjects: Record<string, FoundationProject> = {
  a092M00001IV42DQAT: {
    ...mockFoundationProject(),
    id: 'a092M00001IV42DQAT',
    foundationId: 'a0941000002wBz4AAE',
    name: 'Kubernetes',
    displayName: 'Kubernetes',
    status: 'Active',
    description:
      'Kubernetes (K8s) is an open-source system for automating deployment, scaling, and management of containerized applications.',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/kubernetes.svg',
    colorBand: '#3371e3',
    tag: 'Orchestration',
    slug: 'kubernetes',
    projectSector: 'Cloud',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/569',
    groupName: 'Graduated',
    community: {
      contributors: 2264,
      commits: 82891,
    },
    organization: {
      contributors: 24,
      commits: 2122,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [
        3839, 3773, 4837, 3899, 5002, 4482, 4199, 3878, 3752, 3845, 3128,
      ],
      contributorList: [
        3765, 4099, 4609, 5033, 5563, 5840, 6869, 7382, 7382, 8143, 9400,
      ],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV42hQAD: {
    ...mockFoundationProject(),
    id: 'a092M00001IV42hQAD',
    foundationId: 'a0941000002wBz4AAE',
    name: 'Prometheus',
    displayName: 'Prometheus',
    status: 'Active',
    description:
      'Power your metrics and alerting with a leading open-source monitoring solution.',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/prometheus.svg',
    colorBand: '#DD3B22',
    slug: 'prometheus',
    projectSector: 'Cloud',
    tag: 'Monitoring',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/486',
    groupName: 'Graduated',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [
        45, 122, 354, 577, 836, 1077, 915, 809, 1400, 837, 322,
      ],
      contributorList: [
        45, 167, 521, 1098, 1934, 3011, 3926, 4735, 6135, 6972, 7294,
      ],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV43GQAT: {
    ...mockFoundationProject(),
    id: 'a092M00001IV43GQAT',
    foundationId: 'a0941000002wBz4AAE',
    name: 'Envoy',
    displayName: 'Envoy',
    description: 'Cloud-native high-performance edge/middle/service proxy',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/envoy.svg',
    colorBand: '#C446C3',
    tag: 'Network Proxy',
    groupName: 'Graduated',
    status: 'Active',
    slug: 'Envoy',
    projectSector: 'Cloud',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 7193,
      contributions: 5456,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV43BQAT: {
    ...mockFoundationProject(),
    id: 'a092M00001IV43BQAT',
    foundationId: 'a0941000002wBz4AAE',
    name: 'CoreDNS',
    displayName: 'CoreDNS',
    status: 'Active',
    description: 'CoreDNS is a DNS server that chains plugins',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/coredns.svg',
    colorBand: '#52307F',
    slug: 'coredns',
    projectSector: 'Cloud',
    tag: 'Service Discovery',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/1250',
    groupName: 'Graduated',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 3765,
      contributions: 8921,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV436QAD: {
    ...mockFoundationProject(),
    id: 'a092M00001IV436QAD',
    foundationId: 'a0941000002wBz4AAE',
    name: 'containerd',
    status: 'Active',
    displayName: 'containerd',
    description:
      'An industry-standard container runtime with an emphasis on simplicity, robustness and portability',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/containerd.svg',
    colorBand: '#000000',
    slug: 'containerd',
    projectSector: 'Cloud',
    tag: 'Containers',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/1271',
    groupName: 'Graduated',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 4367,
      contributions: 8790,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV42IQAT: {
    ...mockFoundationProject(),
    id: 'a092M00001IV42IQAT',
    foundationId: 'a0941000002wBz4AAE',
    name: 'Fluentd',
    status: 'Active',
    displayName: 'Fluentd',
    description:
      'Fluentd is an open source data collector for unified logging layer.',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/fluentd.svg',
    colorBand: '#2B79B9',
    slug: 'fluentd',
    projectSector: 'Cloud',
    tag: 'Logging',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/1189',
    groupName: 'Graduated',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV42cQAD: {
    ...mockFoundationProject(),
    id: 'a092M00001IV42cQAD',
    foundationId: 'a0941000002wBz4AAE',
    name: 'OpenTracing',
    displayName: 'OpenTracing',
    status: 'Active',
    description:
      'Vendor-neutral APIs and instrumentation for distributed tracing',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/open-tracing.svg',
    colorBand: '#1D64AA',
    slug: 'opentracing',
    projectSector: 'Cloud',
    tag: 'Distributed Tracing',
    community: {
      contributors: 38,
      commits: 7042,
    },
    groupName: 'Incubating',
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 3211,
      contributions: 1234,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001IV43LQAT: {
    ...mockFoundationProject(),
    id: 'a092M00001IV43LQAT',
    foundationId: 'a0941000002wBz4AAE',
    name: 'gRPC',
    displayName: 'gRPC',
    status: 'Active',
    description: 'A high performance, open-source universal RPC framework',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/grpc.svg',
    colorBand: '#32B0AB',
    slug: 'gRPC',
    projectSector: 'Cloud',
    tag: 'Remote Procedure Call',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2112',
    groupName: 'Incubating',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001If9v8QAB: {
    ...mockFoundationProject(),
    id: 'a092M00001If9v8QAB',
    foundationId: 'a09410000182dD2AAI',
    name: 'OpenColorIO',
    displayName: 'OpenColorIO',
    status: 'Active',
    description:
      'Complete color management solution geared towards motion picture production with an emphasis on visual effects and computer animation',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/opencolorio.png',
    colorBand: '#000000',
    slug: 'opencolorio',
    projectSector: 'Cloud',
    tag: 'Color Science',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2612',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [
        3839, 3773, 4837, 3899, 5002, 4482, 4199, 3878, 3752, 3845, 3128,
      ],
      contributorList: [
        3765, 4099, 4609, 5033, 5563, 5840, 6869, 7382, 7382, 8143, 9400,
      ],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001If9vIQAR: {
    ...mockFoundationProject(),
    id: 'a092M00001If9vIQAR',
    foundationId: 'a09410000182dD2AAI',
    name: 'openvdb',
    displayName: 'openVDB',
    status: 'Active',
    description:
      'Academy Award-winning open-source C++ library comprising a novel hierarchical data structure and a suite of tools for the efficient storage and manipulation of sparse volumetric data discretized on three-dimensional grids.',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/openvdb.png',
    colorBand: '#CC1117',
    slug: 'kubOpenVDBernetes',
    projectSector: 'Cloud',
    tag: 'File Formats',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2774',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 5643,
      contributions: 8790,
      contributionList: [
        45, 122, 354, 577, 836, 1077, 915, 809, 1400, 837, 322,
      ],
      contributorList: [
        45, 167, 521, 1098, 1934, 3011, 3926, 4735, 6135, 6972, 7294,
      ],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001If9ujQAB: {
    ...mockFoundationProject(),
    id: 'a092M00001If9ujQAB',
    foundationId: 'a09410000182dD2AAI',
    name: 'OpenEXR',
    displayName: 'OpenEXR',
    status: 'Active',
    description:
      'High dynamic-range (HDR) image file format originally developed by Industrial Light & Magic for use in computer imaging applications',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/openexr.png',
    colorBand: '#8A000E',
    slug: 'openexr',
    projectSector: 'Cloud',
    tag: 'Image Libraries',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2799',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001If9ueQAB: {
    ...mockFoundationProject(),
    id: 'a092M00001If9ueQAB',
    foundationId: 'a09410000182dD2AAI',
    name: 'OpenCue',
    displayName: 'OpenCue',
    status: 'Active',
    description: 'An open source render management system',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/opencue.jpg',
    colorBand: '#000000',
    slug: 'OpenCue',
    projectSector: 'Cloud',
    tag: 'Rendering',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2837',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 5612,
      contributions: 7832,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
  a092M00001If9uZQAR: {
    ...mockFoundationProject(),
    id: 'a092M00001If9uZQAR',
    foundationId: 'a09410000182dD2AAI',
    name: 'OpenTimelineIO',
    displayName: 'OpenTimelineIO',
    status: 'Active',
    description:
      'Open Source API and interchange format for editorial timeline information',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/opentimelineio.png',
    colorBand: '#80BE43',
    slug: 'OpenTimelineIO',
    projectSector: 'Cloud',
    tag: 'Animation',
    badgeUrl: 'https://bestpractices.coreinfrastructure.org/projects/2288',
    community: {
      contributors: 38,
      commits: 7042,
    },
    organization: {
      contributors: 3,
      commits: 82,
      totalCodeContribution: 1,
    },
    stats: {
      contributors: 2343,
      contributions: 5456,
      contributionList: [],
      contributorList: [],
    },
    ownerId: 'mark@gmail.com',
    programManagerId: 'devidson@hotmail.com',
    opportunityOwnerId: 'hardley@rediff.com',
  },
};
