// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuthAndPermissionsGuard, ContextRedirectGuard } from '@guards';
import { AuthInterceptorService } from '@interceptors';
import { generalConstants } from '@lfx/config';
import { ForbiddenComponent } from '@lfx/core/components/forbidden/forbidden.component';
import { NotFoundComponent } from '@lfx/core/components/not-found/not-found.component';
import { NotSupportedViewComponent } from '@lfx/core/containers/not-supported-view/not-supported-view.component';
import { UnauthorizedComponent } from '@lfx/core/containers/unauthorized/unauthorized.component';
import { ViewSizeRedirectGuard } from '@lfx/core/guards/view-size-redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: NotFoundComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [
      CheckAuthAndPermissionsGuard,
      ContextRedirectGuard,
      ViewSizeRedirectGuard,
    ],
  },
  { path: ':company', redirectTo: ':company/dashboard', pathMatch: 'full' },
  {
    path: ':company/unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'not-supported-view',
    component: NotSupportedViewComponent,
  },
  {
    path: ':company/profile',
    loadChildren: () =>
      import('./modules/company/company.module').then(m => m.CompanyModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/dashboard',
    redirectTo: ':company/home',
    pathMatch: 'full',
  },
  {
    path: ':company/home',
    loadChildren: () =>
      import('./modules/lf-home/lf-home.module').then(m => m.LfHomeModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/membership-summary',
    loadChildren: () =>
      import('./modules/membership-summary/membership-summary.module').then(
        m => m.MembershipSummaryModule
      ),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/events-details',
    redirectTo: ':company/events',
  },
  {
    path: ':company/events',
    loadChildren: () =>
      import('./modules/events/events.module').then(m => m.EventsModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/training-and-certification',
    loadChildren: () =>
      import(
        './modules/training-and-certification/training-and-certification.module'
      ).then(m => m.TrainingAndCertificationModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/newEmployees',
    redirectTo: ':company/users',
  },
  {
    path: ':company/users',
    loadChildren: () =>
      import('./modules/employee-dashboard/employee-dashboard.module').then(
        m => m.EmployeesDashboardModule
      ),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/project',
    loadChildren: () =>
      import('./modules/project/project.module').then(m => m.ProjectModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/project_contributions',
    redirectTo: ':company/project-contributions',
  },
  {
    path: ':company/project-contributions',
    loadChildren: () =>
      import(
        './modules/project-contributions/project-contributions.module'
      ).then(m => m.ProjectContributionsModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['feature_Beta_SideMenu_project_contributions'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: ':company/software-inventory',
    loadChildren: () =>
      import('./modules/software-inventory/software-inventory.module').then(
        m => m.SoftwareInventoryModule
      ),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      pageContentGreyBackground: true,
      permissions: {
        only: ['feature_Beta_SideMenu_software_inventory'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: '403',
    component: ForbiddenComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
