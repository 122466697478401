// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const singleCommitterProjectsCubeQuery = gql`
  query ($organizationId: String!, $memberId: String!, $dateRange: String!) {
    singleCommitterProjects(
      organizationId: $organizationId
      memberId: $memberId
      dateRange: $dateRange
    ) {
      projectName
    }
  }
`;
