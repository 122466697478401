// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createOrganizationSigningEntityMutation = gql`
  mutation createOrganizationSigningEntity($signingEntity: String!) {
    createOrganizationSigningEntity(signingEntity: $signingEntity) {
      code
      message
      success
    }
  }
`;
