<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<ngb-toast
*ngFor="let toast of toastService.toasts"
[class]="toast.classname"
[autohide]="true"
[delay]="toast.delay || 5000"
(hidden)="toastService.remove(toast)"
>
<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
  <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
</ng-template>

<ng-template #text>
  <div class="d-flex justify-content-between">
    <span class='invite-sent-text'>{{ toast.textOrTpl }}</span>
    <i class="fal fa-times cursor-pointer" (click)="toastService.remove(toast)"></i>
  </div>
</ng-template>

</ngb-toast>
