// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const topTenCertificationsQuery = gql`
  query TopTenCertifications(
    $organizationId: String!
    $dateRange: String!
    $companySize: String!
    $industry: String!
  ) {
    topTenCertifications(
      organizationId: $organizationId
      dateRange: $dateRange
      companySize: $companySize
      industry: $industry
    ) {
      similarSizeCompletedCertificatesFromMyOrg
      similarIndustryCompletedCertificatesFromMyOrg
      similarSize
      similarIndustry
      similarSizeChartLabels
      similarIndustryChartLabels
    }
  }
`;
