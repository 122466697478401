// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const verticalLinePlugin = {
  id: 'vertical-line-plugin',
  renderVerticalLine: (chartInstance, line) => {
    const datasetMeta = chartInstance.getDatasetMeta(0);
    const context = chartInstance.chart.ctx;

    const datasetModel = datasetMeta.data[line.dataIndex]._model;
    const yBarCenter = datasetModel.y;
    const barHeight = datasetModel.height;
    const yStart = yBarCenter - barHeight / 2 - 4;
    const yEnd = yBarCenter + barHeight / 2 + 4;

    const xAxisID = datasetMeta.xAxisID;
    const xCoordinate = chartInstance.scales[xAxisID].getPixelForValue(
      line.xCoordinate
    );

    context.beginPath();
    context.strokeStyle = line.color;
    context.moveTo(xCoordinate, yStart);
    context.lineTo(xCoordinate, yEnd);
    context.stroke();

    if (typeof line.label !== typeof undefined) {
      context.textAlign = 'center';
      context.fillStyle = line.color;
      context.fillText(line.label, xCoordinate, yEnd - 30);
    }
  },

  afterDatasetsDraw(chart) {
    if (chart.data.datasets.length < 1) {
      // No dataset, no line :
      return;
    }

    if (chart.config.lines) {
      chart.config.lines.map(line => {
        this.renderVerticalLine(chart, line);
      });
    }
  },
};
