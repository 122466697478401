// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tncCompanyInsightsQuery = gql`
  query TncCompanyInsights($organizationId: String!, $dateRange: String!) {
    tncCompanyInsights(organizationId: $organizationId, dateRange: $dateRange) {
      noOfEmployeesEnrolled
      noOfUniqueTraining
      noOfEmployeesCertified
      noOfUniqueCertification
    }
  }
`;
