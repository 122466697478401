// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allFoundationsQuery = gql`
  query allFoundations($withMembersData: Boolean = false) {
    foundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      model
      slug
      members @include(if: $withMembersData) {
        name
        logoUrl
        tier {
          name
        }
      }
    }
  }
`;
