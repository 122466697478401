// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationUsersContributorsQuery = gql`
  query organizationUsersContributors(
    $organizationId: ID!
    $projectType: String!
    $projectId: ID
    $isMaintainer: Boolean
    $isContributer: Boolean
  ) {
    organizationUsersContributors(
      organizationId: $organizationId
      projectType: $projectType
      projectId: $projectId
      isMaintainer: $isMaintainer
      isContributer: $isContributer
    ) {
      contributorId
      userName
      userTitle
      userPhotoUrl
      userEmailId
      foundationId
      foundationName
      foundationLogo
      projectName
      isContributor
      isMaintainer
      userStatus
      contributionCount
      orgAffiliation {
        endDate
        pkid
        startDate
      }
    }
  }
`;
