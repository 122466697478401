<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<!-- begin error -->
<div class="error">
    <div class="error-code m-b-10">403</div>
    <div class="error-content">
      <div class="error-message">Forbidden...</div>
      <div class="error-desc m-b-30">
        You Don't have access to this.
      </div>
      <div>
        <a routerLink="/" class="btn btn-success p-l-20 p-r-20">Go Home</a>
      </div>
    </div>
  </div>
  <!-- end error -->
