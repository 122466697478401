<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="offcanvas-container">
    <div class="close-button-box cursor-pointer" (click)="onCloseModal()">
        <i class="fal fa-times"></i>
    </div>
    <div class="main-content-box">
        <div class="main-content">
            <div class="title">
                {{ info.title}}
            </div>
            <div class="intro" [innerHTML]="info.body"></div>
            <div class="definitions" *ngIf="info.children">
                <div class="def" *ngFor="let item of info.children">
                    <div class="def-title">
                        {{ item.title }}
                    </div>
                    <div class="def-description">
                        {{ item.body }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
