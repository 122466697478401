// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { certifiedEmployeesByAreaOfInterestQuery } from './../queries/organization/certified-employees-by-area-of-interest';
import { UpdateOrganizationPayload } from './results/update-organization-result';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  allOrganizationsQuery,
  searchOrganizationsQuery,
  committeeMembersQuery,
  myOrganizationQuery,
  organizationQuery,
  organizationAdminsQuery,
  searchUserQuery,
  addressesQuery,
  membershipRecommendationsQuery,
  eventsSummaryQuery,
  organizationSigningEntityQuery,
  organizationSigningEntityByIdQuery,
  topEventsBySpeakersFromMyOrgQuery,
  speakersSummaryQuery,
  eventsSpeakersQuery,
  topEventsByAttendeesFromMyOrgQuery,
  attendeesSummaryQuery,
  allOrgsEventsSummaryQuery,
  eventsAttendeesQuery,
  topTenCoursesCompletedQuery,
  tncLearnersQuery,
  orgTncLearnersQuery,
  tncCompanyInsightsQuery,
  certificationsQuery,
  trainedEmployeesByTypeOFTrainingQuery,
  trainedEmployeesByGeographyQuery,
  insightsSummaryQuery,
  certificationsGroupedQuery,
  topTenCertificationsQuery,
  certifiedEmployeesByGeographyQuery,
  certificationsForOrgQuery,
  certificationsGroupedForOrgQuery,
  eventsSpeakersForOrgQuery,
  eventsAttendeesForOrgQuery,
  tncEnrollmentsQuery,
  allOrgsCertificationsQuery,
  tncSeriesQuery,
  organizationContributionQuery,
  organizationContributorsStatsQuery,
  organizationEmployeesStatsQuery,
  organizationTeamsSummaryQuery,
  organizationEmployeesQuery,
  trainingsForOrgQuery,
  orgProjectBenefitsQuery,
  organizationContributorInsightsQuery,
  organizationResearchQuery,
  organizationCommitteeContactsQuery,
  emailTemplatesQuery,
  dashboardEventsSummaryQuery,
  dashboardAttendeesSummaryQuery,
  dashboardSpeakersSummaryQuery,
  orgEventsParticipationSummaryQuery,
  getMemberAccountQuery,
  organizationTravelFundEventsQuery,
  organizationTeamsSummaryV2Query,
  organizationBoardMeetingAttendanceQuery,
  organizationUsersContributorsQuery,
  organizationUserContributionDetailsQuery,
  projectContributionsListQuery,
  openSourceInvolvementQuery,
  projectEngagementQuery,
} from '../queries';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {
  Company,
  CompanyAdministrators,
  User,
  DeleteUserRoleScopeInput,
  Address,
  CommitteeMembersConnection,
  CreateAddressInput,
  UpdateAddressInput,
  MemberInvite,
  EventsSummary,
  TopEventsBySpeakersFromMyOrg,
  TopEventsByAttendeesFromMyOrg,
  TopTenCoursesCompleted,
  TncCompanyInsights,
  CertificationConnection,
  TrainedEmployeesByTypeOFTraining,
  TrainedEmployeesByGeography,
  CertificationsGrouped,
  TopTenCertifications,
  CertifiedEmployeesByGeography,
  CertifiedEmployeesByAreaOfInterest,
  UpdateBenefitClaimedInput,
  OrganizationContributorInsightsQueryParams,
  OrganizationContributorInsightsConnection,
  ContributionsResponse,
  OrganizationResearch,
  OrganizationResearchQueryParams,
  OrganizationCommitteeContactsQueryParams,
  OrganizationCommitteeContact,
  RequestChangeCommitteeInput,
  UpdateCommiteeContactInput,
  UpdateOrganizationUserInput,
  UpdateOrganizationUserPayload,
  EmailTemplate,
  DashboardEventsSummary,
  DashboardAttendeeSummary,
  DashboardSpeakersSummary,
  GetResponse,
  OrgTeamsSummaryV2,
  OrgTeamsSummaryV2QueryParams,
  OrgTeamsSummaryV2Results,
  ReassignProjectCommitteeContactsInput,
  NewProjectCommitteeContactResult,
  ProjectContributionList,
  OpenSourceInvolvement,
  ProjectEngagement,
  ProductsKpiInput,
} from '@models';
import {
  AllOrganizationsResult,
  UpdateOrganizationResult,
  SearchUserResult,
  CreateAddressPayload,
  CreateAddressResult,
  UpdateAddressPayload,
  UpdateAddressResult,
  CreateOrganizationSigningEntityPayload,
} from './results';
import {
  uploadFileMutation,
  updateOrganizationMutation,
  deleteUserRoleScopeMutation,
  createUserRoleScopeMutation,
  sendNewMemberInviteMutation,
  createUserRoleScopesMutation,
  createAddressMutation,
  updateAddressMutation,
  resendInviteMutation,
  createOrganizationSigningEntityMutation,
  updateBenefitClaimedMutation,
  sendNewEmployeesInviteMutation,
  labelEmployeesAsAdministratorsMutation,
  deleteAdminRoleScopeMutation,
  requestChangeCommitteeMutation,
  updateCommiteeContactMutation,
  updateOrganizationUserMutation,
  editOrgUserAccessMutation,
  reassignCommitteeContactMutation,
} from '../mutations';
import {
  UpdateOrganizationInput,
  SearchUserInput,
  GetMyOrganizationInput,
  CreateUserRoleScopeInput,
  SendMemberInviteInput,
  GetCommitteeMembersInput,
  GetContributionInput,
  SendNewEmployeesInviteInput,
  EditOrgUserAccessInput,
} from './inputs';
import { MutationResponse } from '@lfx/core/models/mutation-response';
import { EventsDataGrouped } from '@lfx/core/models/events-speakers';
import {
  CompanyEventAttendeeSummary,
  EventsDateRange,
  ViewsEventsSummary,
} from '@config';
import { SearchOrganizationsResult } from './results/search-organizations-result';
import { cloneDeep } from 'lodash';
import { organizationEmployeeQuery } from '../queries/organization/organization-employee';
import { organizationStatsQuery } from '../queries/organization/organization-stats';
import { organizationContributorProjects } from '../queries/organization/organization-contributor-projects';
import { organizationProjects } from '../queries/organization/organization-projects';
import { organizationContributors } from '../queries/organization/organization-contributors-v2';
import { createProductsKpiMutation } from '../mutations/organization/create-products-kpi';

@Injectable({
  providedIn: 'root',
})
export class OrganizationServiceGql {
  emailTemplatedIntervalId;

  constructor(private apollo: Apollo) {}

  getAllOrganizations(): Observable<Company[]> {
    return this.apollo
      .watchQuery<AllOrganizationsResult>({
        query: allOrganizationsQuery,
      })
      .valueChanges.pipe(
        map(res => res.data.organizations),
        map(cloneDeep)
      );
  }

  searchOrganizations(
    name: string,
    type: string,
    ismember: boolean,
    pageSize?: number,
    parentId?: string,
    slug?: string
  ): Observable<Company[]> {
    return this.apollo
      .watchQuery<SearchOrganizationsResult>({
        query: searchOrganizationsQuery,
        variables: {
          name,
          type,
          ismember,
          pageSize,
          parentId,
          slug,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.searchOrganizations),
        map(cloneDeep)
      );
  }

  getMyOrganization(
    options?: GetMyOrganizationInput,
    noCache = false
  ): Observable<Company> {
    return this.apollo
      .query<{ myOrganization: Company }>({
        query: myOrganizationQuery,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.myOrganization),
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(new Error('UNAUTHENTICATED'));
          }

          return of(null);
        })
      );
  }

  getOrganizationById(
    salesforceId: string,
    options?: GetMyOrganizationInput,
    noCache = false
  ): Observable<Company> {
    return this.apollo
      .watchQuery<any>({
        query: organizationQuery,
        variables: {
          salesforceId,
          ...options,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organization),
        map(cloneDeep)
      );
  }

  getContribution(
    options?: GetContributionInput,
    noCache = false
  ): Observable<ContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationContributionQuery,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  getOrganizationContributorProjects(
    options?: any,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: organizationContributorProjects,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  organizationProjects(options?: any, noCache = false): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: organizationProjects,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(res => res.organizationProjects)
      );
  }

  organizationContributors(options?: any, noCache = false) {
    return this.apollo
      .watchQuery<any>({
        query: organizationContributors,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(res => res.organizationContributors)
      );
  }

  organizationUsersContributors(
    organizationId: string,
    projectType: string,
    projectId?: string,
    isMaintainer?: boolean,
    isContributer?: boolean,
    noCache = false
  ) {
    return this.apollo
      .watchQuery<any>({
        query: organizationUsersContributorsQuery,
        variables: {
          organizationId,
          projectType,
          projectId,
          isMaintainer,
          isContributer,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(map(res => res.data.organizationUsersContributors));
  }

  organizationUserContributionDetails(
    organizationId: string,
    userId?: string,
    isAllOrgs?: boolean,
    noCache = false
  ) {
    return this.apollo
      .watchQuery<any>({
        query: organizationUserContributionDetailsQuery,
        variables: { organizationId, userId, isAllOrgs },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationUserContributionDetails)
      );
  }

  getOrganizationStats(
    options?: GetContributionInput,
    noCache = false
  ): Observable<ContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationStatsQuery,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  getOrgEmployeesStats(
    organizationId: string,
    projectId?: string,
    activityfilter?: string,
    noCache = false
  ): Observable<ContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationEmployeesStatsQuery,
        variables: {
          organizationId,
          projectId,
          activityfilter,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationEmployeesStats),
        map(cloneDeep)
      );
  }

  getOrgTeamsSummary(
    organizationId: string,
    noCache = false
  ): Observable<ContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationTeamsSummaryQuery,
        variables: {
          organizationId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationTeamsSummary),
        map(cloneDeep)
      );
  }

  getOrgTeamsSummaryV2(
    organizationId: string,
    queryParams: OrgTeamsSummaryV2QueryParams,
    noCache = false
  ): Observable<GetResponse<OrgTeamsSummaryV2> | null> {
    return this.apollo
      .watchQuery<OrgTeamsSummaryV2Results>({
        query: organizationTeamsSummaryV2Query,
        variables: {
          organizationId,
          queryParams,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationTeamsSummaryV2),
        map(cloneDeep)
      );
  }

  getOrgEmployees(
    organizationId: string,
    engagement?: string,
    pageSize?: number,
    offset?: number,
    sortDir?: string,
    orderBy?: string,
    search?: string,
    status?: string,
    filter?: string,
    morefilter?: string,
    committeefilter?: string,
    lfidfilter?: string,
    projectId?: string,
    membershipfilter?: string,
    ospoLeader?: boolean | null,
    contributorfilter?: string,
    exportToCSV?: boolean,
    activityfilter?: string,
    typeview?: string,
    noCache = false,
    hasmembershipentitlement = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: organizationEmployeesQuery,
        variables: {
          organizationId,
          engagement,
          pageSize,
          offset,
          sortDir,
          orderBy,
          search,
          status,
          filter,
          morefilter,
          committeefilter,
          lfidfilter,
          projectId,
          membershipfilter,
          contributorfilter,
          exportToCSV,
          activityfilter,
          ospoLeader,
          typeview,
          hasmembershipentitlement,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationEmployees),
        map(cloneDeep)
      );
  }

  getOrgEmployee({
    organizationId = '',
    userId = '',
    basic = false,
    noCache = false,
    email = '',
  }): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: organizationEmployeeQuery,
        variables: {
          organizationId,
          userId,
          basic,
          email,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.organizationEmployee),
        map(cloneDeep)
      );
  }

  getOrgContributionStats(
    organizationId: string,
    projectId: string,
    year?: string,
    noCache = false
  ): Observable<ContributionsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: organizationContributorsStatsQuery,
        variables: {
          organizationId,
          projectId,
          year,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  getMembershipRecommendationsByOrganizationId(
    organizationId: string,
    noCache?: boolean
  ): Observable<any[]> {
    return this.apollo
      .watchQuery<any>({
        query: membershipRecommendationsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.membershipRecommendations),
        map(cloneDeep)
      );
  }

  getOrganizationSigningEntity(signingEntityName: string) {
    return this.apollo
      .watchQuery<any>({
        query: organizationSigningEntityQuery,
        variables: {
          signingEntityName,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationSigningEntity),
        map(cloneDeep)
      );
  }

  getOrganizationSigningEntityById(signingEntityId: string) {
    return this.apollo
      .watchQuery<any>({
        query: organizationSigningEntityByIdQuery,
        variables: {
          signingEntityId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationSigningEntityById),
        map(cloneDeep)
      );
  }

  uploadLogo(file: File): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: uploadFileMutation,
        variables: { file },
        context: { useMultipart: true, hasUpload: true },
      })
      .pipe(
        map(res => res.data.uploadContractDoc),
        map(cloneDeep)
      );
  }

  deleteUserRoleScope(
    input: DeleteUserRoleScopeInput
  ): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: deleteUserRoleScopeMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data.deleteUserRoleScope),
        map(cloneDeep)
      );
  }

  deleteAdminRoleScope(
    username: string,
    organizationId: string
  ): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: deleteAdminRoleScopeMutation,
        variables: { username, organizationId },
      })
      .pipe(
        map(res => res.data.deleteAdminRoleScope),
        map(cloneDeep)
      );
  }

  createUserRoleScope(input: CreateUserRoleScopeInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: createUserRoleScopeMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data),
        map(cloneDeep)
      );
  }

  updateOrganizationProfile(
    input: UpdateOrganizationInput
  ): Observable<UpdateOrganizationPayload> {
    return this.apollo
      .mutate<UpdateOrganizationResult>({
        mutation: updateOrganizationMutation,
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: getMemberAccountQuery,
            variables: {
              accountId: input.id,
              inUseOnly: true,
            },
          },
        ],
      })
      .pipe(
        map(res => res.data.updateOrganization),
        map(cloneDeep)
      );
  }

  getOrganizationAdmins(
    salesforceId: string
  ): Observable<CompanyAdministrators> {
    return this.apollo
      .watchQuery<any>({
        query: organizationAdminsQuery,
        variables: {
          salesforceId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationAdmins),
        map(cloneDeep)
      );
  }

  getOrganizationCommitteeMembers(
    options: GetCommitteeMembersInput,
    noCache = false
  ): Observable<CommitteeMembersConnection> {
    return this.apollo
      .watchQuery<any>({
        query: committeeMembersQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: options,
      })
      .valueChanges.pipe(
        map(res => res.data.organization.committeeMembersConnection)
      );
  }

  getOrganizationEventsSummary(
    organizationId: string,
    noCache = false
  ): Observable<EventsSummary> {
    return this.apollo
      .watchQuery<any>({
        query: eventsSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.eventsSummary),
        map(cloneDeep)
      );
  }

  getOrganizationDashboardEventsSummary(
    organizationId: string,
    noCache = false
  ): Observable<DashboardEventsSummary> {
    return this.apollo
      .watchQuery<any>({
        query: dashboardEventsSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.dashboardEventsSummary),
        map(cloneDeep)
      );
  }

  getOrganizationViewsEventsSummary(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<ViewsEventsSummary> {
    return this.apollo
      .watchQuery<any>({
        query: orgEventsParticipationSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: {
            organizationId,
            dateRange,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgEventsParticipationSummary),
        map(cloneDeep)
      );
  }

  getAllOrganizationsEventsSummary(
    dateRange: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: allOrgsEventsSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.allOrgsEventsSummary),
        map(cloneDeep)
      );
  }

  getOrganizationSpeakersSummary(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    noCache = false,
    eventId?: string,
    jfilter?: string,
    offset?: number
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: speakersSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          pageSize,
          orderBy,
          sortDir,
          eventId,
          jfilter,
          offset,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.speakersSummary),
        map(cloneDeep)
      );
  }

  getDashboardOrganizationSpeakersSummary(
    organizationId: string,
    eventId?: string,
    noCache = false
  ): Observable<DashboardSpeakersSummary> {
    return this.apollo
      .watchQuery<any>({
        query: dashboardSpeakersSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          eventId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.dashboardSpeakersSummary),
        map(cloneDeep)
      );
  }

  getOrganizationInsightsSummary(
    organizationId: string,
    pageSize?: number,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: insightsSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          pageSize,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.insightsSummary.data),
        map(cloneDeep)
      );
  }

  getTrainingAndCertificationLearners(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset = 0,
    jfilter = '',
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: tncLearnersQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          pageSize,
          orderBy,
          sortDir,
          offset,
          jfilter,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.tncLearners),
        map(cloneDeep)
      );
  }

  getOrganizationTravelFundEvents(
    organizationId: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset = 0,
    year?: string,
    projectID?: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: organizationTravelFundEventsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          pageSize,
          orderBy,
          sortDir,
          year,
          projectID,
          offset,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationTravelFundEvents),
        map(cloneDeep)
      );
  }

  getOrgTrainingAndCertificationLearners(
    organizationId: string,
    pageSize: number,
    offset: number,
    fromDate: string,
    toDate: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: orgTncLearnersQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          pageSize,
          offset,
          fromDate,
          toDate,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.orgTncLearners),
        map(cloneDeep)
      );
  }

  getTrainingAndCertificationEnrollmentsAndExams(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    offset = 0,
    jfilter = '',
    timeFilterField = '',
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: tncEnrollmentsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          pageSize,
          orderBy,
          sortDir,
          offset,
          jfilter,
          timeFilterField,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.tncEnrollments),
        map(cloneDeep)
      );
  }

  getOrganizationAttendeesSummary(
    organizationId: string,
    dateRange: string,
    pageSize?: number,
    orderBy?: string,
    sortDir?: string,
    noCache = false,
    eventId?: string
  ): Observable<CompanyEventAttendeeSummary[]> {
    return this.apollo
      .watchQuery<any>({
        query: attendeesSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          pageSize,
          orderBy,
          sortDir,
          eventId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.attendeesSummary),
        map(cloneDeep)
      );
  }

  getDashboardOrganizationAttendeesSummary(
    organizationId: string,
    eventId: string,
    noCache = false
  ): Observable<DashboardAttendeeSummary[]> {
    return this.apollo
      .watchQuery<any>({
        query: dashboardAttendeesSummaryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          eventId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.dashboardAttendeesSummary),
        map(cloneDeep)
      );
  }

  getTopEventsBySpeakersFromMyOrg(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TopEventsBySpeakersFromMyOrg> {
    return this.apollo
      .watchQuery<any>({
        query: topEventsBySpeakersFromMyOrgQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.topEventsBySpeakersFromMyOrg),
        catchError(() => of(null))
      );
  }

  getCertifiedEmployeesByAreaOfInterest(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<CertifiedEmployeesByAreaOfInterest[]> {
    return this.apollo
      .watchQuery<any>({
        query: certifiedEmployeesByAreaOfInterestQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.certifiedEmployeesByAreaOfInterest),
        catchError(() => of(null))
      );
  }

  getTrainedEmployeesByTypeOFTraining(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TrainedEmployeesByTypeOFTraining> {
    return this.apollo
      .watchQuery<any>({
        query: trainedEmployeesByTypeOFTrainingQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.trainedEmployeesByTypeOfTraining),
        catchError(() => of(null))
      );
  }

  getTrainedEmployeesByGeography(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TrainedEmployeesByGeography[]> {
    return this.apollo
      .watchQuery<any>({
        query: trainedEmployeesByGeographyQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.trainedEmployeesByGeography),
        catchError(() => of(null))
      );
  }

  getTnCSeries(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    type: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: tncSeriesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          companySize,
          type,
          industry,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.tncSeries),
        catchError(() => of(null))
      );
  }

  getCertifiedEmployeesByGeography(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<CertifiedEmployeesByGeography[]> {
    return this.apollo
      .watchQuery<any>({
        query: certifiedEmployeesByGeographyQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.certifiedEmployeesByGeography),
        catchError(() => of(null))
      );
  }

  getTopEventsByAttendeesFromMyOrg(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TopEventsByAttendeesFromMyOrg> {
    return this.apollo
      .watchQuery<any>({
        query: topEventsByAttendeesFromMyOrgQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.topEventsByAttendeesFromMyOrg),
        catchError(() => of(null))
      );
  }

  getTopCoursesCompleted(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    noCache = false
  ): Observable<TopTenCoursesCompleted> {
    return this.apollo
      .watchQuery<any>({
        query: topTenCoursesCompletedQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          companySize,
          industry,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.topTenCoursesCompleted),
        catchError(() => of(null))
      );
  }

  getTopCertifications(
    organizationId: string,
    dateRange: string,
    companySize: string,
    industry: string,
    noCache = false
  ): Observable<TopTenCertifications> {
    return this.apollo
      .watchQuery<any>({
        query: topTenCertificationsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
          companySize,
          industry,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.topTenCertifications),
        catchError(() => of(null))
      );
  }

  getTncCompanyInsight(
    organizationId: string,
    dateRange: string,
    noCache = false
  ): Observable<TncCompanyInsights> {
    return this.apollo
      .watchQuery<any>({
        query: tncCompanyInsightsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.tncCompanyInsights),
        catchError(() => of(null))
      );
  }

  getMyOrgEventsSpeakersQuery(
    dateRange: EventsDateRange
  ): Observable<EventsDataGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsSpeakersQuery,
        fetchPolicy: 'cache-first',
        variables: {
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.myOrganization.events),
        catchError(() => of(null))
      );
  }

  getOrgEventsSpeakersQuery(
    salesforceId: string,
    dateRange: EventsDateRange
  ): Observable<EventsDataGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsSpeakersForOrgQuery,
        fetchPolicy: 'cache-first',
        variables: {
          salesforceId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organization.events),
        catchError(() => of(null))
      );
  }

  getMyOrgCertificationsQuery(
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.apollo
      .watchQuery<any>({
        query: certificationsQuery,
        fetchPolicy: 'cache-first',
        variables: {
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.myOrganization.certificationsConnection),
        catchError(() => of(null))
      );
  }

  getAllOrgsCertificationsQuery(
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.apollo
      .watchQuery<any>({
        query: allOrgsCertificationsQuery,
        fetchPolicy: 'cache-first',
        variables: {
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.allOrgsTnCSummary),
        catchError(() => of(null))
      );
  }

  getOrgTrainingsQuery(
    salesforceId: string,
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.apollo
      .watchQuery<any>({
        query: trainingsForOrgQuery,
        fetchPolicy: 'cache-first',
        variables: {
          salesforceId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organization.certificationsConnection),
        catchError(() => of(null))
      );
  }

  getOrgCertificationsQuery(
    organizationId: string,
    dateRange: EventsDateRange
  ): Observable<CertificationConnection> {
    return this.apollo
      .watchQuery<any>({
        query: certificationsForOrgQuery,
        fetchPolicy: 'cache-first',
        variables: {
          organizationId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.tncOrgCertifications),
        catchError(() => of(null))
      );
  }

  getOrgProjectBenefits(
    organizationId: string,
    slug: string,
    category: string,
    salesforceMembershipId: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: orgProjectBenefitsQuery,
        variables: {
          organizationId,
          slug,
          category,
          salesforceMembershipId,
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.orgProjectBenefits.data),
        catchError(() => of(null))
      );
  }

  getMyOrgCertificationsGroupedQuery(
    dateRange: EventsDateRange,
    type: 'training' | 'certification'
  ): Observable<CertificationsGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: certificationsGroupedQuery,
        fetchPolicy: 'cache-first',
        variables: {
          dateRange,
          type,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.myOrganization.certificationsGrouped),
        catchError(() => of(null))
      );
  }

  getOrgCertificationsGroupedQuery(
    salesforceId: string,
    dateRange: EventsDateRange,
    type: 'training' | 'certification'
  ): Observable<CertificationsGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: certificationsGroupedForOrgQuery,
        fetchPolicy: 'cache-first',
        variables: {
          salesforceId,
          dateRange,
          type,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organization.certificationsGrouped),
        catchError(() => of(null))
      );
  }

  getMyOrgEventsAttendeesQuery(
    dateRange: EventsDateRange,
    noCache = false
  ): Observable<EventsDataGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsAttendeesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.myOrganization.events),
        catchError(() => of(null))
      );
  }

  getOrgEventsAttendeesQuery(
    salesforceId: string,
    dateRange: EventsDateRange,
    noCache = false
  ): Observable<EventsDataGrouped[]> {
    return this.apollo
      .watchQuery<any>({
        query: eventsAttendeesForOrgQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          salesforceId,
          dateRange,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.organization.events),
        catchError(() => of(null))
      );
  }

  searchUsers(input: SearchUserInput): Observable<User[]> {
    return this.apollo
      .watchQuery<SearchUserResult>({
        query: searchUserQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.searchUser),
        map(cloneDeep)
      );
  }

  sendNewMemberEmailInvitation(input: SendMemberInviteInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: sendNewMemberInviteMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  labelEmployeesAsAdministrators(
    input: SendMemberInviteInput[]
  ): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: labelEmployeesAsAdministratorsMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  sendNewEmployeesInvite(input: SendNewEmployeesInviteInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: sendNewEmployeesInviteMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  resendEmailInvitation(
    inviteId: string,
    inviteEmail: string
  ): Observable<MutationResponse<MemberInvite>> {
    return this.apollo
      .mutate<any>({
        mutation: resendInviteMutation,
        variables: {
          inviteId,
          inviteEmail,
        },
      })
      .pipe(map(res => res.data.resendInvite));
  }

  createUserRoleScopes(input: CreateUserRoleScopeInput[]): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: createUserRoleScopesMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  getAddresses(
    organizationId: string,
    inUseOnly = true,
    noCache = false
  ): Observable<Address[]> {
    return this.apollo
      .watchQuery<{ organizationAdresses: Address[] }>({
        query: addressesQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, inUseOnly },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationAdresses),
        map(cloneDeep)
      );
  }

  createAddress(input: CreateAddressInput): Observable<CreateAddressPayload> {
    return this.apollo
      .mutate<CreateAddressResult>({
        mutation: createAddressMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.createAddress));
  }

  updateAddress(input: UpdateAddressInput): Observable<UpdateAddressPayload> {
    return this.apollo
      .mutate<UpdateAddressResult>({
        mutation: updateAddressMutation,
        refetchQueries: [
          {
            query: addressesQuery,
            variables: {
              organizationId: input.organizationId,
              inUseOnly: true,
            },
          },
        ],
        awaitRefetchQueries: true,
        variables: { input },
      })
      .pipe(map(res => res.data.updateAddress));
  }

  updateBenefitClaimed(input: UpdateBenefitClaimedInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: updateBenefitClaimedMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.updateBenefitClaimed));
  }

  createSigningEntity(
    signingEntity: string
  ): Observable<CreateOrganizationSigningEntityPayload> {
    return this.apollo
      .mutate<{
        createOrganizationSigningEntity: CreateOrganizationSigningEntityPayload;
      }>({
        mutation: createOrganizationSigningEntityMutation,
        variables: { signingEntity },
      })
      .pipe(map(res => res.data.createOrganizationSigningEntity));
  }

  getContributorInsights(
    organizationId,
    queryString: OrganizationContributorInsightsQueryParams,
    noCache = false
  ): Observable<OrganizationContributorInsightsConnection> {
    return this.apollo
      .watchQuery<{
        organizationContributorInsights: OrganizationContributorInsightsConnection;
      }>({
        query: organizationContributorInsightsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, queryString },
      })
      .valueChanges.pipe(map(res => res.data.organizationContributorInsights));
  }

  getOrganizationResearch(
    organizationId: string,
    queryParams: OrganizationResearchQueryParams,
    isMember
  ): Observable<OrganizationResearch> {
    return this.apollo
      .watchQuery<{
        organizationResearch: OrganizationResearch;
      }>({
        query: organizationResearchQuery,
        variables: { organizationId, queryParams, isMember },
        fetchPolicy: 'cache-first',
      })
      .valueChanges.pipe(map(res => res.data.organizationResearch));
  }

  getOrganizationBoardMeetingAttendance(
    organizationId: string,
    noCache = true
  ): Observable<OrganizationCommitteeContact[]> {
    return this.apollo
      .watchQuery<any>({
        query: organizationBoardMeetingAttendanceQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationBoardMeetingAttendance)
      );
  }

  getOrganizationCommitteeContacts(
    organizationId: string,
    queryParams: OrganizationCommitteeContactsQueryParams,
    noCache = false
  ): Observable<OrganizationCommitteeContact[]> {
    return this.apollo
      .watchQuery<any>({
        query: organizationCommitteeContactsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, queryParams },
      })
      .valueChanges.pipe(
        map(res => res.data.organizationCommitteeContacts.data)
      );
  }

  requestChangeCommittee(
    input: RequestChangeCommitteeInput,
    refetchParams: OrganizationCommitteeContactsQueryParams
  ): Observable<{ message: string }> {
    return this.apollo
      .mutate<{
        requestChangeCommittee: { message: string };
      }>({
        mutation: requestChangeCommitteeMutation,
        refetchQueries: [
          {
            query: organizationCommitteeContactsQuery,
            variables: {
              organizationId: input.orgId,
              queryParams: refetchParams,
            },
          },
        ],
        variables: { input },
      })
      .pipe(map(res => res.data.requestChangeCommittee));
  }

  updateCommitteeContact(
    input: UpdateCommiteeContactInput
  ): Observable<{ message: string }> {
    return this.apollo
      .mutate<{
        updateCommiteeContact: { message: string };
      }>({
        mutation: updateCommiteeContactMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.updateCommiteeContact));
  }

  updateOrganizationUser(
    input: UpdateOrganizationUserInput
  ): Observable<UpdateOrganizationUserPayload[]> {
    return this.apollo
      .mutate<{
        updateOrganizationUser: UpdateOrganizationUserPayload[];
      }>({
        mutation: updateOrganizationUserMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.updateOrganizationUser));
  }

  getEmailTemplates(
    filter?: string,
    name?: string
  ): Observable<EmailTemplate[]> {
    if (this.emailTemplatedIntervalId) {
      clearInterval(this.emailTemplatedIntervalId);
    }

    return this.apollo
      .watchQuery<{ emailTemplates: EmailTemplate[] }>({
        query: emailTemplatesQuery,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        variables: {
          name,
          filter,
        },
      })
      .valueChanges.pipe(
        tap(res => {
          const url =
            res.data.emailTemplates &&
            res.data.emailTemplates[0] &&
            res.data.emailTemplates[0].template;
          const regex = /X-Amz-Expires=(\d+)/;
          const match = url.match(regex);

          if (match) {
            const expiresIn = parseInt(match[1], 10);

            this.emailTemplatedIntervalId = setInterval(() => {
              this.apollo.client
                .query({
                  query: emailTemplatesQuery,
                  fetchPolicy: 'network-only',
                  variables: {
                    name,
                    filter,
                  },
                })
                .then(({ data }) => {
                  this.apollo.client.writeQuery({
                    query: emailTemplatesQuery,
                    variables: {
                      name,
                      filter,
                    },
                    data,
                  });
                });
            }, expiresIn * 1000);
          }
        }),
        map(res => res.data.emailTemplates)
      );
  }

  editOrgUserAccess(input: EditOrgUserAccessInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: editOrgUserAccessMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data.editOrgUserAccess),
        map(cloneDeep)
      );
  }

  reassignCommitteeRoles(
    input: ReassignProjectCommitteeContactsInput
  ): Observable<MutationResponse<NewProjectCommitteeContactResult[]>> {
    return this.apollo
      .mutate<any>({
        mutation: reassignCommitteeContactMutation,
        variables: { input },
      })
      .pipe(
        map(res => res.data.reassignCommitteeContact),
        map(cloneDeep)
      );
  }

  getProjectContributionsList(
    organizationId: string,
    isAllOrgs: boolean,
    noCache = false
  ): Observable<ProjectContributionList> {
    return this.apollo
      .watchQuery<any>({
        query: projectContributionsListQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, isAllOrgs },
      })
      .valueChanges.pipe(map(res => res.data.projectContributionList));
  }

  getProjectEngagement(
    organizationId: string,
    projectId: string,
    noCache = false
  ): Observable<ProjectEngagement> {
    return this.apollo
      .watchQuery<any>({
        query: projectEngagementQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, projectId },
      })
      .valueChanges.pipe(map(res => res.data.projectEngagement));
  }

  getOpenSourceInvolvement(
    organizationId: string,
    isAllOrgs: boolean,
    noCache = false
  ): Observable<OpenSourceInvolvement> {
    return this.apollo
      .watchQuery<any>({
        query: openSourceInvolvementQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: { organizationId, isAllOrgs },
      })
      .valueChanges.pipe(map(res => res.data.openSourceInvolvement));
  }

  createProductsKpi(
    input: ProductsKpiInput
  ): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: createProductsKpiMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.createProductsKpi));
  }
}
