// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { GetResponse } from '.';

export interface UploadSbomFileResponse {
  contentType: string;
  fileUrl: string;
  presignedUrl: string;
}

export interface UploadSbomFilePayload {
  filename: string;
  overwrite?: boolean;
  orgId: string;
  repositoryUrl?: string;
}

export enum UploadSbomFileModalStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface DeleteSbomFileInput {
  orgId: string;
  fileId: string;
}

export interface ListSbomFilesInput {
  orgId: string;
  queryParams?: ListSbomFilesQueryParams;
}

export interface ListSbomFilesQueryParams {
  pageSize?: number;
  offset?: number;
  orderBy?: string;
  sortDir?: string;
  filter?: string;
}

export interface ListSbomFilesData {
  componentCount: number;
  error: string;
  firstName: string;
  id: string;
  isDeleted: boolean;
  lastName: string;
  licenseCount: number;
  name: string;
  uploadedBy: string;
  uploadedDate: string;
  isProceeded: boolean;
  status: string;
}

export interface ListSbomFilesResults {
  listSbomFiles: GetResponse<ListSbomFilesData> | null;
}

export interface SbomSearchComponentsQueryParams {
  pageSize?: number;
  offset?: number;
  orderBy?: string;
  sortDir?: string;
  filter?: string;
  Name?: string;
  Version?: string;
  LicenseName?: string;
}

export interface SearchComponentsInput {
  orgId: string;
  queryParams: SbomSearchComponentsQueryParams;
}

export interface SbomSearchComponentsData {
  childComponentCount: number;
  fileId: string;
  fileName: string;
  fileUploadedDate: string;
  id: string;
  licenses: string[];
  name: string;
  packageName: string;
  reference: string;
  version: string;
}
export interface SbomSearchComponentsResponse
  extends GetResponse<SbomSearchComponentsData> {
  code: number;
  message?: string;
  totalDependencies: number;
}

export interface SearchComponentsResults {
  searchComponents: SbomSearchComponentsResponse;
}

export interface ListLicensesAndPackagesResults {
  listLicensesAndPackages: ListLicensesAndPackagesResponse;
}

export interface ListLicensesAndPackagesResponse {
  code: number;
  message?: string;
  payload: {
    licenses: LicensesPayload[];
    packages: PackagesPayload[];
  };
}

export interface LicensesPayload {
  id: string;
  isApproved: boolean;
  name: string;
}

export interface PackagesPayload {
  packageName: string;
}

export interface ListLicensesAndPackagesInput {
  orgId: string;
  params?: {
    license?: ListLicensesQueryParams;
    package?: ListPackagesQueryParams;
  };
}

export interface ListLicensesQueryParams {
  orderBy?: string;
  sortDir?: string;
  filter?: string;
}

export type ListPackagesQueryParams = ListLicensesQueryParams;

export interface SbomSoftwareEvaluationResponse {
  costSavings: number;
  effort: number;
  error: string;
  noOfPeople: number;
  status: string;
  code: number;
  message?: string;
}

export interface SbomSoftwareEvaluationsResults {
  sbomSoftwareValuation: SbomSoftwareEvaluationResponse;
}
