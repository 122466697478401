// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const committeeMembersQuery = gql`
  query organizationCommitteeMembers(
    $salesforceId: ID!
    $projectIds: [ID]
    $committeesCategoriesIds: [String]
    $first: Int
    $offset: Int
  ) {
    organization(salesforceId: $salesforceId) {
      id
      committeeMembersConnection(
        projectIds: $projectIds
        committeesCategoriesIds: $committeesCategoriesIds
        first: $first
        offset: $offset
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        committeeMembers {
          appointedBy
          category
          committeeId
          committeeMemberId
          name
          role
          roleStartDate
          roleEndDate
          status
          project {
            id
            name
            logoUrl
          }
          contact {
            id
            lastName
            firstName
            email
            photoUrl
          }
        }
      }
    }
  }
`;
