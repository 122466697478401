// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

// TODO: Cleanup modal options

export const confirmModalOptions = {
  ariaLabelledBy: 'confirm-close-modal',
  centered: true,
  size: 'sm',
  windowClass: 'dialog-confirm-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const membershipModalOptions = {
  ariaLabelledBy: 'membership-modal',
  size: 'xl',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const cclaReviewDialogOptions = {
  ariaLabelledBy: 'ccla-review-modal',
  size: 'lg',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const claDialogueModalOptions = {
  ariaLabelledBy: 'cla-modal',
  size: 'lg',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const confirmRejectDialogueOptions = {
  ariaLabelledBy: 'confirm-reject-modal',
  centered: true,
  size: 'sm',
  windowClass: 'dialog-modal',
  backdrop: 'static',
  modalDialogClass: 'new-widget-dialog confirmation-dialog',
} as NgbModalOptions;

export const signCLAModalOptions = {
  ariaLabelledBy: 'sign-cla-modal',
  size: 'sm',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const deleteWhitelistRuleOptions = {
  ariaLabelledBy: 'sign-cla-modal',
  size: 'sm',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const whitelistDomainOptions = {
  ariaLabelledBy: 'cla-modal',
  size: 'lg',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const whitelistGithubOrgOptions = {
  ariaLabelledBy: 'cla-modal',
  size: 'lg',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const newFoundationModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  backdrop: 'static',
} as NgbModalOptions;

export const newProjectModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  backdrop: 'static',
  centered: true,
} as NgbModalOptions;

export const newOrganizationModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  size: 'sm',
  windowClass: 'edit-org-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const communityModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  size: 'xl',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const serviceRoleModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  size: 'lg',
  windowClass: 'dialog-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const addAddressModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  windowClass: 'add-address-modal',
  backdrop: 'static',
} as NgbModalOptions;

export const eventDetailsModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  centered: true,
  size: 'lg',
  windowClass: 'offcanvas-modal-dialog',
} as NgbModalOptions;

export const speakerSummaryModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  windowClass: 'dashboard-speaker-summary-modal',
  centered: true,
  size: 'lg',
} as NgbModalOptions;

export const teamMemberModalTitle = {
  add: 'Add Team Member - Services',
  edit: 'Edit Team Member - Services',
};

export const manageMembershipContactModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  windowClass: 'manage-membership-modal',
  backdrop: 'static',
  size: 'lg',
} as NgbModalOptions;

export const exploreMembershipModalOptions = {
  ariaLabelledBy: 'modal-basic-title',
  windowClass: 'explore-membership-modal',
  backdrop: 'static',
  size: 'xl',
} as NgbModalOptions;

export const viewParticipantsOptions = {
  ariaLabelledBy: 'modal-basic-title',
  windowClass: 'view-participants-modal',
  backdrop: 'static',
  size: 'lg',
} as NgbModalOptions;
