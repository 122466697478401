// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateUserMutation = gql`
  mutation updateUser($userInfo: UpdateUserInput!) {
    updateUser(input: $userInfo) {
      code
      message
      success
      user {
        id
        username
        firstName
        lastName
        timezone
        emails {
          emailAddress
          isDeleted
          isPrimary
        }
      }
    }
  }
`;
