// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pageSettings } from '@config';
import { AuthService } from '@lfx/core/services';

@Component({
  selector: 'lfx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;

  constructor(private authService: AuthService, private router: Router) {
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {
    if (!this.authService.loggedIn) {
      this.authService.login();
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }
}
