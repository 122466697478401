// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const trainingsForOrgQuery = gql`
  query organizationCertificationsForOrg(
    $salesforceId: ID!
    $dateRange: String
  ) {
    organization(salesforceId: $salesforceId) {
      id
      certificationsConnection(
        salesforceId: $salesforceId
        dateRange: $dateRange
      ) {
        certifications {
          product {
            name
          }
          thisOrg {
            learners
            certifiedLearners
            certificatesAchieved
            enrollments
          }
        }
      }
    }
  }
`;
