// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectWeContributeToCubeQuery = gql`
  query (
    $organizationId: String = ""
    $foundationId: String = ""
    $projectId: String = ""
    $dateRange: String = ""
    $pageSize: Int = 5
    $offset: Int = 0
  ) {
    getProjectWeContributeCodeTo(
      organizationId: $organizationId
      foundationId: $foundationId
      projectId: $projectId
      dateRange: $dateRange
      pageSize: $pageSize
      offset: $offset
    ) {
      totalProjectsCountRes {
        countProjects
        countProjectsChange
      }
      projectsRes {
        project
        projectName
        projectId
        countCommits
        countCommitsChange
        countPullrequests
        countPullrequestsChange
        countIssues
        countIssuesChange
        contributors {
          displayName
          logoUrl
          countContributions
        }
        last30DaysActivities {
          day
          countContributions
        }
      }
    }
  }
`;
