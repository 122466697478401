// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const committersProjectsCubeQuery = gql`
  query ($organizationUrl: String!, $dateRange: String!) {
    committersProjects(
      organizationUrl: $organizationUrl
      dateRange: $dateRange
    ) {
      projectName
      projectSlug
    }
  }
`;
