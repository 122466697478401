// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  EmployeeOrgAffiliation,
  Foundation,
  FoundationProject,
} from './models';

export const convertObjectToArray = <T>(obj: object): T[] =>
  Object.keys(obj).map(idx => obj[idx]);

export const uniqArray = <T>(arr: T[]): T[] => Array.from(new Set(arr));

export const subsetRecord = <T>(
  set: Record<string, T>,
  keys: string[]
): Record<string, T> => {
  const newSet = {};

  keys.filter(k => {
    if (set[k]) {
      newSet[k] = set[k];
    }
  });

  return newSet;
};

export const sortFoundationsOrProjectsByCommits = (
  first: Foundation | FoundationProject,
  second: Foundation | FoundationProject
) => {
  if (!first.organization && !second.organization) {
    return 0;
  }

  if (first.organization && second.organization) {
    return first.organization.totalCodeContribution <
      second.organization.totalCodeContribution
      ? 1
      : -1;
  }

  if (!second.organization) {
    return -1;
  }

  if (!first.organization) {
    return 1;
  }
};

export const sortFoundationsOrProjectsByName = (
  first: Foundation | FoundationProject,
  second: Foundation | FoundationProject
) => {
  if (!first.name && !second.name) {
    return 0;
  }

  if (first.name && second.name) {
    return first.name < second.name ? -1 : 1;
  }

  if (!second.name) {
    return 1;
  }

  if (!first.name) {
    return -1;
  }
};

export const sortProjectByTitle = (a, b) => (a.title < b.title ? -1 : 1);

export const replaceMultipleStrings = (str, regExp, matchingObject) =>
  str.replace(regExp, matched => `<strong>${matchingObject[matched]}</strong>`);

export const downloadCSVFile = (
  csvData: Record<string, string>[],
  fileName: string
) => {
  const csvFields = Object.keys(csvData[0]);
  const csvRows = csvData.map(data =>
    csvFields.map(field => '"' + data[field] + '"')
  );
  const csvContent = [
    csvFields.join(','),
    ...csvRows.map(row => row.join(',')),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const convertOrgAffiliationToStints = (
  orgAffiliation: EmployeeOrgAffiliation[]
): {
  latest: string;
  past: string[];
} => {
  const stint = {
    latest: '',
    past: [],
  };

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return dateString;
    }
    const splitedDate = dateString.split('-');

    return `${splitedDate[1]}/${splitedDate[2]}/${splitedDate[0]}`;
  };

  const formatedAffiliation = orgAffiliation
    .filter(affiliation => affiliation.startDate)
    .map(affiliation => ({
      startDate: formatDate(affiliation.startDate),
      endDate: formatDate(affiliation.endDate),
      pkid: affiliation.pkid,
    }))
    .sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );

  if (formatedAffiliation.length) {
    const isLatest = affiliation =>
      !affiliation.endDate ||
      formatedAffiliation[0].startDate === affiliation.startDate;

    const latestStint = formatedAffiliation.filter(isLatest)[0];

    stint.latest =
      latestStint.startDate + ' - ' + (latestStint.endDate || 'Present');

    stint.past = formatedAffiliation
      .filter(affiliation => latestStint.pkid !== affiliation.pkid)
      .map(
        affiliation =>
          affiliation.startDate + ' - ' + (affiliation.endDate || 'Present')
      );
  }

  return stint;
};
