// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationDomainsQuery = gql`
  query foundationDomains($foundationId: ID!) {
    foundationDomains(foundationId: $foundationId) {
      name
      state
    }
  }
`;
