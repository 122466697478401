// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const leaderboardCommitteeMembersCubeQuery = gql`
  query ($orgId: String, $pageSize: Int, $offset: Int, $name: String) {
    listOrgCommitteeMembers(
      orgId: $orgId
      pageSize: $pageSize
      offset: $offset
      name: $name
    ) {
      data {
        committees {
          name
          projectName
        }
        projects {
          name
        }
        githubId
        id
        lfUserName
        linkedinId
        name
        photoUrl
        twitterId
      }
      metadata {
        totalSize
      }
    }
  }
`;
