// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectContributorsQuery = gql`
  query (
    $organizationId: ID = ""
    $projectId: ID = ""
    $dateRange: String = ""
    $sortBy: String = ""
  ) {
    projectContributors(
      organizationId: $organizationId
      projectId: $projectId
      dateRange: $dateRange
      sortBy: $sortBy
    ) {
      userlogo
      name
      commits
      prs
      issues
    }
  }
`;

export interface projectContributorsGraphQueryVariables {
  projectId: string;
  organizationId: string;
  dateRange?: string;
  sortBy?: string;
}

export interface projectContributorsGraphQueryResponse {
  projectContributors: ProjectContributorsRow[];
}

export interface ProjectContributorsRow {
  name: string;
  userlogo: string;
  commits: number;
  prs: number;
  issues: number;
}
