// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listOrgEventsParticipantsQuery = gql`
  query listOrgEventsParticipants($input: ListOrgEventsParticipantsInput) {
    listOrgEventsParticipants(input: $input) {
      code
      message
      listOrgEventsParticipantsRes {
        data {
          email
          firstname
          lastEventAt
          lastname
          photoUrl
          totalEvents
          type
          userId
          userName
        }
        metadata {
          offset
          pageSize
          totalSize
        }
      }
    }
  }
`;
