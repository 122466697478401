// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationTravelFundEventsQuery = gql`
  query organizationTravelFundEvents(
    $organizationId: String!
    $pageSize: Int
    $orderBy: String
    $sortDir: String
    $offset: Int
    $year: String
    $projectID: String
  ) {
    organizationTravelFundEvents(
      organizationId: $organizationId
      pageSize: $pageSize
      orderBy: $orderBy
      sortDir: $sortDir
      offset: $offset
      year: $year
      projectID: $projectID
    ) {
      totalPaidTravelFund
      totalEstimatedTravelFund
      totalApprovedTravelFund
      data {
        eventName
        eventYear
        recipientCount
        totalPaidAmount
        totalEstimatedAmount
        totalApprovedAmount
      }
      metadata {
        totalSize
        pageSize
        offset
      }
    }
  }
`;
