// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteProjectCommitteeMutation = gql`
  mutation deleteProjectCommittee(
    $committeeInfo: DeleteProjectCommitteeInput!
  ) {
    deleteProjectCommittee(input: $committeeInfo) {
      code
      message
      success
    }
  }
`;
