// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  membershipContactsQuery,
  membershipHistoryQuery,
  listMembershipsQuery,
  listMemberSponsorshipsQuery,
  getMemberAccountQuery,
  projectRolesStatsQuery,
  listAccountMembershipsQuery,
} from '../queries';
import {
  Membership,
  MembershipContacts,
  GetMembershipContactsQueryParams,
  MembershipContact,
  ListAccountSponsorshipQueryParams,
  GetResponse,
  ListAccountSponsorshipData,
  MemberAccount,
} from '@models';
import {
  GetAccountResults,
  GetMembershipContactsResult,
  ListAccountSponsorshipResults,
  MembershipHistoryResult,
} from './results';

import {
  updateMembershipContactMutation,
  deleteMembershipContactMutation,
  createMembershipContactMutation,
  updateKeyContactRolesMutation,
  updateContactRolesMutation,
} from '../mutations';
import { MembershipFilters } from '@lfx/config';
import {
  ListAccountMembershipsRes,
  ListAccountMembershipsResult,
  ListMembershipsResult,
} from './results/list-memberships-result';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MembershipServiceGql {
  constructor(private apollo: Apollo) {}

  listMembershipsByAccountID(
    accountID: string,
    loadMainDataOnly = false,
    filters?: MembershipFilters,
    noCache = false
  ): Observable<Membership[]> {
    return this.apollo
      .watchQuery<ListMembershipsResult>({
        query: listMembershipsQuery,
        variables: {
          loadMainDataOnly,
          filters: {
            accountID,
            ...filters,
          },
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.listMemberships || []),
        map(cloneDeep)
      );
  }

  listAccountMemberships(
    accountID: string,
    loadMainDataOnly = false,
    filters?: MembershipFilters,
    noCache = false
  ): Observable<ListAccountMembershipsRes> {
    return this.apollo
      .watchQuery<ListAccountMembershipsResult>({
        query: listAccountMembershipsQuery,
        variables: {
          loadMainDataOnly,
          filters: {
            accountID,
            ...filters,
          },
        },
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .valueChanges.pipe(
        map(res => res.data.listAccountMemberships),
        map(cloneDeep)
      );
  }

  getMembershipContacts(
    accountId: string,
    projectId: string,
    queryParams?: GetMembershipContactsQueryParams,
    useCache?: boolean
  ): Observable<MembershipContacts> {
    return this.apollo
      .watchQuery<GetMembershipContactsResult>({
        query: membershipContactsQuery,
        fetchPolicy: useCache ? 'cache-and-network' : 'network-only',
        variables: {
          input: {
            accountId,
            projectId,
            queryParams,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.membershipContacts),
        map(cloneDeep)
      );
  }

  getMembershipHistory(
    accountId: string,
    filter?: string,
    pageSize?: number,
    noCache = false
  ): Observable<Membership[]> {
    return this.apollo
      .watchQuery<MembershipHistoryResult>({
        query: membershipHistoryQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          accountId,
          filter,
          pageSize,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.membershipHistory || []),
        map(cloneDeep)
      );
  }

  getprojectRolesStats(
    accountId: string,
    projectId?: string,
    noCache = false
  ): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: projectRolesStatsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          accountId,
          projectId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.projectRolesStats || []),
        map(cloneDeep)
      );
  }

  updateContact(contact: MembershipContact) {
    return this.apollo
      .mutate<any>({
        mutation: updateMembershipContactMutation,
        variables: {
          input: {
            membershipId: contact.membershipId,
            contactId: contact.contactId,
            update: {
              role: contact.role,
              primaryContact: contact.primaryContact,
            },
          },
        },
      })
      .pipe(
        map(res => res.data.updateMembershipContact.contact),
        map(cloneDeep)
      );
  }

  updateKeyContactRoles(input) {
    return this.apollo
      .mutate<any>({
        mutation: updateKeyContactRolesMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(res => res.data.updateKeyContactRoles),
        map(cloneDeep)
      );
  }

  deleteMembershipContact(input: { membershipId: string; contactId: string }) {
    return this.apollo
      .mutate<any>({
        mutation: deleteMembershipContactMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(res => res.data.deleteMembershipContact),
        map(cloneDeep)
      );
  }

  updateContactRoles(input) {
    return this.apollo
      .mutate<any>({
        mutation: updateContactRolesMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(res => res.data.updateContactRoles),
        map(cloneDeep)
      );
  }

  createMembershipContact(input) {
    return this.apollo
      .mutate<any>({
        mutation: createMembershipContactMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map(res => res.data.createMembershipContact.contact),
        map(cloneDeep)
      );
  }

  listMemberSponsorships(
    accountId: string,
    queryParams: ListAccountSponsorshipQueryParams,
    noCache = false
  ): Observable<GetResponse<ListAccountSponsorshipData> | null> {
    return this.apollo
      .watchQuery<ListAccountSponsorshipResults>({
        query: listMemberSponsorshipsQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          input: { accountId, queryParams },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listMemberSponsorships),
        map(cloneDeep)
      );
  }

  getMemberAccount(
    accountId: string,
    noCache = false
  ): Observable<MemberAccount | null> {
    return this.apollo
      .watchQuery<GetAccountResults>({
        query: getMemberAccountQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
        variables: {
          accountId,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.getMemberAccount),
        map(cloneDeep)
      );
  }
}
