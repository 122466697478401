// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const singleMaintainerReposCubeQuery = gql`
  query ($organizationId: String!, $memberId: String!, $dateRange: String!) {
    singleMaintainerRepos(
      organizationId: $organizationId
      memberId: $memberId
      dateRange: $dateRange
    ) {
      projectName
      channel
    }
  }
`;
