// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationMembershipsQuery = gql`
  query foundation($id: ID!) {
    foundation(id: $id) {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      prorated
      autoRenew
      membershipTerm
      paymentFrequency
      tiers {
        sizeMax
        sizeMin
        name
        description
        id
        pricing
        isProrated
        doNotDisplayInAutoJoin
      }
    }
  }
`;
