// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const singleReviewerProjectsCubeQuery = gql`
  query ($organizationUrl: String!, $memberId: String!, $dateRange: String!) {
    singleReviewerProjects(
      organizationUrl: $organizationUrl
      memberId: $memberId
      dateRange: $dateRange
    ) {
      projectName
    }
  }
`;
