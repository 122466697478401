// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  tap,
  map,
  catchError,
  take,
  shareReplay,
  concatMap,
} from 'rxjs/operators';
import {
  Observable,
  BehaviorSubject,
  of,
  forkJoin,
  from,
  throwError,
} from 'rxjs';
import { generalConstants, authClientConfig } from '@config';
import { AuthSession, Profile } from '@models';
import { StorageService } from './storage.service';
import { PermissionService } from './permission.service';
import { UserServiceGql } from '@lfx/core/gql';

import createAuth0Client, { IdToken } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import * as FullStory from '@fullstory/browser';
export const sessionKey = 'authSession';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: boolean;
  userProfile$: BehaviorSubject<Partial<Profile>>;
  permissions: string[];
  roles: string[];
  identified = false;
  claims;

  auth0Client$ = from(
    createAuth0Client({
      domain: authClientConfig.domain,
      client_id: authClientConfig.clientID,
      redirect_uri: authClientConfig.redirectUri,
    })
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(err))
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => (this.loggedIn = res))
  );

  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) =>
      from(client.handleRedirectCallback(authClientConfig.redirectUri))
    )
  );

  getUser$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getUser()))
  );

  constructor(
    private storageService: StorageService,
    private userServiceGql: UserServiceGql,
    private permissionsService: PermissionService
  ) {}

  login(redirectPath = '/'): void {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: authClientConfig.redirectUri,
        appState: { target: redirectPath },
      });
    });
  }

  updateProfile(profile: Partial<Profile>) {
    this.userProfile$.next(profile);
    this.updateAuthSession(profile);
  }

  initializeUserProfile() {
    return forkJoin(this.getUser$, this.userServiceGql.getCurrentUser()).pipe(
      take(1),
      map(([authUser, cbUser]: any[]) => {
        let userProfile: Partial<Profile> = {};

        if (authUser && cbUser) {
          userProfile = authUser;
          userProfile.username = cbUser.username;
          userProfile.salesforceId = cbUser.id;
          userProfile.primaryEmail = authUser.email;
          userProfile.firstName = cbUser.firstName;
          userProfile.lastName = cbUser.lastName;
          userProfile.avatarUrl = cbUser.avatarUrl
            ? cbUser.avatarUrl
            : authUser.avartarUrl
            ? authUser.avartarUrl
            : authUser.picture;

          if (FullStory.isInitialized()) {
            if (this.identified) {
              FullStory.setUserVars({
                appName: 'LF Company Dashboard',
                salesforceId: userProfile.salesforceId,
                email: userProfile.primaryEmail,
                name: userProfile.firstName + ' ' + userProfile.lastName,
                avatarUrl: userProfile.avatarUrl,
              });
            } else {
              FullStory.identify(userProfile.username, {
                appName: 'LF Company Dashboard',
                salesforceId: userProfile.salesforceId,
                email: userProfile.primaryEmail,
                name: userProfile.firstName + ' ' + userProfile.lastName,
                avatarUrl: userProfile.avatarUrl,
              });
              this.identified = true;
            }
          }
        }

        this.userProfile$ = new BehaviorSubject(userProfile);

        return userProfile;
      })
    );
  }

  setPermissions() {
    if (this.permissions) {
      return of(this.permissions);
    }

    return this.getUserPermissions().pipe(
      tap(perms => {
        this.permissions = perms;
        this.permissionsService.loadPermissions(perms);
      })
    );
  }

  setRoles() {
    if (this.roles) {
      return of(this.roles);
    }

    return this.getUserRoles().pipe(
      tap(roles => {
        this.roles = roles;
        this.permissionsService.loadRoles(roles);
      })
    );
  }

  logout(checkClaims?): void {
    if (checkClaims && !this.claims) {
      this.clearSession();
      this.auth0Client$.subscribe((client: Auth0Client) => {
        client.logout({
          client_id: authClientConfig.clientID,
          returnTo: window.location.origin,
        });
      });
    }
  }

  clearSession(): void {
    this.permissionsService.clearAllPermissionsForSession();
    this.storageService.clear(sessionKey);
    this.storageService.clear(generalConstants.currentContext);
    this.storageService.clear(generalConstants.currentOrganization);
    this.storageService.clear(generalConstants.currentOrganizationB2Bid);
    this.storageService.clear(generalConstants.currentOrganizationSlug);
  }

  getIdToken$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        from(client.getIdTokenClaims(options))
      ),
      concatMap((claims: IdToken) => {
        this.claims = claims;
        let logoutTimeoutId = null;

        if (claims) {
          if (logoutTimeoutId) {
            clearTimeout(logoutTimeoutId);
          }

          return of(claims.__raw);
        }

        logoutTimeoutId = setTimeout(() => this.logout(true), 7000);

        return throwError(new Error('Empty Token'));
      })
    );
  }

  private updateAuthSession(profile: Partial<Profile>) {
    this.storageService
      .getItem<AuthSession>(sessionKey)
      .pipe(
        map(session => ({
          ...session,
          firstName: profile.firstName || session.firstName,
          lastName: profile.lastName || session.lastName,
          avatarUrl: profile.avatarUrl || session.avatarUrl,
        }))
      )
      .subscribe((update: AuthSession) => {
        this.storageService.setItem<AuthSession>(sessionKey, update);
      });
  }

  private getUserPermissions(): Observable<any> {
    if (this.permissions) {
      return of(this.permissions);
    }

    return this.userServiceGql.getCurrentUser().pipe(
      catchError(() => {
        this.logout();

        return null;
      }),
      map((response: any) => {
        const main = response
          ? response.permissions.flatMap(i =>
              i.actions ? i.actions.map(a => `${i.resource}_${a}`) : []
            )
          : [];
        const withScopes = response
          ? response.permissions.flatMap(i =>
              i.actions
                ? i.actions.flatMap(a =>
                    i.scopes.flatMap(s =>
                      s.id.flatMap(id => `${i.resource}_${a}_${id}`)
                    )
                  )
                : []
            )
          : [];

        return main.concat(withScopes);
      })
    );
  }

  private getUserRoles(): Observable<any> {
    if (this.roles) {
      return of(this.roles);
    }

    return this.userServiceGql.getCurrentUser().pipe(
      catchError(() => {
        this.logout();

        return null;
      }),
      map((response: any) => (response ? response.roles : null))
    );
  }
}
