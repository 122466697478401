// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const vulnerabilitiesSeverityQuery = gql`
  query vulnerabilitiesSeverity {
    vulnerabilitiesSeverity {
      low
      medium
      high
    }
  }
`;
