// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteRepositoryRoleMutation = gql`
  mutation deleteRepositoryRole(
    $deleteRepositoryRoleInput: DeleteRepositoryRoleInput!
  ) {
    deleteRepositoryRole(
      deleteRepositoryRoleInput: $deleteRepositoryRoleInput
    ) {
      code
      message
      success
    }
  }
`;
