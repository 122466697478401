// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createRepositoryRoleMutation = gql`
  mutation createRepositoryRole(
    $projectId: String!
    $repositoryId: String!
    $body: AddRepositoryRoleInput
  ) {
    createRepositoryRole(
      projectId: $projectId
      repositoryId: $repositoryId
      body: $body
    ) {
      code
      message
      success
      newRepositoryRoles {
        role
        repositoryId
        source
        updatedAt
        createdAt
      }
    }
  }
`;
