// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationBoardMeetingAttendanceQuery = gql`
  query organizationBoardMeetingAttendance($organizationId: ID!) {
    organizationBoardMeetingAttendance(organizationId: $organizationId) {
      accountName
      accountId
      attendance
    }
  }
`;
