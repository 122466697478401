// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allOrgsEventsSummaryQuery = gql`
  query allOrgsEventsSummary($dateRange: String) {
    allOrgsEventsSummary(dateRange: $dateRange) {
      totalEvents
      totalSpeakers
      totalAttendees
      totalPresentations
      allOrgsTotalAudienceReached
      allOrgsEventsSpokenAt
      speakersByJobFunctions {
        name
        total
      }
      attendeesByJobFunctions {
        name
        total
      }
      topspeakers {
        name
        events
        presentations
        logo
        contactId
        eventList {
          id
          name
          startDate
          presentations
        }
      }
      attendeesByGender {
        man
        notDefined
        woman
        preferNotToAnswer
        otherGenderIdentity
      }
      speakersByGender {
        man
        notDefined
        woman
        preferNotToAnswer
        otherGenderIdentity
      }
      speakersByGeography {
        inEvents
        total
        country
      }
      attendeesByGeography {
        inEvents
        total
        country
      }
      attendeesByIndustry {
        name
        total
      }
      attendeesByJobLevel {
        name
        total
      }
      speakersByJobLevel {
        name
        total
      }
      hostedEvents {
        startDate
        speakers
        attendees
        isVirtual
      }
    }
  }
`;
