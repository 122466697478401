<!---- Copyright The Linux Foundation and each contributor to LFX.-->
<!---- SPDX-License-Identifier: MITs -->
<div class="rounded menu">
    <div class="item" (click)="getPageHelp.emit(helpPaths)">
      Product Documentation
    </div>
    <div class="divider" ></div>
    <div class="item" (click)="createSupportTicket.emit()">
      <a [href]="helpPaths.support" target="_blank">Create Support Ticket</a>
    </div>
</div>
