// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { pageMenus, pageSettings } from '@config';

@Component({
  selector: 'lfx-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('topMenuContainer') topMenuContainer;

  menus = pageMenus;
  navControlLeft = false;
  navControlRight = false;
  navWidth = 0;
  navMarginLeft = 0;
  navMarginRight = 0;
  pageSettings = pageSettings;

  constructor() {}

  ngOnInit() {}

  controlLeft() {
    const widthLeft = this.navMarginLeft;
    const containerWidth = this.topMenuContainer.nativeElement.clientWidth;
    let finalScrollWidth = 0;

    if (widthLeft <= containerWidth) {
      finalScrollWidth = 0;
      this.navControlLeft = false;
    } else {
      finalScrollWidth = widthLeft - containerWidth + 88;
    }

    if (!document.body.classList.contains('rtl-mode')) {
      this.navMarginLeft = finalScrollWidth;
      this.navMarginRight = 0;
      this.navControlRight = true;
    } else {
      this.navMarginRight = finalScrollWidth;
      this.navMarginLeft = 0;
      this.navControlRight = true;
    }
  }

  controlRight() {
    const containerWidth = this.topMenuContainer.nativeElement.clientWidth - 88;
    const widthLeft = this.navWidth + -this.navMarginLeft - containerWidth;
    let finalScrollWidth = 0;

    if (widthLeft <= containerWidth) {
      finalScrollWidth = widthLeft - -this.navMarginLeft + 128;
      this.navControlRight = false;
    } else {
      finalScrollWidth = containerWidth - -this.navMarginLeft - 128;
    }

    if (finalScrollWidth !== 0) {
      if (!document.body.classList.contains('rtl-mode')) {
        this.navMarginLeft = finalScrollWidth;
        this.navMarginRight = 0;
      } else {
        this.navMarginRight = finalScrollWidth;
        this.navMarginLeft = 0;
      }

      this.navControlLeft = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const windowWidth = this.topMenuContainer.nativeElement.clientWidth - 128;
      let listFullWidth = 0;
      let listPrevWidth = 0;
      let listActive = false;

      const navList = Array.from(
        document.querySelectorAll('.top-menu .nav > li')
      );

      navList.forEach(list => {
        const elm = list as any;

        listFullWidth += elm.offsetWidth;
        listPrevWidth += !listActive ? elm.offsetWidth : 0;
        listActive = elm.classList.contains('active') ? true : listActive;
      });

      this.navWidth = listFullWidth;

      listPrevWidth = !listActive ? 0 : listPrevWidth;

      if (listPrevWidth >= windowWidth) {
        const finalScrollWidth = listPrevWidth - windowWidth + 128;

        if (!document.body.classList.contains('rtl-mode')) {
          this.navMarginLeft = finalScrollWidth;
          this.navMarginRight = 0;
        } else {
          this.navMarginRight = finalScrollWidth;
          this.navMarginLeft = 0;
        }
      }

      this.navControlRight =
        listPrevWidth !== listFullWidth && listFullWidth >= windowWidth
          ? true
          : false;
      this.navControlLeft =
        listPrevWidth >= windowWidth && listFullWidth >= windowWidth
          ? true
          : false;
    });
  }

  expandCollapseSubmenu(currentMenu, allMenu, active) {
    if (
      currentMenu.state === 'expand' ||
      (active.isActive && !currentMenu.state)
    ) {
      currentMenu.state = 'collapse';
    } else {
      currentMenu.state = 'expand';
    }
  }
}
