// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const contributorProjectsQuery = gql`
  query contributorProjects(
    $userId: String!
    $organizationId: String
    $projectId: String
  ) {
    contributorProjects(
      userId: $userId
      organizationId: $organizationId
      projectId: $projectId
    ) {
      projects {
        id
        name
        maintainer
        userDetails {
          email
          username
          repositories {
            id
            maintainer
            shortName
          }
        }
      }
    }
  }
`;

export const projectRepositoriesQuery = gql`
  query projectRepositories($projectId: String!) {
    projectRepositories(projectId: $projectId) {
      data {
        id
        slug
        url
      }
    }
  }
`;
