// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectMeetingsQuery = gql`
  query projectMeeting($id: ID!) {
    projectMeeting(id: $id) {
      name
      date
    }
  }
`;
