// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteInviteMutation = gql`
  mutation deleteInvite($input: DeleteInviteInput!) {
    deleteInvite(input: $input) {
      code
      message
      success
    }
  }
`;
