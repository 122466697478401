// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const reassignCommitteeContactMutation = gql`
  mutation reassignCommitteeContact(
    $input: ReassignProjectCommitteeContactsInput!
  ) {
    reassignCommitteeContact(input: $input) {
      code
      message
      success
      payload {
        email
        firstName
        lastName
        projectId
        committeeId
        role
        votingStatus
        allowUserUpdate
        success
        message
      }
    }
  }
`;
