// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const committersCubeQuery = gql`
  query (
    $organizationId: String!
    $dateRange: String!
    $slugs: [String]
    $lastActive: String
  ) {
    committers(
      organizationId: $organizationId
      dateRange: $dateRange
      slugs: $slugs
      lastActive: $lastActive
    ) {
      memberId
      memberUsername
      displayName
      logoUrl
      githubUrl
      twitterUrl
      linkedinUrl
      countCommits
      countContributions
      countCommittersProjects
      lastCommitDate
    }
  }
`;
