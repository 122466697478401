// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const userEventsQuery = gql`
  query (
    $dateRange: String = ""
    $userId: ID = ""
    $email: String = ""
    $organizationId: ID = ""
  ) {
    getUserEvents(
      dateRange: $dateRange
      userId: $userId
      email: $email
      organizationId: $organizationId
    ) {
      ym
      value
      speaker
      attendee
    }
  }
`;

export interface userEventsGraphQueryVariables {
  userId?: string;
  email?: string;
  dateRange?: string;
  organizationId?: string;
}

export interface userEventsGraphQueryResponse {
  getUserEvents: userEventsRow[];
}

export interface userEventsRow {
  ym: string;
  value: number;
  speaker: number;
  attendee: number;
}
