// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectMembershipDetails = gql`
  query GetProjectMembershipDetails(
    $projectId: ID!
    $organizationId: ID!
    $accountB2bId: ID!
    $filter: String
  ) {
    currentUser {
      roles
    }
    membershipHistory(accountId: $accountB2bId, filter: $filter) {
      endDate
      membershipType
      product {
        name
        family
      }
    }
    project(id: $projectId) {
      id
      logoUrl
      name
      autoJoinEnabled
      website
      programManager {
        email
        firstName
        lastName
      }
      userOrganizationMembership(organizationId: $organizationId) {
        senderName
        submitted
        applicationStatus
      }
      model
    }
  }
`;

export const projectMembershipWithTiers = gql`
  query GetProjectMembershipDetailsWithTiers(
    $projectId: ID!
    $organizationId: ID
  ) {
    currentUser {
      roles
    }
    project(id: $projectId) {
      id
      slug
      autoJoinEnabled
      membershipAgreementLink
      website
      meMembership {
        projectId
        accountId
        endDate
        product {
          id
          name
        }
      }
      userOrganizationMembership(organizationId: $organizationId) {
        senderName
        senderEmail
        submitted
        productId
        productName
        applicationStatus
        stage
        OpportunityDescription
        OpportunityProductDescription
      }
      userOrganizationTiers(organizationId: $organizationId) {
        id
        productId
        name
        price
        benefits {
          name
        }
      }
    }
  }
`;

export const membershipProjectsQuery = gql`
  query getMembershipProjects {
    membershipProjects {
      logo
      id: memproject_id
      name
      slug
    }
  }
`;
