// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Injectable } from '@angular/core';
import {
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, from, of } from 'rxjs';
import {
  NgxPermissionsGuard,
  NgxPermissionsService,
  NgxRolesService,
} from 'ngx-permissions';
import { AuthGuard } from './auth.guard';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckAuthAndPermissionsGuard extends NgxPermissionsGuard {
  routerObject: Router;
  constructor(
    permissionsService: NgxPermissionsService,
    rolesService: NgxRolesService,
    router: Router,
    private authGuard: AuthGuard
  ) {
    super(permissionsService, rolesService, router);
    this.routerObject = router;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const canActivate$ = this.authGuard.canActivate(
      route,
      state
    ) as Observable<boolean>;

    return canActivate$.pipe(
      switchMap(value => {
        if (!value) {
          return of(this.routerObject.createUrlTree['/login']);
        }

        const superCanLoad$ = super.canActivate(route, state);

        if (superCanLoad$ === true || superCanLoad$ === false) {
          return of(superCanLoad$);
        }

        if (superCanLoad$ instanceof Promise) {
          return from(superCanLoad$);
        }

        return superCanLoad$ as Observable<boolean>;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const canActivateChild$ = this.authGuard.canActivateChild(
      childRoute,
      state
    ) as Observable<boolean>;

    return canActivateChild$.pipe(
      switchMap(value => {
        if (!value) {
          return of(this.routerObject.createUrlTree['/login']);
        }

        const superCanLoad$ = super.canActivateChild(childRoute, state);

        if (superCanLoad$ === true || superCanLoad$ === false) {
          return of(superCanLoad$);
        }

        if (superCanLoad$ instanceof Promise) {
          return from(superCanLoad$);
        }

        return superCanLoad$;
      })
    );
  }

  canLoad(route: Route): any {
    const canLoad$ = this.authGuard.canLoad(route, null) as Observable<boolean>;

    return canLoad$.pipe(
      switchMap(value => {
        if (!value) {
          return of(this.routerObject.createUrlTree['/login']);
        }

        const superCanLoad$ = super.canLoad(route);

        if (superCanLoad$ === true || superCanLoad$ === false) {
          return of(superCanLoad$);
        }

        if (superCanLoad$ instanceof Promise) {
          return from(superCanLoad$);
        }

        return superCanLoad$;
      })
    );
  }
}
