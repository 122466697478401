// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const companyProjectMetricsQuery = gql`
  query companyProjectMetrics($input: CompanyProjectMetricsInput!) {
    companyProjectMetrics(input: $input) {
      claManagersCount
      companyId
      companyName
      contributorsCount
      projectId
      projectName
      projectLogoUrl
    }
  }
`;
