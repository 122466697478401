// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateContactRolesMutation = gql`
  mutation updateContactRoles($input: UpdateContactRolesInput!) {
    updateContactRoles(input: $input) {
      message
      code
      success
    }
  }
`;
