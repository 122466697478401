// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number): any {
    if (isNaN(value)) {
      return null; // Value can only be a number.
    }

    if (value === null) {
      return null; // Value not be null.
    }

    if (value === 0) {
      return 0; // Value should be greater than zero.
    }

    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0; // Value can be negative number as well.
    let key = '';

    const powers = [
      { key: 'T', value: Math.pow(10, 12) }, // Trillion
      { key: 'B', value: Math.pow(10, 9) }, // Billon
      { key: 'M', value: Math.pow(10, 6) }, // Million
      { key: 'K', value: 1000 }, // Thousand
    ];

    powers.forEach(entity => {
      let reduced = abs / entity.value;

      reduced = Math.round(reduced * rounder) / rounder;

      if (reduced >= 1) {
        abs = reduced;
        key = entity.key;

        return;
      }
    });

    return (isNegative ? '-' : '') + abs + key;
  }
}
