// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
// https://angular.io/guide/styleguide#never-directly-import-lazy-loaded-folders

export const throwIfAlreadyLoaded = (parentModule: any, moduleName: string) => {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
};
