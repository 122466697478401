// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { AMLineChartComponent } from './components/charts/am-line-chart/am-line-chart.component';
import { AMBarChartComponent } from './components/charts/am-bar-chart/am-bar-chart.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { importEcharts } from '@lfx/shared/echarts';
import { FullAddress } from '@lfx/shared/pipes/full-address.pipe';
import { NgbDropdownConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GridsterModule } from 'angular-gridster2';
import { NgPipesModule } from 'angular-pipes';
import { NgPipesModule as NgxPipesModule } from 'ngx-pipes';
import { HighchartsChartModule } from 'highcharts-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxEchartsModule } from 'ngx-echarts';
import { LinkyModule } from 'ngx-linky';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { AlertComponent } from './components/alert/alert.component';
import { AutoCompleteSearchComponent } from './components/autocomplete-search/autocomplete-search.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CascadingRowTableComponent } from './components/cascading-row-table/cascading-row-table.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DataGridWidgetComponent } from './components/charts/data-grid-widget/data-grid-widget.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { GaugeChartComponent } from './components/charts/gauge-chart/gauge-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { NumberWidgetComponent } from './components/charts/number-widget/number-widget.component';
import { PackedBubbleChartComponent } from './components/charts/packed-bubble-chart/packed-bubble-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { StakedBarChartComponent } from './components/charts/staked-bar-chart/staked-bar-chart.component';
import { TwoSeriesBarChartComponent } from './components/charts/two-series-bar-chart/two-series-bar-chart.component';
import { WaterfallChartComponent } from './components/charts/waterfall-chart/waterfall-chart.component';
import { WorldMapChartComponent } from './components/charts/world-map-chart/world-map-chart.component';
import { CompareByDropdownComponent } from './components/compare-by-dropdown/compare-by-dropdown.component';
import { CoverMiniCardComponent } from './components/cover-mini-card/cover-mini-card.component';
import { CrowdfundingBugBountyCardComponent } from './components/crowdfunding-bug-bounty-card/crowdfunding-bug-bounty-card.component';
import { CrowdfundingFundingCardComponent } from './components/crowdfunding-funding-card/crowdfunding-funding-card.component';
import { CrowdfundingListCardComponent } from './components/crowdfunding-list-card/crowdfunding-list-card.component';
import { CrowdfundingMeetupCardComponent } from './components/crowdfunding-meetup-card/crowdfunding-meetup-card.component';
import { CrowdfundingMentorshipCardComponent } from './components/crowdfunding-mentorship-card/crowdfunding-mentorship-card.component';
import { CrowdfundingMiniCardComponent } from './components/crowdfunding-mini-card/crowdfunding-mini-card.component';
import { CrowdfundingScholarshipCardComponent } from './components/crowdfunding-scholarship-card/crowdfunding-scholarship-card.component';
import { DateRangeToggleNavbarComponent } from './components/date-range-toggle-navbar/date-range-toggle-navbar.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FoundationProjectCardComponent } from './components/foundation-project-card/foundation-project-card.component';
import { FullCardComponent } from './components/full-card/full-card.component';
import { GroupComponent } from './components/group/group.component';
import { IconStatusDialogComponent } from './components/icon-status-dialog/icon-status-dialog.component';
import { InsightsCardComponent } from './components/insights-card/insights-card.component';
import { LogoListComponent } from './components/logo-list/logo-list.component';
import { MiniCardComponent } from './components/mini-card/mini-card.component';
import { MiniProjectCardComponent } from './components/mini-project-card/mini-project-card.component';
import { MiniTagsComponent } from './components/mini-tags/mini-tags.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PanelComponent } from './components/panel/panel.component';
import { AcknowledgementComponent } from './components/popups/acknowledgement/acknowledgement.component';
import { ConfirmationModalWithoutHeadersComponent } from './components/popups/confirmation-without-headers/confirmation-without-headers.component';
import { ConfirmationModalComponent } from './components/popups/confirmation/confirmation.component';
import { PreviewDocumentModalComponent } from './components/preview-document-modal/preview-document-modal.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QuickNavComponent } from './components/quick-nav/quick-nav.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SearchFilterDropdownComponent } from './components/search-filter-dropdown/search-filter-dropdown.component';
import { SectionComponent } from './components/section/section.component';
import { SliderComponent } from './components/slider/slider.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TogglePillsComponent } from './components/toggle-pills/toggle-pills.component';
import { TopBtnComponent } from './components/top-btn/top-btn.component';
import { UsageBarComponent } from './components/usage-bar/usage-bar.component';
import { ClickOutsideDirective } from './directives/click-outside';
import { DraggableDirective } from './directives/draggable.directive';
import { LetDirective } from './directives/let.directive';
import { LfxPermissionsAllDirective } from './directives/lfx-permissions-all.directive';
import { MovableDirective } from './directives/movable.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DomainFormatPipe } from './pipes/domain-format.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FilterArrayOfObjectsPipe } from './pipes/filter-array-of-objects.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { SanitizeHtml } from './pipes/sanitize.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SortArrayOfObjectsPipe } from './pipes/sort-array-of-objects.pipe';
import { TextShortenPipe } from './pipes/textshorten.pipe';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { LineAreaChartComponent } from './components/charts/line-area-chart/line-area-chart.component';
import { TwoLayerFunnelChartComponent } from './components/charts/two-layer-funnel-chart/two-layer-funnel-chart.component';
import { WordCloudChartComponent } from './components/charts/word-cloud-chart/word-cloud-chart.component';
import { OutlineBtnComponent } from './components/outline-btn/outline-btn.component';
import { ImageErrorFallbackDirective } from './directives/image-error-fallback.directive';
import { NgbPaginationWrapperComponent } from './components/ngb-pagination-wrapper/ngb-pagination-wrapper.component';
import { InsightsStatisticsBoxComponent } from './components/insights-statistics-box/insights-statistics-box.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { ProjectGroupMembershipTableComponent } from './components/project-group-membership-table/project-group-membership-table.component';
import { InsightsStatisticsCircleComponent } from './components/insights-statistics-circle/insights-statistics-circle.component';
import { EmployeeInsightsDetailsDialogComponent } from './components/employee-insights-details-dialog/employee-insights-details-dialog.component';
import { EventsDetailsSpeakersTableComponent } from './components/events-details-speakers-table/events-details-speakers-table.component';
import { ScreenshotTakerComponent } from './components/screenshot-taker/screenshot-taker.component';
import { FirstKeyPipe } from './pipes/first-key.pipe';
import { DashPipe } from './pipes/dash.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { LineEchartsComponent } from './components/charts/line-echarts/line-echarts.component';
import { DragDropDocumentsDirective } from './directives/drag-drop-documents.directive';
import { PiiHelpIconComponent } from './components/pii-help-icon/pii-help-icon.component';
import { NewTableComponent } from './components/new-table/new-table.component';
import { UploadLogoDialogComponent } from './components/upload-logo-dialog/upload-logo-dialog.component';
import { BenefitsUsedStatusComponent } from './components/benefits-used-status/benefits-used-status.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PercentageBarComponent } from './components/percentage-bar/percentage-bar.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { AMCircleProgressComponent } from './components/charts/am-circle-progress/am-circle-progress.component';
import { AMMapChartComponent } from './components/charts/am-map-chart/am-map-chart.component';
import { LfBoxComponent } from './components/lf-box/lf-box.component';
import { LfMetricComponent } from './components/lf-metric/lf-metric.component';
import { DashboardAccessPipe } from './pipes/dashboard-access.pipe';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { OrgNestingPopoverComponent } from './components/org-nesting-popover/org-nesting-popover.component';
import { PercentagePipe } from './pipes/percentage.pipe';
import { SbomNoFilesStateComponent } from './components/sbom-no-files-state/sbom-no-files-state.component';
import { UrlFormatPipe } from './pipes/url-format.pipe';
import { DashboardSoftwareValuationComponent } from './components/dashboard-software-valuation/dashboard-software-valuation.component';
import { FirstNPipe } from './pipes/first-n-value-of-array.pipe';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { StintComponent } from './components/stint/stint.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { InfoOffcanvasComponent } from './components/info-offcanvas/info-offcanvas.component';
import { ProjectsDropdownComponent } from './components/projects-dropdown/projects-dropdown.component';

@NgModule({
  declarations: [
    PanelComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    AcknowledgementComponent,
    AlertComponent,
    SectionComponent,
    GroupComponent,
    MiniCardComponent,
    FullCardComponent,
    TogglePillsComponent,
    LogoListComponent,
    BadgeComponent,
    MiniTagsComponent,
    ProgressBarComponent,
    FoundationProjectCardComponent,
    CoverMiniCardComponent,
    PaginationComponent,
    SanitizeHtml,
    TextShortenPipe,
    MovableDirective,
    DraggableDirective,
    QuickNavComponent,
    MiniProjectCardComponent,
    TopBtnComponent,
    StatusIndicatorComponent,
    ClickOutsideDirective,
    TopBtnComponent,
    CrowdfundingListCardComponent,
    SliderComponent,
    CrowdfundingMentorshipCardComponent,
    CrowdfundingMeetupCardComponent,
    CrowdfundingFundingCardComponent,
    FullAddress,
    ShortNumberPipe,
    SortArrayOfObjectsPipe,
    CrowdfundingBugBountyCardComponent,
    CrowdfundingScholarshipCardComponent,
    CrowdfundingMiniCardComponent,
    BarChartComponent,
    WordCloudChartComponent,
    LineChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    GaugeChartComponent,
    NumberWidgetComponent,
    PreviewDocumentModalComponent,
    DataGridWidgetComponent,
    DateFormatPipe,
    DomainFormatPipe,
    LfxPermissionsAllDirective,
    UtcDatePipe,
    PaginatorComponent,
    CascadingRowTableComponent,
    HighlightPipe,
    AutoCompleteSearchComponent,
    DateRangeToggleNavbarComponent,
    EllipsisPipe,
    ConfirmationModalComponent,
    ConfirmationModalWithoutHeadersComponent,
    InsightsCardComponent,
    IconStatusDialogComponent,
    FilterDropdownComponent,
    FilterArrayOfObjectsPipe,
    LetDirective,
    TwoSeriesBarChartComponent,
    WaterfallChartComponent,
    PackedBubbleChartComponent,
    CompareByDropdownComponent,
    WorldMapChartComponent,
    StakedBarChartComponent,
    UsageBarComponent,
    SearchFilterDropdownComponent,
    TimelineComponent,
    SearchBoxComponent,
    LineAreaChartComponent,
    TwoLayerFunnelChartComponent,
    OutlineBtnComponent,
    ImageErrorFallbackDirective,
    NgbPaginationWrapperComponent,
    InsightsStatisticsBoxComponent,
    ProjectCardComponent,
    ProjectGroupMembershipTableComponent,
    InsightsStatisticsCircleComponent,
    EmployeeInsightsDetailsDialogComponent,
    EventsDetailsSpeakersTableComponent,
    ScreenshotTakerComponent,
    DashPipe,
    FirstKeyPipe,
    AvatarComponent,
    LineEchartsComponent,
    DragDropDocumentsDirective,
    PiiHelpIconComponent,
    NewTableComponent,
    UploadLogoDialogComponent,
    BenefitsUsedStatusComponent,
    ErrorAlertComponent,
    AMBarChartComponent,
    AMLineChartComponent,
    AMCircleProgressComponent,
    AMMapChartComponent,
    PercentageBarComponent,
    RadarChartComponent,
    LfBoxComponent,
    LfMetricComponent,
    DashboardAccessPipe,
    DisclaimerComponent,
    OrgNestingPopoverComponent,
    PercentagePipe,
    SbomNoFilesStateComponent,
    UrlFormatPipe,
    DashboardSoftwareValuationComponent,
    FirstNPipe,
    ExternalLinkComponent,
    StintComponent,
    InfoOffcanvasComponent,
    ProjectsDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgPipesModule,
    NgxPipesModule,
    LinkyModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    PdfViewerModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    GridsterModule,
    NgSelectModule,
    SwiperModule,
    NgxEchartsModule.forRoot({
      echarts: importEcharts,
    }),
    HighchartsChartModule,
    NgCircleProgressModule.forRoot({
      radius: 22,
      space: -6,
      unitsFontSize: '14',
      unitsColor: '#333333',
      outerStrokeWidth: 5,
      outerStrokeColor: '#008002',
      innerStrokeColor: '#e0e0e0',
      innerStrokeWidth: 5,
      title: 'UI',
      titleColor: '#333333',
      titleFontSize: '14',
      animation: false,
      showSubtitle: false,
      outerStrokeLinecap: 'butt',
      backgroundColor: '#ffffff',
      backgroundOpacity: 1,
      backgroundPadding: 0,
      titleFontWeight: '650',
    }),
    VirtualScrollerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PanelComponent,
    NgbModule,
    PageHeaderComponent,
    BreadcrumbComponent,
    AlertComponent,
    SectionComponent,
    GroupComponent,
    MiniCardComponent,
    FullCardComponent,
    TogglePillsComponent,
    LogoListComponent,
    FoundationProjectCardComponent,
    BadgeComponent,
    MiniTagsComponent,
    ProgressBarComponent,
    NgPipesModule,
    NgxPipesModule,
    CoverMiniCardComponent,
    PaginationComponent,
    SanitizeHtml,
    TextShortenPipe,
    MovableDirective,
    QuickNavComponent,
    MiniProjectCardComponent,
    TopBtnComponent,
    StatusIndicatorComponent,
    CrowdfundingListCardComponent,
    SliderComponent,
    CrowdfundingScholarshipCardComponent,
    CrowdfundingMentorshipCardComponent,
    CrowdfundingMeetupCardComponent,
    CrowdfundingFundingCardComponent,
    ClickOutsideDirective,
    FullAddress,
    ShortNumberPipe,
    SortArrayOfObjectsPipe,
    CrowdfundingBugBountyCardComponent,
    CrowdfundingMiniCardComponent,
    NgxPermissionsModule,
    BarChartComponent,
    WordCloudChartComponent,
    LineChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    GaugeChartComponent,
    NumberWidgetComponent,
    PreviewDocumentModalComponent,
    DataGridWidgetComponent,
    NgxDatatableModule,
    LfxPermissionsAllDirective,
    NgxSpinnerModule,
    GridsterModule,
    UtcDatePipe,
    PaginatorComponent,
    CascadingRowTableComponent,
    HighlightPipe,
    AutoCompleteSearchComponent,
    DateRangeToggleNavbarComponent,
    EllipsisPipe,
    InsightsCardComponent,
    IconStatusDialogComponent,
    DomainFormatPipe,
    FilterDropdownComponent,
    FilterArrayOfObjectsPipe,
    SwiperModule,
    LetDirective,
    TwoSeriesBarChartComponent,
    WaterfallChartComponent,
    PackedBubbleChartComponent,
    CompareByDropdownComponent,
    HighchartsChartModule,
    WorldMapChartComponent,
    StakedBarChartComponent,
    DateFormatPipe,
    UsageBarComponent,
    SearchFilterDropdownComponent,
    TimelineComponent,
    SearchBoxComponent,
    NgxEchartsModule,
    LineAreaChartComponent,
    TwoLayerFunnelChartComponent,
    OutlineBtnComponent,
    ImageErrorFallbackDirective,
    NgbPaginationWrapperComponent,
    InsightsStatisticsBoxComponent,
    ProjectCardComponent,
    ProjectGroupMembershipTableComponent,
    InsightsStatisticsCircleComponent,
    EmployeeInsightsDetailsDialogComponent,
    EventsDetailsSpeakersTableComponent,
    ScreenshotTakerComponent,
    DashPipe,
    FirstKeyPipe,
    AvatarComponent,
    LineEchartsComponent,
    DragDropDocumentsDirective,
    PiiHelpIconComponent,
    NewTableComponent,
    UploadLogoDialogComponent,
    BenefitsUsedStatusComponent,
    ErrorAlertComponent,
    NgCircleProgressModule,
    AMBarChartComponent,
    AMLineChartComponent,
    AMCircleProgressComponent,
    AMMapChartComponent,
    PercentageBarComponent,
    RadarChartComponent,
    LfBoxComponent,
    LfMetricComponent,
    DashboardAccessPipe,
    DisclaimerComponent,
    OrgNestingPopoverComponent,
    PercentagePipe,
    SbomNoFilesStateComponent,
    UrlFormatPipe,
    DashboardSoftwareValuationComponent,
    FirstNPipe,
    ExternalLinkComponent,
    StintComponent,
    ProjectsDropdownComponent,
  ],
  providers: [NgbDropdownConfig, SortArrayOfObjectsPipe, ShortNumberPipe],
})
export class SharedModule {}
