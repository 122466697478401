// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsAttendeesQuery = gql`
  query eventsAttendees($dateRange: String) {
    myOrganization {
      id
      events(dateRange: $dateRange) {
        year
        months {
          label
          attendees {
            attendees
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalAttendees
            location
            startDate
            name
            attendees
            similarindustry {
              avgTotalAttendance
            }
            similarsize {
              avgTotalAttendance
            }
            sponsoredsametier {
              avgTotalAttendance
            }
          }
        }
        quarters {
          label
          attendees {
            attendees
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalAttendees
            location
            startDate
            name
            attendees
            similarindustry {
              avgTotalAttendance
            }
            similarsize {
              avgTotalAttendance
            }
            sponsoredsametier {
              avgTotalAttendance
            }
          }
        }
      }
    }
  }
`;
