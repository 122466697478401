// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsSpeakersQuery = gql`
  query eventsSpeakers($dateRange: String) {
    myOrganization {
      id
      events(dateRange: $dateRange) {
        year
        months {
          label
          speakers {
            speakers
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalSpeakers
            location
            startDate
            name
            speakers
            similarindustry {
              avgTotalSpeakers
            }
            similarsize {
              avgTotalSpeakers
            }
            sponsoredsametier {
              avgTotalSpeakers
            }
          }
        }
        quarters {
          label
          speakers {
            speakers
            similarIndustry
            similarSize
            sponsoredSameTier
          }
          events {
            totalSpeakers
            location
            startDate
            name
            speakers
            similarindustry {
              avgTotalSpeakers
            }
            similarsize {
              avgTotalSpeakers
            }
            sponsoredsametier {
              avgTotalSpeakers
            }
          }
        }
      }
    }
  }
`;

export const eventsSpeakersGroupedQuery = gql`
  query orgNumberOfSpeakersGrouped($input: EventDiversityInput) {
    orgNumberOfSpeakersGrouped(input: $input) {
      year
      months {
        label
        speakers {
          speakers
          similarIndustry
          similarSize
          sponsoredSameTier
        }
        attendees {
          attendees
          similarIndustry
          similarSize
          sponsoredSameTier
        }
        events {
          attendees
          location
          name
          startDate
          totalAttendees
          totalSpeakers
          speakers
          similarindustry {
            avgTotalSpeakers
            avgTotalAttendance
          }
          similarsize {
            avgTotalSpeakers
            avgTotalAttendance
          }
          sponsoredsametier {
            avgTotalSpeakers
            avgTotalAttendance
          }
        }
      }
      quarters {
        label
        attendees {
          attendees
          similarIndustry
          similarSize
          sponsoredSameTier
        }
        speakers {
          speakers
          similarIndustry
          similarSize
          sponsoredSameTier
        }
        events {
          attendees
          location
          name
          startDate
          totalAttendees
          totalSpeakers
          speakers
          similarindustry {
            avgTotalSpeakers
            avgTotalAttendance
          }
          similarsize {
            avgTotalSpeakers
            avgTotalAttendance
          }
          sponsoredsametier {
            avgTotalSpeakers
            avgTotalAttendance
          }
        }
      }
    }
  }
`;

export const userEventsDetailsQuery = gql`
  query userEventsDetails($input: UserEventsDetailsInput) {
    userEventsDetails(input: $input) {
      name
      startDate
    }
  }
`;
