// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '@lfx/shared/shared.module';
import { LoginComponent } from './containers/login/login.component';
import { AuthCallbackComponent } from './containers/auth-callback/auth-callback.component';

@NgModule({
  declarations: [LoginComponent, AuthCallbackComponent],
  imports: [SharedModule, AuthRoutingModule],
})
export class AuthModule {}
