// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import { of, Observable, timer, merge, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  finalize,
  map,
} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
  AutoCompleteSearchInputActionEvent,
  AutoCompleteActionEventType,
} from '@lfx/shared/interfaces';

@Component({
  selector: 'lfx-autocomplete-search',
  templateUrl: './autocomplete-search.component.html',
  styleUrls: ['./autocomplete-search.component.scss'],
})
export class AutoCompleteSearchComponent implements OnInit {
  @ViewChild('searchBox') searchBox;
  @ViewChild('instance') instance: NgbTypeahead;
  @Output() searchInput =
    new EventEmitter<AutoCompleteSearchInputActionEvent>();

  @Output() selectedItem = new EventEmitter<any>();
  @Output() selectEnterDetail = new EventEmitter<boolean>();
  @Output() notFoundButtonClicked = new EventEmitter<any>();
  @Input() searchResult$;
  @Input() usedFor = '';
  @Input() placeholderText = 'search';
  @Input() enterDetailsText = 'User Not found. Enter Details below';
  @Input() notFoundText = '';
  @Input() textThreshold = 0;
  @Input() notFoundWithButton = false;
  @Input() italicText = true;
  @Input() notFoundWithButtonText = '';

  model: any;
  loadingClients = false;
  modal: string;
  focus$ = new Subject<string>();
  isFocused = false;
  isTyping = false;

  constructor() {}

  ngOnInit() {}

  searchClient = (text$: Observable<string>) => {
    const debounceText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(text => ({ type: AutoCompleteActionEventType.typing, text })),
      tap(() => (this.isTyping = true))
    );
    const inputFocus$ = this.focus$.pipe(
      map(text => ({ type: AutoCompleteActionEventType.focus, text })),
      tap(() => {
        this.isTyping = false;
        this.isFocused = true;
      })
    );

    return merge(debounceText$, inputFocus$).pipe(
      tap(() => (this.loadingClients = true)),
      switchMap(payload => {
        if (payload.text.length < this.textThreshold) {
          return of([]);
        }

        return this.getClientsAsObservable(payload);
      }),
      tap(() => (this.loadingClients = false)),
      catchError(() => {
        this.loadingClients = false;

        return of([]);
      }),
      finalize(() => {
        this.loadingClients = false;
      })
    );
  };

  getClientsAsObservable(
    payload: AutoCompleteSearchInputActionEvent
  ): Observable<any> {
    this.searchInput.emit(payload);

    return timer(100).pipe(switchMap(() => this.searchResult$ || of([])));
  }

  onSelectItem(event, input) {
    event.preventDefault();
    input.value = '';
    this.remove();

    this.selectedItem.emit(event);
  }

  onSelectEnterDetail() {
    this.searchResult$.subscribe(searchResult => {
      this.remove();
      this.selectEnterDetail.emit(searchResult.length > 0);
    });
  }

  notFoundAction() {
    this.notFoundButtonClicked.emit();
  }

  onInputBlur() {
    setTimeout(() => {
      this.isFocused = false;
    }, 250);
  }

  remove() {
    this.modal = '';
    this.searchResult$ = of([]);
    this.searchInput.emit({
      text: '',
      type: AutoCompleteActionEventType.remove,
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: KeyboardEvent
  ) {
    this.remove();
  }
}
