// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const deleteAdminRoleScopeMutation = gql`
  mutation deleteAdminRoleScope($username: String!, $organizationId: String!) {
    deleteAdminRoleScope(username: $username, organizationId: $organizationId) {
      code
      message
      success
    }
  }
`;
