// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const reviewersGrowthCubeQuery = gql`
  query ($input: ReviewersGrowthInput) {
    getReviewersGrowth(input: $input) {
      code
      message
      reviewersGrowthCountsRes {
        date
        countReviewers
      }
    }
  }
`;
