// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationVulnerableProjectsQuery = gql`
  query foundationVulnerableProjects($input: FoundationVulnerabilityInput) {
    foundationVulnerableProjects(input: $input) {
      id
      name
      parent
      updatedAt
      description
      logoUrl
      colorBand
      vulnerability {
        projectId
        sfdcProjectId
        message
        totalRepositories
        totalScannedRepositories
        updatedAt
        securityIssues {
          high {
            totalCount
            fixableCount
          }
          medium {
            totalCount
            fixableCount
          }
          low {
            totalCount
            fixableCount
          }
          total {
            totalCount
            fixableCount
          }
        }
      }
    }
  }
`;
