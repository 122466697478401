// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationQuery = gql`
  query foundation(
    $id: ID!
    $withAdminData: Boolean = false
    $withProjectsData: Boolean = false
    $withMembersData: Boolean = false
    $withContributionsData: Boolean = false
    $communityYearEvent: String = "all"
    $organizationYearEvent: String = "all"
  ) {
    foundation(id: $id) {
      id
      name
      enabledServices
      creationDate
      description
      logoUrl
      colorBand
      tag
      status
      slug
      projectSector
      model
      prorated
      autoRenew
      membershipTerm
      paymentFrequency
      autoJoinEnabled
      projectType
      repositoryUrl
      website
      insightsLink
      notificationsGroup
      members @include(if: $withMembersData) {
        id
        name
        logoUrl
        website
        tier {
          id
          name
        }
      }
      owner {
        id
        firstName
        lastName
        email
      }
      opportunityOwner {
        id
        firstName
        lastName
        email
      }
      programManager {
        id
        firstName
        lastName
        email
      }
      executiveDirector {
        id
        firstName
        lastName
        email
      }
      tiers @include(if: $withAdminData) {
        sizeMin
        sizeMax
        name
        description
        id
        pricing
        isProrated
        sizeMin
        sizeMax
        doNotDisplayInAutoJoin
        benefits {
          title
          points
        }
      }
      community(filter: $communityYearEvent) {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      legal {
        legalEntity
        formationDate
        dissolutionDate
        entityType
        msaUrl
        charterUrl
        cocUrl
      }
      organization(filter: $organizationYearEvent) {
        contributors
        commits
        totalCodeContribution
      }
      projects @include(if: $withProjectsData) {
        badgeUrl
        autoJoinEnabled
        enabledServices
        membershipAutorenew
        name
        parent
        status
        category
        createdDate
        endDate
        id
        modifiedDate
        projectType
        systemModStamp
        type
        model
        description
        logoUrl
        colorBand
        tag
        community {
          contributors
          commits
        }
        organization {
          contributors
          commits
          totalCodeContribution
        }
        metrics {
          commits
        }
        groupName
        metrics {
          commits
        }
        slug
        funding
      }
      committees @include(if: $withAdminData) {
        id
        name
        collaborationName
        description
        category
        url
      }
      # finance @include(if: $withAdminData) {
      #   name
      #   internationalAddress
      #   phone
      #   emailFrom
      #   emailAddress
      #   website
      #   city
      #   country
      #   state
      #   postalCode
      #   netSuiteClass
      #   department
      #   incomeGlAccount
      #   deferredRevenueAccount
      #   jobCode
      #   projectCode
      #   subsidiaryId
      # }
      contributionsData @include(if: $withContributionsData) {
        developers {
          name
          commits
        }
        organizations {
          name
          commits
        }
        organizationsOtherCommits
      }
      website
      foundation {
        id
        name
      }
      parent
      parentHierarchy {
        id
        name
        slug
        logoUrl
        status
        parentHierarchy {
          id
          name
          slug
          logoUrl
          status
          parentHierarchy {
            id
            name
            slug
            logoUrl
            status
          }
        }
      }
    }
  }
`;
