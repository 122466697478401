// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const myFoundationsQuery = gql`
  query getMyFoundationsQuery(
    $withMembersData: Boolean = false
    $withProjectsData: Boolean = false
    $withProgramManagerData: Boolean = false
  ) {
    myFoundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      type
      autoJoinEnabled
      projectType
      members @include(if: $withMembersData) {
        name
        logoUrl
        tier {
          name
        }
        website
      }
      community {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      programManager @include(if: $withProgramManagerData) {
        id
        email
        firstName
        lastName
        title
      }
      projects @include(if: $withProjectsData) {
        badgeUrl
        autoJoinEnabled
        enabledServices
        membershipAutorenew
        name
        parent
        status
        category
        createdDate
        endDate
        id
        modifiedDate
        projectType
        systemModStamp
        type
        description
        logoUrl
        colorBand
        tag
        metrics {
          commits
        }
        community {
          contributors
          commits
        }
        organization {
          contributors
          commits
          totalCodeContribution
        }
        groupName
      }
      contributionsData {
        developers {
          name
          commits
        }
        organizations {
          name
          commits
        }
        organizationsOtherCommits
      }
    }
  }
`;
