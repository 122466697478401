// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const requestChangeCommitteeMutation = gql`
  mutation requestChangeCommittee($input: RequestChangeCommitteeInput!) {
    requestChangeCommittee(input: $input) {
      message
    }
  }
`;
