// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';

export const profileSnapshotConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'view-profile-offcanvas',
  scroll: false,
};

export const activitiesOffcanvasConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'activities-offcanvas',
  scroll: false,
};

export const projectsOffcanvasConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'projects-offcanvas',
  scroll: false,
};

export const boardCommitteeOffcanvasConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'board-commitee-offcanvas',
  scroll: false,
};

export const editEmployeeSnapshotConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'edit-employee-offcanvas',
  scroll: false,
};

export const projectDetailsOffcanvasConfig: NgbOffcanvasOptions = {
  position: 'end',
  panelClass: 'project-details-offcanvas',
  scroll: false,
};
