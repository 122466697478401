// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const membershipContactsQuery = gql`
  query membershipContacts($input: MembershipContactsInput!) {
    membershipContacts(input: $input) {
      data {
        role
        primaryContact
        contact {
          email
          firstName
          id
          lastName
          photoUrl
        }
        id
      }
    }
  }
`;
