// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { UserContext } from '../core/models/user-context';

export const roleContextMapping: Record<string, UserContext> = {
  user: UserContext.Individual,
  contributor: UserContext.Company,
  contact: UserContext.Company,
  viewer: UserContext.Company,
  donor: UserContext.Individual,
  mentee: UserContext.Individual,
  mentor: UserContext.Individual,
  'project-maintainer': UserContext.Company,
  'project-manager': UserContext.Company,

  'lf-events': UserContext.Individual,
  'lf-executive': UserContext.Company,
  'lf-legal': UserContext.Company,
  'lf-operations': UserContext.Company,
  'lf-program-manager': UserContext.Company,
  'lf-training': UserContext.Individual,
  'community-program-manager': UserContext.Company,
  'super-admin': UserContext.Company,
  'system-admin': UserContext.Company,

  'company-admin': UserContext.Company,

  // CLA-specific roles
  'cla-manager': UserContext.Company,
  'cla-manager-designee': UserContext.Company,
  'cla-signatory': UserContext.Company,
  'lf-staff': UserContext.Staff,
};

export const contextPriority: UserContext[] = [
  UserContext.Staff,
  UserContext.Admin,
  UserContext.Company,
  UserContext.Individual,
];

export const contextDefaultRoutes = new Map();
contextDefaultRoutes.set(UserContext.Staff, '/company/dashboard');
contextDefaultRoutes.set(UserContext.Admin, '/admin/overview');
contextDefaultRoutes.set(UserContext.Company, '/company/dashboard');
contextDefaultRoutes.set(UserContext.Individual, '/company/unauthorized');
