// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const lfSponsoredProjectsQuery = gql`
  query lfSponsoredProjectsQuery {
    lfSponsoredProjects {
      autoJoinEnabled
      charterURL
      codeOfConduct
      description
      enabledServices
      entityName
      executiveDirectorID
      masterServiceAgreementURL
      groupName
      membershipAutorenew
      name
      opportunityOwnerID
      ownerID
      parent
      programManagerID
      projectEntityDissolutionDate
      projectEntityFormationDocument
      projectEntityMSADate
      projectEntityMSAExpirationDate
      logoUrl
      badgeUrl
      tag
      renewalType
      repositoryURL
      slug
      startDate
      status
      website
      category
      createdDate
      enabledServiceList
      endDate
      id
      isDeleted
      modifiedDate
      projectType
      systemModStamp
      type
      colorBand
      community {
        contributors
        commits
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      metrics {
        affiliations
        commits
        contributions
        contributors
        organizations
        repositories
      }
    }
  }
`;
