// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const addNewClaManagerMutation = gql`
  mutation addNewClaManager($input: AddClaManagerInput!) {
    addNewClaManager(input: $input) {
      code
      message
      success
      newManager {
        existingUser
        approvedOn
        lfUsername
        name
        email
        claGroupName
        organizationName
        organizationSfid
        projectId
        projectName
        projectSfid
        userSfid
      }
    }
  }
`;
