// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const foundationMeetingsQuery = gql`
  query foundationMeeting($id: ID!) {
    foundationMeeting(id: $id) {
      pastMeeting {
        name
        date
      }
      upcoming {
        name
        date
      }
    }
  }
`;
