// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './not-supported-view.component.html',
  styleUrls: ['./not-supported-view.component.scss'],
})
export class NotSupportedViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
