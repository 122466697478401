// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateAvatarMutation = gql`
  mutation updateUserAvatar($file: Upload!) {
    updateUserAvatar(file: $file) {
      fileUrl
    }
  }
`;
