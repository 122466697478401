// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const updateFoundationCommitteeMutation = gql`
  mutation updateFoundationCommittee($committeeInfo: UpdateCommitteeInput!) {
    updateCommittee(input: $committeeInfo) {
      code
      message
      success
      committee {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
