// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationSigningEntityQuery = gql`
  query organizationSigningEntity($signingEntityName: String!) {
    organizationSigningEntity(signingEntityName: $signingEntityName) {
      companyAcl
      companyExternalId
      companyId
      companyManagerId
      companyName
      signingEntityName
    }
  }
`;
