// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const currentUserQuery = gql`
  query currentUser($withPermissions: Boolean = false, $basic: Boolean = true) {
    currentUser(basic: $basic) {
      id
      username
      firstName
      lastName
      avatarUrl
      fullName
      primaryEmail
      emails {
        emailAddress
        emailType
        active
        isDeleted
        isPrimary
        isVerified
      }
      permissions @include(if: $withPermissions) {
        context
        actions
        resource
        scopes {
          id
        }
      }
      roles
    }
  }
`;
