// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const allOrganizationsQuery = gql`
  query allOrganizations {
    organizations {
      id
      name
      description
      logoUrl
      phone
      website
      slug
    }
  }
`;
