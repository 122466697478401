// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const claEnabledFoundationsQuery = gql`
  {
    claEnabledFoundations {
      id
      name
      logoUrl
      projectType
      parent
      foundation {
        id
        name
      }
      projects {
        id
        name
        logoUrl
        projectType
      }
    }
  }
`;
