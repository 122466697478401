// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
export const genderMap = {
  male: 'man',
  man: 'man',
  female: 'woman',
  woman: 'woman',
  they: 'other gender identity',
  other: 'other gender identity',
  nonbinary: 'other gender identity',
  'prefer not to answer': 'prefer not to answer',
  'i dont want to answer': 'prefer not to answer',
  unspecified: 'no data',
  notdefined: 'no data',
  prefernottoanswer: 'prefer not to answer',
  othergenderidentity: 'other gender identity',
  'no data': 'no data',
};
