// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pageSettings } from '@config';
import { InitService } from '@lfx/init.service';
import { AuthService, CompanyService } from '@services';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'lfx-auth-callback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;
  authCompleteSubscription$: Subscription;
  queryParams = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private init: InitService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private location: Location
  ) {
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {
    if (
      this.location.path() &&
      this.location.path().split('/') &&
      this.location.path().split('/').length > 1 &&
      this.location.path().split('/')[1].toLowerCase() !== 'company'
    ) {
      this.companyService.setCurrentCompanyIdBySlug(
        this.location.path().split('/')[1]
      );
    }
    this.handleAuthCallback();
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
    this.authCompleteSubscription$.unsubscribe();
  }

  private handleAuthCallback() {
    const url = new URL(window.location.href);

    this.setQueryParms(url);

    if (url.searchParams.has('code') && url.searchParams.has('state')) {
      let targetRoute = '/';
      const authComplete$ = this.authService.handleRedirectCallback$.pipe(
        tap(cbRes => {
          targetRoute = this.getTargetRouteFromAppState(cbRes.appState);
        }),
        mergeMap(() =>
          combineLatest([
            this.init.initUser(),
            this.authService.isAuthenticated$,
          ])
        ),
        // *info: avoid break the app and allow to re-try
        catchError(() => of(''))
      );

      this.authCompleteSubscription$ = authComplete$.subscribe(() => {
        // *info: on re-try user can be stuck on /auth page
        if (targetRoute === '/auth') {
          targetRoute = '/';
        }
        this.router.navigate([targetRoute], {
          queryParams: this.queryParams || {},
        });
      });
    }
  }

  private getTargetRouteFromAppState(appState) {
    if (!appState) {
      return '/';
    }

    const { target, targetUrl } = appState;

    return target || targetUrl || '/';
  }

  private setQueryParms(url: URL) {
    const entries: [string, string][] = [];

    for (const key of url.searchParams.keys()) {
      if (key !== 'code' && key !== 'state') {
        const value = url.searchParams.get(key);

        entries.push([key, value]);
      }
    }
    this.queryParams = Object.fromEntries(entries);
  }
}
