// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { Component, OnInit, OnDestroy } from '@angular/core';
import { pageSettings } from '@lfx/config';

@Component({
  selector: 'lfx-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css'],
})
export class ForbiddenComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;

  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }
}
