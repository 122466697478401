// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const eventsSummaryQuery = gql`
  query eventsSummary($organizationId: String) {
    eventsSummary(organizationId: $organizationId) {
      totalAttendances
      totalSpekers
      totalSponsered
      totalSavings
      totalSponsorshipSavings
      totalRegistrationSavings
      totalSponseredYoYdiff
      totalSpeakersYoYdiff
      totalAttendancesYoYdiff
      totalSavingsYoYdiff
      chartLabels
      speakersChartData
      attendeesChartData
      metadata {
        pageSize
        totalSize
        offset
      }
      eventsBySpeakers {
        name
        attendees
        speakers
        startDate
        id
      }
      eventsByAttendees {
        id
        name
        attendees
        speakers
        startDate
        endDate
        year
      }
      sponsorships {
        discount
        # id => problem in Apollo Caching appeared because the data is mockups. Should uncomment this when the APIs are ready
        name
        price
        startDate
      }
      registrations {
        discount
        # id => problem in Apollo Caching appeared because the data is mockups. Should uncomment this when the APIs are ready
        name
        price
        startDate
      }
    }
  }
`;
