// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const rolesQuery = gql`
  query roles($roleInput: FilterInput) {
    roles(input: $roleInput) {
      roleId
      roleName
      roleTitle
      isAdmin
      description
      roleObjects {
        objectRoleId
        roleId
        objectId
        objectName
      }
    }
  }
`;
