// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationQuery = gql`
  query organization(
    $salesforceId: ID!
    $withAdmins: Boolean = false
    $withServiceUsers: Boolean = false
    $withInvites: Boolean = false
    $withOrganizationFoundations: Boolean = false
    $withOrganizationCommitteeFoundations: Boolean = false
    $withContribution: Boolean = false
    $withContributionStats: Boolean = false
    $withContributionMonthly: Boolean = false
    $withContributionPerEmployee: Boolean = false
    $withContributionPerProject: Boolean = false
    $withContributionPerProjectMonthly: Boolean = false
    $contributionMonthlyFilters: ContributionMonthlyFilters
    $contributionFilters: ContributionFilters
    $contributionStatsFilters: ContributionFilters
    $contributionPerProjectFilters: ContributionFilters
    $contributionPerProjectMonthlyFilters: ContributionFilters
    $contributionPerEmployeeFilters: ContributionPerEmployeeFilters
    $contributionProjectId: ID
  ) {
    organization(salesforceId: $salesforceId) {
      id
      description
      github
      gitlab
      industry
      logoUrl
      name
      slug
      companyType
      type
      website
      createdAt
      employees
      totalEmployees
      lastModifiedAt
      lastModifiedBy
      joinNowNoOfEmployees
      lastEmployeeSizeVerfiedDate
      link
      crunchBaseUrl
      sector
      domainAlias
      salesforceB2BAccountId
      contribution(contributionFilters: $contributionFilters)
        @include(if: $withContribution) {
        metadata {
          offset
          pageSize
          totalSize
        }
        data {
          chat
          codeContributions
          commits {
            contribution
            contributors
            totalContributors
            totalContribution
          }
          issues {
            all {
              contribution
              totalContribution
            }
            resolved {
              contribution
              totalContribution
            }
            submitted {
              contribution
              totalContribution
            }
          }
          mailingList
          projectGroup {
            count
            totalCount
          }
          lastContributionDate
          pr {
            all {
              contribution
              totalContribution
            }
            approved {
              contribution
              totalContribution
            }
            merged {
              contribution
              totalContribution
            }
            reviewed {
              contribution
              totalContribution
            }
            submitted {
              contribution
              totalContribution
            }
          }
          project {
            description
            id
            industrySector
            logoUrl
            name
            slug
            parent
            isMember
          }
          totalContributions
        }
      }
      contributionStats(contributionStatsFilters: $contributionStatsFilters)
        @include(if: $withContributionStats) {
        chat
        codeContributions
        totalProjects
        commits {
          contribution
          contributors
          totalContributors
          totalContribution
        }
        issues {
          all {
            contribution
            totalContribution
          }
          resolved {
            contribution
            totalContribution
          }
          submitted {
            contribution
            totalContribution
          }
        }
        mailingList
        lastContributionDate
        pr {
          all {
            contribution
            totalContribution
          }
          approved {
            contribution
            totalContribution
          }
          merged {
            contribution
            totalContribution
          }
          reviewed {
            contribution
            totalContribution
          }
          submitted {
            contribution
            totalContribution
          }
        }
        totalContributions
      }
      contributionMonthly(
        contributionMonthlyFilters: $contributionMonthlyFilters
      ) @include(if: $withContributionMonthly) {
        projects {
          logo
          slug
          projectname
          project_id
        }
        months {
          countofcommit
          countofissue
          countofpr
          ym
        }
        data {
          date
          insights {
            chat
            mailingList
          }
        }
      }
      contributionPerProjectMonthly(
        projectId: $contributionProjectId
        contributionPerProjectMonthlyFilters: $contributionPerProjectMonthlyFilters
      ) @include(if: $withContributionPerProjectMonthly) {
        metadata {
          offset
          pageSize
          totalSize
        }
        data {
          date
          insights {
            chat
            commits {
              contribution
            }
            issues {
              all
              commented
              resolved
              submitted
            }
            mailingList
            pr {
              all
              approved
              merged
              reviewed
              submitted
            }
          }
        }
      }
      contributionPerEmployee(
        contributionPerEmployeeFilters: $contributionPerEmployeeFilters
      ) @include(if: $withContributionPerEmployee) {
        metadata {
          offset
          pageSize
          totalSize
        }
        data {
          chat
          codeContributions
          commits {
            contribution
          }
          issues
          mailingList
          name
          logoUrl
          pr
          projects {
            totalContribution
            contribution
            description
            id
            industrySector
            logoUrl
            name
            slug
            parent
          }
          totalContributions
        }
      }
      contributionPerProject(
        projectId: $contributionProjectId
        contributionPerProjectFilters: $contributionPerProjectFilters
      ) @include(if: $withContributionPerProject) {
        metadata {
          offset
          pageSize
          totalSize
        }
        data {
          chat
          codeContributions
          commits {
            contribution
            contributors
            totalContributors
            totalContribution
          }
          issues {
            all {
              contribution
              totalContribution
            }
          }
          mailingList
          projectGroup {
            count
            totalCount
          }
          lastContributionDate
          pr {
            all {
              contribution
              totalContribution
            }
            approved {
              contribution
              totalContribution
            }
            merged {
              contribution
              totalContribution
            }
            reviewed {
              contribution
              totalContribution
            }
            submitted {
              contribution
              totalContribution
            }
          }
          project {
            description
            id
            industrySector
            logoUrl
            name
            slug
            parent
            isMember
          }
          organization {
            id
            logoUrl
            name
          }
          totalContributions
        }
      }
      admins @include(if: $withAdmins) {
        id
        name
        email
        logoUrl
        roleId
        scopeId
        roleName
        isAppointed
      }
      invites @include(if: $withInvites) {
        inviteId
        email
        firstName
        lastName
        roleName
        scope
        scopeId
        status
        expired
      }
      serviceUsers @include(if: $withServiceUsers) {
        id
        username
        email
        name
        avatar
        roles {
          roleId
          name
          projectName
          foundationName
          scopeId
          scopeTypeId
          projectId
          foundationId
        }
      }
      foundations @include(if: $withOrganizationFoundations) {
        id
        name
      }
      committeeFoundations @include(if: $withOrganizationCommitteeFoundations) {
        id
        name
        logoUrl
      }
    }
  }
`;
