// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createNewFoundationMutation = gql`
  mutation createNewFoundation($foundationInfo: CreateNewFoundationInput!) {
    createNewFoundation(input: $foundationInfo) {
      code
      message
      success
      newFoundation {
        id
      }
    }
  }
`;
