// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const projectSecurityDetailsQuery = gql`
  query rootSecurityProjectDetails($input: SecurityProjectDetailsInput) {
    rootSecurityProjectDetails(input: $input) {
      repositories {
        dependencies {
          dependencyId
          licenses
          name
          repositoryId
          repositoryFolderpathId
          rootDependency
          version
        }
        folderPaths
        name
        repositoryId
        url
      }
    }
  }
`;
