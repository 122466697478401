// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const assignCLAManagerDesigneeMutation = gql`
  mutation assignCLAManagerDesignee($input: AssignCLAManagerDesigneeInput!) {
    assignCLAManagerDesignee(input: $input) {
      code
      message
      success
    }
  }
`;
