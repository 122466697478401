// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createProductsKpiMutation = gql`
  mutation CreateProductsKpi($input: CreateProductsKpiInput!) {
    createProductsKpi(input: $input) {
      code
      message
      success
    }
  }
`;
