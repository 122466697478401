// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tncEnrollmentsQuery = gql`
  query tncEnrollments(
    $organizationId: String
    $dateRange: String
    $pageSize: Int
    $orderBy: String
    $sortDir: String
    $offset: Int
    $jfilter: String
    $timeFilterField: String
  ) {
    tncEnrollments(
      organizationId: $organizationId
      dateRange: $dateRange
      pageSize: $pageSize
      orderBy: $orderBy
      sortDir: $sortDir
      offset: $offset
      jfilter: $jfilter
      timeFilterField: $timeFilterField
    ) {
      metadata {
        pageSize
        totalSize
      }
      data {
        enrollmentDate
        certificationAchievementDate
        user {
          firstName
          lastName
          id
          photoUrl
        }
        product {
          name
          id
          type
          code
        }
      }
    }
  }
`;
