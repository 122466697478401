// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const listSbomLicensesQuery = gql`
  query listSbomLicenses($orgId: String!) {
    listSbomLicenses(orgId: $orgId) {
      name
      stats
    }
  }
`;
