// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const createFoundationCommitteesMutation = gql`
  mutation createFoundationCommittees($committeesInfo: CreateCommitteesInput!) {
    createCommittees(input: $committeesInfo) {
      code
      message
      success
      committees {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
