// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const attendeesSummaryQuery = gql`
  query attendeesSummary(
    $organizationId: String
    $dateRange: String
    $pageSize: Int
    $orderBy: String
    $sortDir: String
    $eventId: String
  ) {
    attendeesSummary(
      organizationId: $organizationId
      dateRange: $dateRange
      pageSize: $pageSize
      orderBy: $orderBy
      sortDir: $sortDir
      eventId: $eventId
    ) {
      city
      contactId
      country
      email
      firstName
      gender
      jobFunction
      jobLevel
      lastName
      logo
      state
      title
      totalEventsAttended
      events {
        id
        name
        startDate
      }
    }
  }
`;

export const dashboardAttendeesSummaryQuery = gql`
  query dashboardAttendeesSummary($organizationId: String, $eventId: String) {
    dashboardAttendeesSummary(
      organizationId: $organizationId
      eventId: $eventId
    ) {
      contactId
      firstName
      lastName
      logo
      title
      email
    }
  }
`;

export const attendeeDiversityChartQuery = gql`
  query orgEventsDiversityChart($input: EventDiversityInput) {
    orgEventsDiversityChart(input: $input) {
      byJobFunction {
        counts
        jobfunction
      }
      byJobLevel {
        count
        joblevel
      }
      byGeography {
        counts
        country
      }
      byGender {
        gender
        count
      }
    }
  }
`;

export const orgEventsTableQuery = gql`
  query orgEventsTableData($input: EventInput) {
    orgEventsTableData(input: $input) {
      attendeesFromMyOrg {
        eventsCount
        firstName
        lastName
        logo
        contactId
        title
      }
      speakersFromMyOrg {
        eventsCount
        firstName
        lastName
        logo
        contactId
        title
      }
      speakersAccrossAllOrg {
        eventsCount
        firstName
        lastName
        logo
        contactId
      }
    }
  }
`;

export const getEventViewDetailsSummaryQuery = gql`
  query getEventViewDetailsSummary($input: EventViewDetailSummaryInput!) {
    orgEventViewDetailsSummary(input: $input) {
      email
      eventId
      eventName
      logoUrl
      eventStartdate
    }
  }
`;
