// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const pastZoomMeetingsQuery = gql`
  query pastZoomMeetingsQuery($input: ZoomMeetingsInput) {
    pastZoomMeetings(input: $input) {
      data {
        agenda
        duration
        id
        project
        startTime
        topic
        recordingType
        recordingExtension
        recordingPassword
        recordingShareUrl
        recordingStart
        recordingAccess
        recordingEnabled
        recordingTranscriptAccess
        recordingTranscriptEnabled
      }
      metadata {
        total
      }
    }
  }
`;
