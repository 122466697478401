// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationProjects = gql`
  query organizationProjects(
    $organizationId: ID = ""
    $dateRange: String = ""
  ) {
    organizationProjects(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      slug
      projectname
      project_id
      logo
      countofemployees
      countofcommits
      share
    }
  }
`;
