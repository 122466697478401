// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const singleReviewerActivitiesCubeQuery = gql`
  query ($organizationUrl: String!, $memberId: String!, $dateRange: String!) {
    singleReviewerActivities(
      organizationUrl: $organizationUrl
      memberId: $memberId
      dateRange: $dateRange
    ) {
      type
      platform
      countContributions
    }
  }
`;
