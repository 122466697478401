// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationContributors = gql`
  query organizationContributorsResponse(
    $organizationId: ID = ""
    $dateRange: String = ""
  ) {
    organizationContributors(
      organizationId: $organizationId
      dateRange: $dateRange
    ) {
      total
      contributors {
        email
        name
        userlogo
        commits
        userid
      }
    }
  }
`;
