// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const tncLearnersQuery = gql`
  query tncLearners(
    $organizationId: String
    $dateRange: String
    $pageSize: Int
    $orderBy: String
    $sortDir: String
    $offset: Int
    $jfilter: String
  ) {
    tncLearners(
      organizationId: $organizationId
      dateRange: $dateRange
      pageSize: $pageSize
      orderBy: $orderBy
      sortDir: $sortDir
      offset: $offset
      jfilter: $jfilter
    ) {
      metadata {
        pageSize
        totalSize
      }
      data {
        combinedTrainingStats {
          enrollments
          certificatesAchieved
        }
        certificationStats {
          enrollments
          certificatesAchieved
        }
        learner {
          firstName
          lastName
          id
          photoUrl
        }
      }
    }
  }
`;
