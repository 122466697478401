// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const plannedEventsQuery = gql`
  query plannedEvents {
    plannedEvents {
      id
      cventId
      cventSandbox
      description
      embassy
      endDate
      locationAddress
      locationCity
      locationCountry
      locationName
      locationState
      locationZip
      name
      projectId
      registrationUrl
      startDate
      status
      confirmationNumber
      createdDate
      cventLink
      eventUrl
      price
      projectName
      purchaseDate
      registeredEmailAddress
      registrationType
    }
  }
`;
