// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const requestCorporateSignatureMutation = gql`
  mutation requestCorporateSignature($input: RequestClaSignatureInput!) {
    requestCorporateSignature(input: $input) {
      code
      message
      success
      data {
        signatureId
        signUrl
      }
    }
  }
`;
