// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MITs
import { gql } from 'apollo-angular';

export const organizationEmployeesStatsQuery = gql`
  query organizationEmployeesStats(
    $organizationId: String!
    $projectId: String
    $activityfilter: String
  ) {
    organizationEmployeesStats(
      organizationId: $organizationId
      projectId: $projectId
      activityfilter: $activityfilter
    ) {
      administratorCount
      totalEmployees
      noLfidCount
      committeeCount
      membershipCount
      contributorsCount
      ospoCount
      projectNoLfidCount
      comitteeDetailCount {
        governingBoardCount
        marketingCount
        otherCount
        technicalCount
        legalCount
        financeCount
      }
      contributorDetailCount {
        maintainersCount
        missingLfCount
      }
      membershipDetailCount {
        billingCount
        legalCount
        primaryVotingCount
      }
    }
  }
`;
